import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { withStyles } from "@material-ui/core/styles";

import ChevronDown from "mdi-material-ui/ChevronDown";
import Translate from "mdi-material-ui/Translate";
import InternalStore from "../stores/InternalStore";

import { withTranslation } from 'react-i18next';
import i18n from "../i18n/i18n";
import theme from "../theme";

const styles = {
    langBtnText: {
      [theme.breakpoints.down(380)]: {
        display: 'none'
      },
    },
    language:{
        display : 'none'
    },
    language1:{
        display : 'block'
    }
    
   
  };

class ChangeLang extends Component{
    constructor(){
        super()
        this.state = {
            langMenuAnchor: null,
            zh:true,
            en:true,
        };
    }
    componentDidMount() {
        InternalStore.GetSystemSettings(1, resp => {
            this.setState({
              SysSet: resp,
            });
            
            if (resp.zh==false){
                this.setState({
                    zh:false
                })
            }

            if (resp.en==false){
                
                this.setState({
                    en:false
                })
            
            }
            localStorage.setItem("Name", resp.name)
            
          });
 
    }
    onLangMenuOpen = (e) => {
       
        InternalStore.GetSystemSettings(1, resp => {
            this.setState({
              SysSet: resp,
            });


            localStorage.setItem("Name", resp.name)
            
          });
        this.setState({
          langMenuAnchor: e.currentTarget,
        });
    }
    
    onLangMenuClose = () => {
        this.setState({
            langMenuAnchor: null,
        });
    }

    changeLang = lang=>{
        i18n.changeLanguage(lang)
        this.props.updateLang()
        localStorage.setItem('language', lang)
        this.onLangMenuClose()
    }

    render(){
        const langOpen = Boolean(this.state.langMenuAnchor);
        const { t } = this.props;
        return(
            <div className={this.props.className}>
                <Button color="inherit" onClick={this.onLangMenuOpen} style={{textTransform: 'none'}} title={t('changeLang')} startIcon={<Translate />} endIcon={<ChevronDown />}><span className={this.props.classes.langBtnText}>{i18n.language === 'zh' ? '中文' : (i18n.language === 'en' ? 'English' : '')}</span></Button>
                <Menu
                id="lang-menu"
                anchorEl={this.state.langMenuAnchor}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                open={langOpen}
                onClose={this.onLangMenuClose}
                >
               <div >
                <MenuItem  className={ this.state.zh==false ? this.props.classes.language : this.props.classes.language1} selected={i18n.language === 'zh'} onClick={()=>{this.changeLang('zh')}}>中文</MenuItem>
                </div> 
                <MenuItem   className={ this.state.en==false ? this.props.classes.language : this.props.classes.language1} onClick={()=>{this.changeLang('en')}}>English</MenuItem>
                </Menu>
            </div>
        );
    }
}
export default withStyles(styles)(withRouter(withTranslation()(ChangeLang)));