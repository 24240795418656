import React from "react";

import TextField from '@material-ui/core/TextField';
import { withRouter } from 'react-router-dom';
import { withTranslation } from "react-i18next";

import FormComponent from "../../classes/FormComponent";
import Form from "../../components/Form";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from "../../components/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import Checkbox from '@material-ui/core/Checkbox';
import InternalStore from "../../stores/InternalStore";
import { withStyles } from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';
// import IconButton from '@material-ui/core/IconButton';
// import PhotoCamera from '@material-ui/icons/PhotoCamera';

const styles = {
  input: {
        display: 'none',
      },
  logo: {
    // height: "100%",
    // width:"100%",
  },
}


class AdminSystemSettingsForm extends FormComponent {
  constructor() {
    super();
    this.state = {
      object:{},
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.UpLoadImg_logo = this.UpLoadImg_logo.bind(this);
    this.UpLoadImg_favicon = this.UpLoadImg_favicon.bind(this);
  }
  componentDidMount() {
    InternalStore.GetSystemSettings(1, resp => {
      this.setState({
        object: resp,
      });
    });
  }


  onSubmit = () => {
    InternalStore.updateSystemSettings(this.state.object, resp => {
      window.location.reload()
      
    });
  }
  UpLoadImg_logo = (file) =>{
    let that=this
    var imgFile = new FileReader();
    imgFile.readAsDataURL(file.target.files[0]);
    imgFile.onload = function () {
        var imgData = this.result;
        let object = that.state.object;
        let obj = object;
        obj.logo=imgData
        that.setState({
          object: obj,
        });
    }
  }
  
  UpLoadImg_favicon = (file) =>{
    let that=this
    var imgFile = new FileReader();
    imgFile.readAsDataURL(file.target.files[0]);
    imgFile.onload = function () {
        var imgData = this.result;
        let object = that.state.object;
        let obj = object;
        obj.favicon=imgData
        that.setState({
          object: obj,
        });
    }
  }



  render() {
    if (this.state.object === undefined) {
      return null;
    }
    const { t } =this.props



    return(
      <Form
        submitLabel={this.props.submitLabel}
        onSubmit={this.onSubmit}
      >
        <TextField
          id="name"
          label={t('SiteName')}
          margin="normal"
          value={this.state.object.name || ""}
          onChange={this.onChange}
          required
          fullWidth
        />
          <FormControl label={t('SiteLanguage')}>
          <FormGroup>
            <FormControlLabel
              label={'English'}
              control={
                <Checkbox
                  id="en"
                  checked={!!this.state.object.en}
                  onChange={this.onChange}
                  disabled={this.props.disabled}

                  color="primary"
                />
              }
            />
            <FormControlLabel
              label={'中文'}
              control={
                <Checkbox
                  id="zh"
                  checked={!!this.state.object.zh}
                  onChange={this.onChange}
                  color="primary"
                />
              }
            />
          </FormGroup>
          <FormHelperText>{t('SiteLanguageHelpTex')}</FormHelperText>
        </FormControl>
        <FormControl label={t('SiteDocumentation')}>
          <FormGroup>
            <FormControlLabel
              label={t('Display')}
              control={
                <Checkbox
                  id="document"
                  checked={!!this.state.object.document}
                  onChange={this.onChange}
                  color="primary"
                />
              }
            />
          </FormGroup>
          <FormHelperText>{t('SiteDocumentationHelpTex')}</FormHelperText>
        </FormControl>
        <FormControl label={'Logo'}>
          <div>
          <img src={this.state.object.logo}  className={this.props.classes.logo} alt="Zysmart" />
          </div>
          <FormHelperText>{t('LogoHelpTex')}</FormHelperText>
          <FormGroup>
            <div>
              <input
                accept="image/*"
                className={this.props.classes.input}
                id="contained-button-logo"
                name="upFile" 
                multiple
                type="file"
                onChange= {this.UpLoadImg_logo} 
              />
         
              <label htmlFor="contained-button-logo">
                <Button variant="contained" color="primary" component="span"  >
                  Upload
                </Button>
              </label>
            </div>
        </FormGroup>
       
        </FormControl>
    
        <FormControl label={'Favicon'}>
          <div>
          <img src={this.state.object.favicon}  className={this.props.classes.logo} alt="Zysmart" />
          </div>
          <FormHelperText>{t('FaviconHelpTex')}</FormHelperText>
          <FormGroup>
            <div>
              <input
                accept="image/*"
                className={this.props.classes.input}
                id="contained-button-favicon"
                name="upFile" 
                multiple
                type="file"
                onChange= {this.UpLoadImg_favicon} 
              />
         
              <label htmlFor="contained-button-favicon">
                <Button variant="contained" color="primary" component="span"  >
                  Upload
                </Button>
              </label>
            </div>
        </FormGroup>
        
        </FormControl>
      </Form>

    );
  }
}

export default withStyles(styles)(withTranslation()(AdminSystemSettingsForm));
