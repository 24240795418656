import React, { Component } from "react";
import { withRouter, Link } from 'react-router-dom';


import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { withStyles } from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
//import Input from "@material-ui/core/Input";
//import InputAdornment from "@material-ui/core/InputAdornment";
import blue from "@material-ui/core/colors/blue";
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';


import MenuIcon from "mdi-material-ui/Menu";
import Backburger from "mdi-material-ui/Backburger";
import AccountCircle from "mdi-material-ui/AccountCircle";
//import Magnify from "mdi-material-ui/Magnify";




/* import Domain from "mdi-material-ui/Domain";
import Home from "mdi-material-ui/Home";
import Account from "mdi-material-ui/Account";
import Server from "mdi-material-ui/Server";
import Apps from "mdi-material-ui/Apps";
import RadioTower from "mdi-material-ui/RadioTower";
import Tune from "mdi-material-ui/Tune";
import Rss from "mdi-material-ui/Rss";
import KeyVariant from "mdi-material-ui/KeyVariant";
import ChevronDown from "mdi-material-ui/ChevronDown";
import Translate from "mdi-material-ui/Translate"; */

import InternalStore from "../stores/InternalStore";
import SessionStore from "../stores/SessionStore";
import OrganizationStore from "../stores/OrganizationStore";
import theme from "../theme";
import SearchBox from "./SearchBox";
import Admin from "./Admin";
import { withTranslation } from 'react-i18next';
import i18n from "../i18n/i18n";
import ChangeLang from "./ChangeLang";


const styles = {
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 10,
    [theme.breakpoints.down(1150)]: {
      display: 'none'
    },
  },
  hidden: {
    display: "none",
  },
  flex: {
    flex: 1,
  },
  logo: {
    height: 32,
    [theme.breakpoints.down(1230)]: {
      display: 'none',
    },
  },
  miniLogo: {
    height: 32,
    display: 'none',
    [theme.breakpoints.down(1230)]: {
      display: 'block',
    },
  },
  logoBox: {
    [theme.breakpoints.down(1150)]: {
      flex: 1,
    },
  },
  search: {
    marginRight: 3 * theme.spacing(1),
    color: theme.palette.common.white,
    background: blue[400],
    width: 150,
    padding: 5,
    borderRadius: 3,
    transition: 'width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
  searchFocus: {
    width: '400px !important'
  },
  chip: {
    background: blue[600],
    color: theme.palette.common.white,
    marginRight: theme.spacing(1),
    "&:hover": {
      background: blue[400],
    },
    "&:active": {
      background: blue[400],
    },
    [theme.breakpoints.down(1150)]: {
      maxWidth: 100,
    },
    
  },
  iconButton: {
    color: theme.palette.common.white,
    marginRight: theme.spacing(1),
    [theme.breakpoints.down(600)]: {
      display: 'none'
    },
  },
  topMenuBox: {
    flex: 1,
    paddingLeft: 20,
    height: 64,
    lineHeight: '64px',
    whiteSpace: 'nowrap',
    overflowX: 'hidden',
    [theme.breakpoints.down(1150)]: {
      display: 'none'
    },
  },
  activeMenu: {
    backgroundColor: '#44a6f5',
  },
  mobileMenuBtn: {
    display: 'none',
    [theme.breakpoints.down(1150)]: {
      display: 'block'
    },
  },
  langBtnText: {
    [theme.breakpoints.down(380)]: {
      display: 'none'
    },
  },
};

const preventDefault = (event) => event.preventDefault();
class TopNav extends Component {
  constructor() {
    super();

    this.state = {
      menuAnchor: null,
      search: "",
      oidcEnabled: false,
      organization: null,
      searchFocus: false, 
      activeMenu: 'index',
      langMenuAnchor: null,
      herf:"",
      SysSet:{},
    };
    this.getOrganizationFromLocation = this.getOrganizationFromLocation.bind(this);
    this.updateMenuStatus = this.updateMenuStatus.bind(this);
  }

  onMenuOpen = (e) => {
    this.setState({
      menuAnchor: e.currentTarget,
    });
  }

  onMenuClose = () => {
    this.setState({
      menuAnchor: null,
    });
  }

  onLangMenuOpen = (e) => {
    this.setState({
      langMenuAnchor: e.currentTarget,
    });
  }

  onLangMenuClose = () => {
    this.setState({
      langMenuAnchor: null,
    });
  }

  onLogout = () => {
    if (this.state.oidcEnabled === true) {
      if (this.state.logoutURL !== "") {
        SessionStore.logout(false, () => {
          window.location.assign(this.state.logoutURL);
        });
      } else {
        SessionStore.logout(true, () => {
            this.props.history.push("/login");
        });
      }
    } else {
      SessionStore.logout(true, () => {
        this.props.history.push("/login");
      });
    }
  }


  handleDrawerToggle = () => {
    this.props.setDrawerOpen(!this.props.drawerOpen);
  }

  onSearchChange = (e) => {
    this.setState({
      search: e.target.value,
    });
  }

  onSearchSubmit = (e) => {
    e.preventDefault();
    this.props.history.push(`/search?search=${encodeURIComponent(this.state.search)}`);
  }


  handleMobileMenu = ()=>{
    this.props.setMobileMenuOpen(true);
  }

  showSysConfMenu = () => {
    this.props.setShowCateType(1);
    this.props.setDrawerOpen(true);
  }

  showOrganMenu = () => {
    this.props.setShowCateType(2);
    this.props.setDrawerOpen(true);
  }

  hideLeftMenu = () => {
    this.props.setDrawerOpen(false);
  }

  componentDidMount() {

    InternalStore.GetSystemSettings(1, resp => {
      this.setState({
        SysSet: resp,
      });
      localStorage.setItem("Name", resp.name)
      localStorage.setItem("Favicon", resp.favicon)
      localStorage.setItem("Zh", resp.zh)
      localStorage.setItem("En", resp.en)


      if (!resp.document ){
        document.getElementById("doc").style.display = "none";
      }
      

    });
    InternalStore.settings(resp => {
      this.setState({
        oidcEnabled: resp.openidConnect.enabled,
        logoutURL: resp.openidConnect.logoutURL,
      });
    })

    SessionStore.on("organization.change", () => {
      OrganizationStore.get(SessionStore.getOrganizationID(), resp => {
        this.setState({
          organization: resp.organization,
        });
      });
    });

    OrganizationStore.on("change", (org) => {
      if (this.state.organization !== null && this.state.organization.id === org.id) {
        this.setState({
          organization: org,
        });
      }
    });

    OrganizationStore.on("delete", id => {
      if (this.state.organization !== null && this.state.organization.id === id) {
        this.setState({
          organization: null,
        });
      }
    });

    if (SessionStore.getOrganizationID() !== null) {
      OrganizationStore.get(SessionStore.getOrganizationID(), resp => {
        this.setState({
          organization: resp.organization,
        });
      });
    }

    this.getOrganizationFromLocation();
  }

  componentDidUpdate(prevProps) {
    if (this.props === prevProps) {
      return;
    }

    this.getOrganizationFromLocation();
    this.updateMenuStatus()
  }

  onSearchFocus = (e)=>{
    this.setState({
      searchFocus: true
    })
  }

  onSearchBlur = (e)=>{
    this.setState({
      searchFocus: false
    })
  }

  getOrganizationFromLocation() {
    //判断语言 设置跳转地址
    if (i18n.language=="zh"){
      this.setState({
        herf:"http://lora.zhongyismart.com:3000/#/OpenApi",
      });
    }else{
      this.setState({
        herf:"http://lora.zhongyismart.com:3000/#/English/OpenApi",
      });
    }
    

    const organizationRe = /\/organizations\/(\d+)/g;
    const match = organizationRe.exec(this.props.history.location.pathname);

    if (match !== null && (this.state.organization === null || this.state.organization.id !== match[1])) {
      SessionStore.setOrganizationID(match[1]);
    }
  }

  updateMenuStatus(){
    const systemConfs = ['/dashboard', '/gateway-profiles', '/network-servers', '/organizations', '/users', '/api-keys', '']
    const applicationReg = /^\/organizations\/(\d+)\/applications$/
    const indexReg = /^\/organizations\/(\d+)$/
    const multReg = /^\/organizations\/(\d+)\/multicast-groups$/
    const gatewayReg = /^\/organizations\/(\d+)\/gateways$/
    const organReg = /^\/organizations\/(\d+)\/.*/
    let path = this.props.history.location.pathname
    if(indexReg.test(path)){
      this.setState({activeMenu: 'index'})
    }else if(gatewayReg.test(path)){
      this.setState({activeMenu: 'gateway'})
    }else if(systemConfs.indexOf(path) !== -1){
      this.setState({activeMenu: 'sysConf'})
    }else if(applicationReg.test(path)){
      this.setState({activeMenu: 'app'})
    }else if(multReg.test(path)){
      this.setState({activeMenu: 'mult'})
    }else if(organReg.test(path)){
      this.setState({activeMenu: 'organ'})
    }
  }
  
  changeLang = lang=>{
    i18n.changeLanguage(lang)
    this.props.setCurrLang(lang)
    this.onLangMenuClose()
  }

  render() {
    let drawerIcon;
    if (!this.props.drawerOpen) {
      drawerIcon = <MenuIcon />;
    } else {
      drawerIcon = <Backburger />;
    }


    const open = Boolean(this.state.menuAnchor);
    const { t } = this.props;
    return(
      <AppBar className={this.props.classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="toggle drawer"
            onClick={this.handleDrawerToggle}
            className={this.props.classes.menuButton}
          >
            {drawerIcon}
          </IconButton>

          <div className={this.props.classes.logoBox}>
          {this.state.organization && <Button component={Link} to={`/organizations/${this.state.organization.id}`}>
              <img src={this.state.SysSet.logo} className={this.props.classes.logo} alt="Zysmart" />
              {/* <img src='/logo/mini-logo.png' className={this.props.classes.miniLogo} alt="Zysmart" /> */}
            </Button>}
          </div>

          {!this.state.searchFocus && <div className={this.props.classes.topMenuBox}>
            {this.state.organization && <Button onClick={this.hideLeftMenu} style={{textTransform: 'none'}} className={this.state.activeMenu === 'index' ? this.props.classes.activeMenu : ''} size="large" color="inherit" component={Link} to={`/organizations/${this.state.organization.id}`}>{t('dashboard')}</Button>}
            {this.state.organization && <Button onClick={this.hideLeftMenu} style={{textTransform: 'none'}} className={this.state.activeMenu === 'app' ? this.props.classes.activeMenu : ''} size="large" color="inherit" component={Link} to={`/organizations/${this.state.organization.id}/applications`}>{t('applications')}</Button>}
            {this.state.organization && <Button onClick={this.hideLeftMenu} style={{textTransform: 'none'}} className={this.state.activeMenu === 'mult' ? this.props.classes.activeMenu : ''} size="large" color="inherit" component={Link} to={`/organizations/${this.state.organization.id}/multicast-groups`}>{t('multicastGroups')}</Button>}
            {this.state.organization && <Button onClick={this.hideLeftMenu} style={{textTransform: 'none'}} className={this.state.activeMenu === 'gateway' ? this.props.classes.activeMenu : ''} size="large" color="inherit" component={Link} to={`/organizations/${this.state.organization.id}/gateways`}>{t('gateways')}</Button>}
            
            {this.state.organization && <Button onClick={this.showOrganMenu} style={{textTransform: 'none'}} className={this.state.activeMenu === 'organ' ? this.props.classes.activeMenu : ''} size="large" color="inherit">{t('organizationsManage')}</Button>}
            <Admin><Button onClick={this.showSysConfMenu} style={{textTransform: 'none'}} className={this.state.activeMenu === 'sysConf' ? this.props.classes.activeMenu : ''} size="large" color="inherit" >{t('setting')}</Button></Admin>
            
            {<a  style={{color: 'White',marginLeft:'10px',textDecoration:'none'}} id="doc" target="_blank" href={this.state.herf} >{t('OnlineDocumentation')}</a>}

          </div>}
          {/* <div>中仪lorawan网关管理系统(NS)软件</div>  */}
 
          {/* <form onSubmit={this.onSearchSubmit}>
            <Input
              placeholder="Search organization, application, gateway or device"
              className={`${this.props.classes.search} ${this.state.searchFocus ? this.props.classes.searchFocus : ''}`}
              disableUnderline={true}
              value={this.state.search || ""}
              onChange={this.onSearchChange}_
              onFocus={this.onSearchFocus}
              onBlur={this.onSearchBlur}
              startAdornment={
                <InputAdornment position="start">
                  <Magnify />
                </InputAdornment>
              }
            />
          </form> */}

          <SearchBox/>
          <ChangeLang updateLang={this.props.updateLang} />

          {/* <a href="https://www.chirpstack.io/application-server/" target="chirpstack-doc">
            <IconButton className={this.props.classes.iconButton}>
              <HelpCicle />
            </IconButton>
          </a> */}

          <Chip
            avatar={
              <Avatar>
                <AccountCircle />
              </Avatar>
            }
            label={this.props.user.email}
            onClick={this.onMenuOpen}
            color="primary"
            classes={{
              root: this.props.classes.chip,
            }}
          />
          <Menu
            id="menu-appbar"
            anchorEl={this.state.menuAnchor}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={open}
            onClose={this.onMenuClose}
          >
            {!this.state.oidcEnabled && <MenuItem onClick={this.onMenuClose} component={Link} to={`/users/${this.props.user.id}/password`}>{t('changePassword')}</MenuItem>}
            <MenuItem onClick={this.onLogout}>{t('logout')}</MenuItem>
          </Menu>
          <IconButton
            color="inherit"
            aria-label="toggle drawer"
            onClick={this.handleMobileMenu}
            className={this.props.classes.mobileMenuBtn}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
    );
  }
}

export default withStyles(styles)(withRouter(withTranslation()(TopNav)));
