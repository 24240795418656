import React, { Component } from "react";
import { withRouter, Link } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import { CardContent } from "@material-ui/core";

import { withTranslation } from "react-i18next";

import TitleBar from "../../components/TitleBar";
import TitleBarTitle from "../../components/TitleBarTitle";
import ExtraChannelItemForm from "./ExtraChannelItemForm";
import InternalStore from "../../stores/InternalStore";




const styles = {
  card: {
    overflow: "visible",
  },
};


class CreateExtraChannelItem extends Component {
  constructor() {
    super();
    this.state = {
      
    };
    this.onSubmit = this.onSubmit.bind(this);
   // this.closeDialog = this.closeDialog.bind(this);
  }

  componentDidMount() {
   console.log("====chuangjian============",this.props.match)
  }



  onSubmit(extraChannelItem) {
    console.log("====chuangjian============",this.props.match.params.extraChannelsID)

    // let object = this.state.object;
    // object[field] = newCode;
    // this.setState({
    //   object: object,
    // });
    extraChannelItem.extra_channels_id=this.props.match.params.extraChannelsID
    console.log("====extraChannelItem============",extraChannelItem)
    InternalStore.CreateExtraChannelItem(extraChannelItem, resp => {
      this.props.history.push(`/extra-channels/${this.props.match.params.extraChannelsID}`);
    });
  }

  render() {
    const { t } = this.props
    return(
      <Grid container spacing={4}>
      

        <TitleBar>
          <TitleBarTitle title={t('Subchannel')} to="/extra-channels" />
          <TitleBarTitle title="/" />
          <TitleBarTitle title={t('create')} />
        </TitleBar>

        <Grid item xs={12}>
          <Card className={this.props.classes.card}>
            <CardContent>
              <ExtraChannelItemForm
                submitLabel={t('CreateSubchannel')}
                onSubmit={this.onSubmit}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(withRouter(withTranslation()(CreateExtraChannelItem)));
