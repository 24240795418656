import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { IconButton } from "@material-ui/core";
import Magnify from "mdi-material-ui/Magnify";
import Input from "@material-ui/core/Input";
import { withStyles } from '@material-ui/core/styles';
import CloseIcon from 'mdi-material-ui/Close';

import { withTranslation } from 'react-i18next';

const styles = {
    closeBtn: {
        float: 'right'
    },
    searchBox: {
    }
}

class SearchBox extends Component{

    constructor(){
        super()
        this.state = {
            open: false,
            search: '',
        }
    }

   handleClickOpen = () => {
    this.setState({
        open : true
    })
  }

   handleClose = () => {
    this.setState({
        open : false
    })
  }

   onSearchChange = (e) => {
    var str = e.target.value
    var value = str.replace(/\s*/g,"");
    value=value.replace(":","")
    value=value.replace("：","")
    this.setState({
        search : value
    })
  }

   onSearchSubmit = (e) => {
    e.preventDefault();
    this.setState({
        open : false
    })
    this.props.history.push(`/search?search=${encodeURIComponent(this.state.search)}`);
  }

  render (){
     const { t } = this.props;
      return(
        <div>

        <IconButton
              color="inherit"
              onClick={this.handleClickOpen}
            >
              <Magnify />
      </IconButton>
        <Dialog open={this.state.open} onClose={this.handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">
              <span></span>
            <IconButton className={this.props.classes.closeBtn} edge="start" color="inherit" onClick={this.handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent  className={this.props.classes.searchBox}>

          <DialogContentText>
          {t('searchTips')}
          </DialogContentText>

          <form onSubmit={this.onSearchSubmit}>
              <Input
                autoFocus
                fullWidth
                placeholder=""
                value={this.state.search || ""}
                onChange={this.onSearchChange}
              />
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.onSearchSubmit} color="primary">
              {t('search')}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      );
    }
}
export default withStyles(styles)(withRouter(withTranslation()(SearchBox)));