import React, { Component } from "react";
import { withRouter } from 'react-router-dom';

import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import CloseIcon from 'mdi-material-ui/Close';
import SideNav from './SideNav';

import theme from "../theme";


const styles = {
    appBar: {
        position: 'relative',
        zIndex: 1201,
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    logo: {
      height: 32,
    },
    flex: {
      flex: 1,
    },
    closeBtn: {
      float: 'right',
    }
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

let UNLISTEN
class MobileMenu extends Component {

    constructor(){
        super()
        this.state = {
            open: false,
        }
    }


    handleClose = ()=>{
      this.props.setMobileMenuOpen(false)
    }

    componentDidMount(){
      UNLISTEN = this.props.history.listen(location=>{
        this.props.setMobileMenuOpen(false)
      })
    }

    componentWillUnmount(){
      UNLISTEN && UNLISTEN()
    }



    render() {
      return(
        <Dialog fullScreen open={this.props.open} onClose={this.handleClose} TransitionComponent={Transition}>
        <AppBar className={this.props.classes.appBar}>
          <Toolbar>
          <div className={this.props.classes.flex}>
            <img src="/logo/logo.png" className={this.props.classes.logo} alt="ChirpStack.io" />
          </div>
            <IconButton edge="start" color="inherit" onClick={this.handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <SideNav open={this.props.open} user={this.state.user} isMobile={true}/>
      </Dialog>

      );
    }
  }
  
  export default withStyles(styles)(withRouter(MobileMenu));