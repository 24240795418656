import React, { Component } from "react";
import { withRouter } from 'react-router-dom';

import { withStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from "@material-ui/core/CardContent";
import { withTranslation } from "react-i18next";

import InternalStore from "../../stores/InternalStore";
import ExtraChannelsForm from "./ExtraChannelsForm";


const styles = {
  card: {
    overflow: "visible",
  },
};


class UpdateExtraChannels extends Component {
  constructor() {
    super();
    this.onSubmit = this.onSubmit.bind(this);
  }
  onSubmit(extraChannels) {
    console.log("====this.props.extraChannels===",this.props.extraChannels.extraChannels)
    console.log("====this.props.extraChannels===",extraChannels)
    InternalStore.UpdateExtraChannels(this.props.extraChannels, resp => {
      this.props.history.push(`/extra-channels/${extraChannels.extraChannelsID}`);
    });
  }

  render() {
    const { t } = this.props
    return(
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Card className={this.props.classes.card}>
            <CardContent>
              <ExtraChannelsForm
                submitLabel={t('update')}
                object={this.props.extraChannels}
                onSubmit={this.onSubmit}
                update={true}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  }
}
export default withStyles(styles)(withRouter(withTranslation()(UpdateExtraChannels)));
