import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import { withStyles } from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Button from '@material-ui/core/Button';
import TableSortLabel from '@material-ui/core/TableSortLabel';

import Refresh from "mdi-material-ui/Refresh";
import Delete from "mdi-material-ui/Delete";
import Counter from "mdi-material-ui/Counter";
import SerialPort from "mdi-material-ui/SerialPort";
import Signal from "mdi-material-ui/Signal";
import AspectRatio from "mdi-material-ui/AspectRatio";
import SendOutline from "mdi-material-ui/SendOutline";
import ContentCopy from "mdi-material-ui/ContentCopy";

import moment from "moment";
import { withTranslation } from "react-i18next";

//import TableCellLink from "../../components/TableCellLink";
import DeviceQueueItemForm from "./DeviceQueueItemForm";
import DeviceQueueStore from "../../stores/DeviceQueueStore";
import DeviceStore from "../../stores/DeviceStore";
import Paper from "../../components/Paper";
import theme from "../../theme";




import { Line, Bar } from "react-chartjs-2";
import Heatmap from "../../components/Heatmap";
import MulticastGroupStore from "../../stores/MulticastGroupStore";


import copy from "copy-to-clipboard";



const styles = {
  dataContainer: {
    textAlign: 'center',
    lineHeight: '1.375rem',
  },
  dataIcon: {
    fontSize: '2rem',
    color: theme.palette.primary.main,
  },
  dataValue: {
    fontSize: '1rem',
    color: '#282f3a',
    margin: 0,
    marginBottom: '4px!important',
  },
  dataName: {
    fontSize: '.875rem',
    color: '#8898aa',
    margin: 0,
  },
  blank20: {
    height: '20px',
  },
  link: {
    textDecoration: "none",
    color: theme.palette.primary.main,
    cursor: "pointer",
  },
  chart: {
    height: 300,
  },
  barOptions :{
    animation: false,
    plugins: {
      legend: {
        display: true,
      },
    },
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
      },
      x: {
        type: "time",
      },
    },
  },
}

class DetailsCard extends Component {
  constructor() {
    super();
    this.state = {};
    this.getData = this.getData.bind(this);
    this.onCopy = this.onCopy.bind(this);
    
  }
  componentDidMount(){
    this.getData()
  }
  TimeZone(t) {

  }
  getData(){
    
      //获取设备详情
      DeviceStore.DeviceDetails(this.props.device.device.devEUI, resp => {
        // console.log("====resp====-",resp)
        // console.log("==-=====shiqu=====",-new Date().getTimezoneOffset()/60);
        this.setState({
          data: resp
        })
        
      })
      //设备Key

      DeviceStore.DeviceKey(this.props.device.device.devEUI, resp => {
        // console.log("DeviceKey",resp)
        this.setState({
          key: resp
        })
        
      })
  }
  onCopy(s){
    var  str = s.replace(/\s*/g,"");
    copy(str); 
  }  
  render() {
    const { t } = this.props
    
    
    // let createTime = '---'
    // if(this.state.data !== undefined){
    //   createTime = moment(Number(this.state.data.createTime)).format('lll');
    // }
    return(
      <Card>
        <CardHeader title={t('details')} />
        <CardContent>
          <Grid container spacing={1} >
            <Grid item xs={4}>
              <Paper>
                  <div className={this.props.classes.dataContainer}>
                    <Counter className={this.props.classes.dataIcon}/>
                    <p className={this.props.classes.dataValue}>
                    {this.state.data === undefined ? '' : (this.state.data.fCntUp ? this.state.data.fCntUp: '--')}
                    </p>
                    <p className={this.props.classes.dataName}>
                      {t('fCntUp')}
                    </p>
                  </div>
              </Paper>
            </Grid>
            <Grid item xs={4}>
              <Paper>
                  <div className={this.props.classes.dataContainer}>
                    <Counter className={this.props.classes.dataIcon}/>
                    <p className={this.props.classes.dataValue}>
                    {this.state.data === undefined ? '' : (this.state.data.fCntDown ? this.state.data.fCntDown: '--')}
                    </p>
                    <p className={this.props.classes.dataName}>
                    {t('fCntDown')}
                    </p>
                  </div>
              </Paper>
            </Grid>
            <Grid item xs={4}>
              <Paper>
                  <div className={this.props.classes.dataContainer}>
                    <SerialPort className={this.props.classes.dataIcon}/>
                    <p className={this.props.classes.dataValue}>
                    {this.state.data === undefined ? '' : (this.state.data.fPort ? this.state.data.fPort: '--')}
                    </p>
                    <p className={this.props.classes.dataName}>
                    {t('fPort')}
                    </p>
                  </div>
              </Paper>
            </Grid>
            <Grid item xs={4}>
              <Paper>
                  <div className={this.props.classes.dataContainer}>
                    <Signal className={this.props.classes.dataIcon}/>
                    <p className={this.props.classes.dataValue}>
                    {this.state.data === undefined ? '' : (this.state.data.rssi ? this.state.data.rssi: '--')}
                    </p>
                    <p className={this.props.classes.dataName}>
                    {t('RSSI2')}
                    </p>
                  </div>
              </Paper>
            </Grid>
            <Grid item xs={4}>
              <Paper>
                  <div className={this.props.classes.dataContainer}>
                    <AspectRatio className={this.props.classes.dataIcon}/>
                    <p className={this.props.classes.dataValue}>
                    {this.state.data === undefined ? '' : (this.state.data.snr ? this.state.data.snr: '--')}
                    </p>
                    <p className={this.props.classes.dataName}>
                    {t('SNR2')}
                    </p>
                  </div>
              </Paper>
            </Grid>
            <Grid item xs={4}>
              <Paper>
                  <div className={this.props.classes.dataContainer}>
                    <SendOutline className={this.props.classes.dataIcon}/>
                    <p className={this.props.classes.dataValue}>
                    {this.state.data === undefined ? '' : (this.state.data.freq ? this.state.data.freq: '--')}
                    </p>
                    <p className={this.props.classes.dataName}>
                    {t('freq2')}
                    </p>
                  </div>
              </Paper>
            </Grid>
          </Grid>
          <div className={this.props.classes.blank20}></div>
          <Table>
            <TableBody>
{/*               <TableRow>
                <TableCell>{t('name')}</TableCell>
                <TableCell>{this.props.device.device.name}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t('description')}</TableCell>
                <TableCell>{this.props.device.device.description}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t('deviceProfile2')}</TableCell>
                <TableCellLink to={`/organizations/${this.props.match.params.organizationID}/device-profiles/${this.props.deviceProfile.deviceProfile.id}`}>{this.props.deviceProfile.deviceProfile.name}</TableCellLink>
              </TableRow> */}
              <TableRow>
                <TableCell>DevEUI</TableCell>
                <TableCell width="200px" >{this.state.key === undefined ? '' : (this.state.key.devEui ? this.state.key.devEui: '--')}</TableCell>
                 
                <TableCell align="right"   onClick={()=>{this.onCopy(this.state.key.devEui)}} >
                  <IconButton
                   
                    color="primary"
                    title={t('copy')}
                  >
                    <ContentCopy/>
                  </IconButton>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>AppEUI</TableCell>
                <TableCell width="200px">{this.state.key === undefined ? '' : (this.state.key.appEui ? this.state.key.appEui: '--')}</TableCell>
                <TableCell align="right"  onClick={()=>{this.onCopy(this.state.key.appEui)}}>
                  <IconButton
                    color="primary"
                    title={t('copy')}
                  >
                    <ContentCopy/>
                  </IconButton>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>DevAddr</TableCell>
                <TableCell width="200px" >{this.state.key === undefined ? '' : (this.state.key.devAddr ? this.state.key.devAddr: '--')}</TableCell>
                <TableCell align="right" onClick={()=>{this.onCopy(this.state.key.devAddr)}}>
                  <IconButton
                    color="primary"
                    title={t('copy')}
                  >
                    <ContentCopy/>
                  </IconButton>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>AppKey</TableCell>
                <TableCell  width="200px">{this.state.key === undefined ? '' : (this.state.key.appKey ? this.state.key.appKey: '--')}</TableCell>
                <TableCell align="right" onClick={()=>{this.onCopy(this.state.key.appKey)}}>
                  <IconButton
                    color="primary"
                    title={t('copy')}
                  >
                    <ContentCopy/>
                  </IconButton>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>AppSKey</TableCell>
                <TableCell width="200px">{this.state.key === undefined ? '' : (this.state.key.appSKey ? this.state.key.appSKey: '--')}</TableCell>
                <TableCell align="right" onClick={()=>{this.onCopy(this.state.key.appSKey)}}>
                  <IconButton
                    color="primary"
                    title={t('copy')}
                  >
                    <ContentCopy/>
                  </IconButton>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>NwkSKey</TableCell>
                <TableCell width="200px">{this.state.key === undefined ? '' : (this.state.key.nwkSKey ? this.state.key.nwkSKey: '--')}</TableCell>
                <TableCell align="right" onClick={()=>{this.onCopy(this.state.key.nwkSKey)}}>
                  <IconButton
                    color="primary"
                    title={t('copy')}
                  >
                    <ContentCopy/>
                  </IconButton>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    );
  }
}

DetailsCard = withStyles(styles)(withTranslation()(DetailsCard));


class StatusCard extends Component {

  constructor() {
    super();
    this.state = {};
    this.getData = this.getData.bind(this);
  }
  componentDidMount(){
    this.getData()
  }
  getData(){
    
      //获取设备详情
      DeviceStore.DeviceState(this.props.device.device.devEUI, resp => {
        // console.log("resp",resp)
        this.setState({
          data: resp
        })
        
      })
      //设备Key

     
  }
  render() {
    const { t } = this.props
    let lastSeenAt = "never";
    let state = t('enabled');

    if (this.props.device.lastSeenAt !== null) {
      // lastSeenAt = moment(this.props.device.lastSeenAt).format("lll");
      lastSeenAt =this.props.device.lastSeenAt
      lastSeenAt = lastSeenAt.split(".")[0]
      lastSeenAt= lastSeenAt.slice(0,10)+" "+lastSeenAt.slice(-8)
    }

    if (this.props.device.device.isDisabled === true) {
      state = t('disabled');
    }

    

    return(
      <Card>
        <CardHeader title={t('status')} />
        <CardContent>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>{t('deviceName2')}</TableCell>
                <TableCell align="right">{this.state.data === undefined ? '' : (this.state.data.deviceName ? this.state.data.deviceName: '--')}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t('activationType')}</TableCell>
                <TableCell align="right"> {this.state.data === undefined ? '' : (this.state.data.activationType ? this.state.data.activationType: '--')}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t('FrequencyBand')}</TableCell>  
                <TableCell align="right">{this.state.data === undefined ? '' : (this.state.data.frequencyBand ? this.state.data.frequencyBand: '--')}</TableCell>
              </TableRow>

              
              <TableRow>
                <TableCell>Class</TableCell>  
                <TableCell align="right">{this.state.data === undefined ? '' : (this.state.data.class ? this.state.data.class: '--')}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t('supportClassB')}</TableCell>
                <TableCell align="right">{this.state.data === undefined ? '' : (this.state.data.supportClassB ? this.state.data.supportClassB: '--')}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t('supportClassC')}</TableCell>     
                <TableCell align="right">{this.state.data === undefined ? '' : (this.state.data.supportClassC ? this.state.data.supportClassC: '--')}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t('lastSeen2')}</TableCell> 
                {/* <TableCell align="right">{lastSeenAt}</TableCell> */}
                <TableCell align="right">{this.state.data === undefined ? '' : (this.state.data.lastSeen ? this.state.data.lastSeen: '--')}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t('deviceCreateTime')}</TableCell>
                <TableCell align="right">{this.state.data === undefined ? '' : (this.state.data.deviceCreateTime ? this.state.data.deviceCreateTime: '--')}</TableCell>
              </TableRow>
              {/* <TableRow>
                <TableCell>{t('deviceCreateTime')}</TableCell>
                <TableCell align="right"></TableCell>
              </TableRow> */}
              {/* <TableRow>
                <TableCell>{t('subnetType')}</TableCell>               
                <TableCell align="right">{this.state.data === undefined ? '' : (this.state.data.subnetType ? this.state.data.subnetType: '--')}</TableCell>
              </TableRow> */}

              <TableRow>
                <TableCell>{t('macVersion')}</TableCell>               
                <TableCell align="right">{this.state.data === undefined ? '' : (this.state.data.macVersion ? this.state.data.macVersion: '--')}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t('DR')}</TableCell>
                <TableCell align="right">{this.state.data === undefined ? '' : (this.state.data.dr ? this.state.data.dr: '--')}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell>{t('ADR')}</TableCell>
                <TableCell align="right">{this.state.data === undefined ? '' : (this.state.data.adr ? this.state.data.adr: '--')}</TableCell>

              </TableRow>

              <TableRow>
                <TableCell>{t('fPort')}</TableCell>
                <TableCell align="right">{this.state.data === undefined ? '' : (this.state.data.fPort ? this.state.data.fPort: '--')}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t('state')}</TableCell>
                <TableCell align="right">{state}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    );
  }
}
StatusCard = withTranslation()(StatusCard);

//最新一帧数据卡片
class LatestFrameDataCard extends Component {
  constructor() {
    super();
    this.state = {

      DeviceGatewayItem: [],
    };
    this.getData = this.getData.bind(this);
  }
  componentDidMount(){
    this.getData()
  }
  getData(){

      DeviceStore.DeviceLates(this.props.device.device.devEUI, resp => {
        this.setState({
          data: resp
        })
      })

      DeviceStore.DeviceGateway(this.props.device.device.devEUI, resp => {
        // console.log("list",resp)
        this.setState({
          DeviceGatewayItem: resp.result
        })
        // console.log("===",this.state.DeviceGatewayItem)
      })
  }

  render() {
    const { t } = this.props
    const rows = this.state.DeviceGatewayItem.map((item, i) => <GatewayIdCardRow key={i} item={item}/>);

    return(
      <Card>
        <CardHeader title={t('latestFrameData')} />
        <CardContent>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>{t('gatewayId')}</TableCell>
            
                <TableCell align="right">{this.state.data === undefined ? '' : (this.state.data.gatewayId ? this.state.data.gatewayId: '--')}</TableCell>

              </TableRow>
              <TableRow>
                <TableCell>{t('gatewayName2')}</TableCell>
                <TableCell align="right">{this.state.data === undefined ? '' : (this.state.data.gatewayName ? this.state.data.gatewayName: '--')}</TableCell>

              </TableRow>
              <TableRow>
                <TableCell>{t('RSSI2')}</TableCell>
                <TableCell align="right">{this.state.data === undefined ? '' : (this.state.data.rssi ? this.state.data.rssi: '--')}</TableCell>

              </TableRow>
              <TableRow>
                <TableCell>{t('SNR2')}</TableCell>
                <TableCell align="right">{this.state.data === undefined ? '' : (this.state.data.snr ? this.state.data.snr: '--')}</TableCell>

              </TableRow>
              <TableRow>
                <TableCell>{t('freq2')}</TableCell>
                <TableCell align="right">{this.state.data === undefined ? '' : (this.state.data.freq ? this.state.data.freq: '--')}</TableCell>

              </TableRow>
            </TableBody>
          </Table>
          <div className={this.props.classes.blank20}></div>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sortDirection="asc">
                <TableSortLabel
                  active={true}
                  direction='asc'
                >
                  {t('gatewayId')}
                </TableSortLabel>
                </TableCell>
                <TableCell>{t('RSSI2')}</TableCell>
                <TableCell>{t('SNR2')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
               {rows}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    );
  }
}

class GatewayIdCardRow extends Component {
  
  render() {
    let confirmed = "no";
    if (this.props.item.confirmed) {
      confirmed = "yes";
    }

    return(
      <TableRow>
        <TableCell>{this.props.item.gatewayId}</TableCell>
        <TableCell>{this.props.item.rssi}</TableCell>
      
        <TableCell>{this.props.item.snr}</TableCell>
      </TableRow>
    );
  }
}
LatestFrameDataCard = withStyles(styles)(withTranslation()(LatestFrameDataCard));

class EnqueueCard extends Component {
  constructor() {
    super();

    this.state = {
      object: {},
    };
  }

  onSubmit = (queueItem) => {
   
    let qi = queueItem;
    qi.devEUI = this.props.match.params.devEUI;

    DeviceQueueStore.enqueue(qi, resp => {
      this.setState({
        object: {},
      });
    });
  }

  render() {
    const { t } = this.props
    return(
      <Card>
        <CardHeader title={t('enqueueDownlinkPayload')} />
        <CardContent>
          <DeviceQueueItemForm
            submitLabel={t('enqueuePayload')}
            onSubmit={this.onSubmit}
            object={this.state.object}
          />
        </CardContent>
      </Card>
    );
  }
}

EnqueueCard = withRouter(withTranslation()(EnqueueCard));


class QueueCardRow extends Component {
  render() {
    let confirmed = "no";
    if (this.props.item.confirmed) {
      confirmed = "yes";
    }

    return(
      <TableRow>
        <TableCell>{this.props.item.fCnt}</TableCell>
        <TableCell>{this.props.item.fPort}</TableCell>
        <TableCell>{confirmed}</TableCell>
        <TableCell>{this.props.item.data}</TableCell>
      </TableRow>
    );
  }
}


class QueueCard extends Component {
  constructor() {
    super();

    this.state = {
      deviceQueueItems: [],
    };
    this.flushQueue = this.flushQueue.bind(this);
  }

  componentDidMount() {
    this.getQueue();

    DeviceQueueStore.on("enqueue", this.getQueue);
  }

  componentWillUnmount() {
    DeviceQueueStore.removeListener("enqueue", this.getQueue);
  }

  getQueue = () => {
    DeviceQueueStore.list(this.props.match.params.devEUI, resp => {
      this.setState({
        deviceQueueItems: resp.deviceQueueItems,
      });
    });
  }

  flushQueue = () => {
    if (window.confirm(this.props.t('flushDeviceQueueTips'))) {
      DeviceQueueStore.flush(this.props.match.params.devEUI, resp => {
        this.getQueue();
      });
    }
  }

  render() {
    const rows = this.state.deviceQueueItems.map((item, i) => <QueueCardRow key={i} item={item}/>);

    const { t } = this.props

    return(
      <Card>
        <CardHeader title={t('downlinkQueue')} action={
          <div>
            <Button onClick={this.getQueue}><Refresh /></Button>
            <Button onClick={this.flushQueue} color="secondary"><Delete /></Button>
          </div>
        } />
        <CardContent>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>FCnt</TableCell>
                <TableCell>FPort</TableCell>
                <TableCell>{t('confirmed')}</TableCell>
                <TableCell>{t('hexEncodedPayload')}</TableCell>
            
              </TableRow>
            </TableHead>
            <TableBody>
              {rows}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    );
  }
}

QueueCard = withRouter(withTranslation()(QueueCard));


class DeviceDetails extends Component {
  constructor() {
    super();
    this.state = {
      activated: false,
      multicastGroups: [],
    };
  }
  componentDidMount() {
    this.setDeviceActivation();
    this.setMulticastGroups();
    this.loadStats();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.device !== this.props.device) {
      this.setDeviceActivation();
      this.setMulticastGroups();
    }
  }
  setDeviceActivation = () => {
    if (this.props.device === undefined) {
      return;
    }

    DeviceStore.getActivation(this.props.device.device.devEUI, resp => {
      if (resp === null) {
        this.setState({
          activated: false,
        });
      } else {
        this.setState({
          activated: true,
        });
      }
    });
  };

  setMulticastGroups = () => {
    MulticastGroupStore.list("", 0, "", this.props.device.device.devEUI, 999, 0, resp => {
      this.setState({
        multicastGroups: resp.result,
      });
    });
  }

  loadStats = () => {
    const end = moment().toISOString();
    const start = moment().subtract(7, "days").toISOString();

    DeviceStore.getStats(this.props.match.params.devEUI, start, end, resp => {
      let statsUp = {
        labels: [],
        datasets: [
          {
            label: "uplink",
            borderColor: "rgba(33, 150, 243, 1)",
            backgroundColor: "rgba(0, 0, 0, 0)",
            lineTension: 0,
            pointBackgroundColor: "rgba(33, 150, 243, 1)",
            data: [],
          },
        ],
      };

      let statsGwRssi = {
        labels: [],
        datasets: [
          {
            label: "rssi (reported by gateways)",
            borderColor: "rgba(33, 150, 243, 1)",
            backgroundColor: "rgba(0, 0, 0, 0)",
            lineTension: 0,
            pointBackgroundColor: "rgba(33, 150, 243, 1)",
            data: [],
          },
        ],
      };

      let statsGwSnr = {
        labels: [],
        datasets: [
          {
            label: "snr (reported by gateways)",
            borderColor: "rgba(33, 150, 243, 1)",
            backgroundColor: "rgba(0, 0, 0, 0)",
            lineTension: 0,
            pointBackgroundColor: "rgba(33, 150, 243, 1)",
            data: [],
          },
        ],
      };

      let statsErrors = {
        labels: [],
        datasets: [],
      };
      let statsErrorsSet = {};

      let statsUpDr = {
        labels: [],
        datasets: [],
      };
      let statsUpDrSet = [];

      let statsUpFreq = {
        labels: [],
        datasets: [],
      };
      let statsUpFreqSet = [];

      for (const row of resp.result) {
        statsUp.labels.push(moment(row.timestamp).format("YYYY-MM-DD"));
        statsUp.datasets[0].data.push(row.rxPackets);

        statsGwRssi.labels.push(moment(row.timestamp).format("YYYY-MM-DD"));
        statsGwSnr.labels.push(moment(row.timestamp).format("YYYY-MM-DD"));

        if (row.rxPackets !== 0) {
          statsGwRssi.datasets[0].data.push(row.gwRssi);
          statsGwSnr.datasets[0].data.push(row.gwSnr);
        } else {
          statsGwRssi.datasets[0].data.push(null);
          statsGwSnr.datasets[0].data.push(null);
        }


        statsErrors.labels.push(moment(row.timestamp).format("YYYY-MM-DD"));
        Object.entries(row.errors).forEach(([k, v]) => {
          if (statsErrorsSet[k] === undefined) {
            statsErrorsSet[k] = [];
          }

          // fill gaps with 0s
          for (let i = statsErrorsSet[k].length; i < statsErrors.labels.length - 1; i++) {
            statsErrorsSet[k].push(0);
          }

          statsErrorsSet[k].push(v);
        });

        statsUpDr.labels.push(moment(row.timestamp).format("YYYY-MM-DD"));
        Object.entries(row.rxPacketsPerDr).forEach(([k, v]) => {
          if (statsUpDrSet[k] === undefined) {
            statsUpDrSet[k] = [];
          }

          // fill gaps with 0s
          for (let i = statsUpDrSet[k].length; i < statsUpDr.labels.length - 1; i++) {
            statsUpDrSet[k].push(0);
          }

          statsUpDrSet[k].push(v);
        });


        statsUpFreq.labels.push(moment(row.timestamp).format("YYYY-MM-DD"));
        Object.entries(row.rxPacketsPerFrequency).forEach(([k, v]) => {
          if (statsUpFreqSet[k] === undefined) {
            statsUpFreqSet[k] = [];
          }

          // fill gaps with 0s
          for (let i = statsUpFreqSet[k].length; i < statsUpFreq.labels.length - 1; i++) {
            statsUpFreqSet[k].push(0);
          }

          statsUpFreqSet[k].push(v);
        });
      }

      let backgroundColors = ['#8bc34a', '#ff5722', '#ff9800', '#ffc107', '#ffeb3b', '#cddc39', '#4caf50', '#009688', '#00bcd4', '#03a9f4', '#2196f3', '#3f51b5', '#673ab7', '#9c27b0', '#e91e63'];

      Object.entries(statsErrorsSet).forEach(([k, v]) => {
        statsErrors.datasets.push({
          label: k,
          data: v,
          backgroundColor: backgroundColors.shift(),
        });
      });

      backgroundColors = ['#8bc34a', '#ff5722', '#ff9800', '#ffc107', '#ffeb3b', '#cddc39', '#4caf50', '#009688', '#00bcd4', '#03a9f4', '#2196f3', '#3f51b5', '#673ab7', '#9c27b0', '#e91e63'];

      Object.entries(statsUpDrSet).forEach(([k, v]) => {
        statsUpDr.datasets.push({
          label: k,
          data: v,
          backgroundColor: backgroundColors.shift(),
        });
      });

      Object.entries(statsUpFreqSet).forEach(([k, v]) => {
        statsUpFreq.datasets.push({
          label: k,
          data: v,
          backgroundColor: backgroundColors.shift(),
        });
      });

      this.setState({
        statsUp: statsUp,
        statsGwRssi: statsGwRssi,
        statsGwSnr: statsGwSnr,
        statsUpFreq: statsUpFreq,
        statsErrors: statsErrors,
        statsUpDr: statsUpDr,
      });
    });
  }

  render() {
    if (this.state.statsUp === undefined) {
      return null;
    }

    const statsOptionsX = {
      animation: false,
      plugins: {
        legend: {
          display: false,
        },
      },
      maintainAspectRatio: false,
      scales: {
        y: {
          beginAtZero: true,
        },
        x: {
          type: "time",
        },
      },
    };

    const statsOptions = {
      legend: {
        display: false,
      },
      maintainAspectRatio: false,
      scales: {
        yAxes: [{
          ticks: {
            beginAtZero: true,
          },
        }],
      },
    }

    const barOptions = {
      animation: false,
      plugins: {
        legend: {
          display: true,
        },
      },
      maintainAspectRatio: false,
      scales: {
        y: {
          beginAtZero: true,
        },
        x: {
        //   type: "time",
        },
      },
    };
    return(
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6} md={4}>
          <DetailsCard device={this.props.device} deviceProfile={this.props.deviceProfile} match={this.props.match} />
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <StatusCard device={this.props.device} />
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <LatestFrameDataCard  device={this.props.device}/>
        </Grid>

        {this.state.activated&&<Grid item xs={6}>
          <Card>
            <CardHeader title="Received" />
            <CardContent className={this.props.classes.chart}>
            <Line height={75} options={statsOptions} data={this.state.statsUp} redraw />
            </CardContent>
          </Card>
        </Grid>}
        {this.state.activated&&<Grid item xs={6}>
          <Card>
            <CardHeader title="Errors" />
            <CardContent className={this.props.classes.chart}>
              <Bar data={this.state.statsErrors} options={barOptions} />
            </CardContent>
          </Card>
        </Grid>}
        {this.state.activated&&<Grid item xs={6}>
          <Card>
            <CardHeader title="SNR" />
            <CardContent className={this.props.classes.chart}>
              <Line height={75} options={statsOptions} data={this.state.statsGwSnr} />
            </CardContent>
          </Card>
        </Grid>}
        {this.state.activated&&<Grid item xs={6}>
          <Card>
            <CardHeader title="RSSI" />
            <CardContent className={this.props.classes.chart}>
              <Line height={75} options={statsOptions} data={this.state.statsGwRssi} />
            </CardContent>
          </Card>
        </Grid>}

        {this.state.activated&&<Grid item xs={6}>
          <Card>
            <CardHeader title="Received / frequency" />
            <CardContent className={this.props.classes.chart}>

              <Bar data={this.state.statsUpFreq} options={barOptions} />
            </CardContent>
          </Card>
        </Grid>}
        {this.state.activated &&<Grid item xs={6}>
          <Card>
            <CardHeader title="Received / DR" />
            <CardContent className={this.props.classes.chart}>

              <Bar data={this.state.statsUpDr} options={barOptions} />
            </CardContent>
          </Card>
        </Grid> }
 
        {this.state.activated && <Grid item xs={12}>
          <EnqueueCard />
        </Grid>}
        {this.state.activated &&<Grid item xs={12}>
          <QueueCard />
        </Grid>}
      </Grid>
    );
  }
}


export default withStyles(styles)(DeviceDetails);


