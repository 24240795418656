import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import queryString from "query-string";
import Form from "../../components/Form";
import ChangeLang from "../../components/ChangeLang";
import FormComponent from "../../classes/FormComponent";
import SessionStore from "../../stores/SessionStore";
import InternalStore from "../../stores/InternalStore";
import theme from "../../theme";
import { withTranslation } from 'react-i18next';
import Translate from "mdi-material-ui/Translate";
import ChevronDown from "mdi-material-ui/ChevronDown";
import i18n from "../../i18n/i18n";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import { red } from "@material-ui/core/colors";
import { makeStyles } from '@material-ui/styles';



const styles = {
  textField: {
    width: "100%",
  },
  link: {
    "& a": {
      color: theme.palette.primary.main,
      textDecoration: "none",
    },
  },
  langContainer: {
    //display: 'inline-block',
    float: 'right',
    //backgroundColor:"#396690",
    color:"#e8f0fe",
  },
  login: {
    color:"#e8f0fe !important",
    "& form div button span": {
      color: '#fff',
      textDecoration: "none",
    },
    "& form div label": {
      color: '#fff',
      textDecoration: "none",
    },
  },
  logo: {
    height: 32,
  },
  background: {
    backgroundColor:"#396690",
    opacity:1,    
  },
  language:{
    display : 'none'
  },
  language1:{
      display : 'block'
  }
 

};

// const useStyles = makeStyles(
//   (theme) => {
//     return {
//     '& .super-app-theme--Open': {
//       backgroundColor: getBackgroundColor(theme.palette.info.main)
      
//     }
//   } 
// )
class LoginForm extends FormComponent {
  
  render() {


    if (this.state.object === undefined) {
      return null;
    }

    return(
      <Form
        submitLabel={this.props.submitLabel}
        onSubmit={this.onSubmit}
        
      >
        <TextField
          id="email"
          label={this.props.t('usernameOrEmail')}
          margin="normal"
          value={this.state.object.email || ""}
          onChange={this.onChange}
          fullWidth
          required
         
        />
        <TextField
          id="password"
          label={this.props.t('password')}
          type="password"
          margin="normal"
          value={this.state.object.password || ""}
          onChange={this.onChange}
          fullWidth
          required
         
        />
      </Form>
    );
  }
}

class OpenIDConnectLogin extends Component {
  render() {
    return(
      <div>
        <a href={this.props.loginUrl}><Button variant="outlined">{this.props.loginLabel}</Button></a>
      </div>
    );
  }
}


class Login extends Component {
  constructor() {
    super();

    this.state = {
      loaded: false,
      registration: "",
      oidcEnabled: false,
      oidcLoginlabel: "",
      oidcLoginUrl: "",
      SysSet:{},
      langMenuAnchor: null,
      zh:true,
      en:true,
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.loLoginBackgroundgo = this.loLoginBackgroundgo.bind(this);
    this.onLangMenuOpen = this.onLangMenuOpen.bind(this);
    
    
  }


onLangMenuOpen = (e) => {
   
    InternalStore.GetSystemSettings(1, resp => {
        this.setState({
          SysSet: resp,
        });
        localStorage.setItem("Name", resp.name)
        localStorage.setItem("Favicon", resp.favicon)
        localStorage.setItem("Zh", resp.zh)
        localStorage.setItem("En", resp.en)
        
      });
    this.setState({
      langMenuAnchor: e.currentTarget,
    });
}

onLangMenuClose = () => {
    this.setState({
        langMenuAnchor: null,
    });
}

changeLang = lang=>{
    i18n.changeLanguage(lang)
    this.props.updateLang()
    localStorage.setItem('language', lang)
    this.onLangMenuClose()
}
  componentDidMount() {
    InternalStore.GetSystemSettings(1, resp => {
      this.setState({
        SysSet: resp,
      });
     
      if (resp.zh==false){
        this.setState({
            zh:false
        })
      }

      if (resp.en==false){
        console.log("====en=====",resp.en)
          this.setState({
              en:false
          })
      
      }
      
    });
    
    SessionStore.logout(true, () => {});
    this.loLoginBackgroundgo()
    InternalStore.settings(resp => {
      this.setState({
        loaded: true,
        registration: resp.branding.registration,
        oidcEnabled: resp.openidConnect.enabled,
        oidcLoginUrl: resp.openidConnect.loginURL,
        oidcLoginLabel: resp.openidConnect.loginLabel,
      });
    });

    // callback from openid provider
    if (this.props.location.search !== "") {
      let query = queryString.parse(this.props.location.search);
  
      SessionStore.openidConnectLogin(query.code, query.state, () => {
        this.props.history.push("/");
      });
    }
  }

  onLangMenuOpen = (e) => {
       
    InternalStore.GetSystemSettings(1, resp => {
        this.setState({
          SysSet: resp,
        });
        localStorage.setItem("Name", resp.name)
      });
    this.setState({
      langMenuAnchor: e.currentTarget,
    });
}

  onSubmit(login) {
    SessionStore.login(login, () => {

      this.props.history.push("/");
      this.props.loLoginBackgroundgo('')
    });
  }
  loLoginBackgroundgo(){
    this.props.loLoginBackgroundgo('/LoginBackground.png')
  }
  
  render() {
    const langOpen = Boolean(this.state.langMenuAnchor);
    if (!this.state.loaded) {
      return null;
    }
    const { t } = this.props;
 
    
    return(
      // .MuiInputLabel-animated

       <Grid container justify="center" >
        <Grid item xs={12} sm={8} md={6} lg={4}  >        
          <Card  className={this.props.classes.background}> 
            <CardHeader
              //title={`Zysmart ${t('login')}`}
              title={
                <div>
                  
                  <img src={this.state.SysSet.logo}  className={this.props.classes.logo} alt="Zysmart" />
                  {/* <ChangeLang updateLang={this.props.updateLang} className={this.props.classes.langContainer}/> */}

             
                  <Button color="inherit" onClick={this.onLangMenuOpen} className={this.props.classes.langContainer} style={{textTransform: 'none'}} title={t('changeLang')} startIcon={<Translate />} endIcon={<ChevronDown />}><span className={this.props.classes.langBtnText}>{i18n.language === 'zh' ? '中文' : (i18n.language === 'en' ? 'English' : '')}</span></Button>
                    <Menu
                    id="lang-menu"
                    anchorEl={this.state.langMenuAnchor}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    open={langOpen}
                    onClose={this.onLangMenuClose}
                    >
                  <div >
                    <MenuItem  className={ this.state.zh==false ? this.props.classes.language : this.props.classes.language1} selected={i18n.language === 'zh'} onClick={()=>{this.changeLang('zh')}}>中文</MenuItem>
                    </div> 
                    <MenuItem   className={ this.state.en==false ? this.props.classes.language : this.props.classes.language1} onClick={()=>{this.changeLang('en')}}>English</MenuItem>
                    </Menu>
          
                </div>
              }
            />

            <CardContent  className={this.props.classes.login}>
              {!this.state.oidcEnabled && <LoginForm t={t} 
                submitLabel={t('login') }
                onSubmit={this.onSubmit}
              />}

              {this.state.oidcEnabled && <OpenIDConnectLogin
                loginUrl={this.state.oidcLoginUrl}
                loginLabel={this.state.oidcLoginLabel}
              />}
            </CardContent>
            {this.state.registration !== "" && <CardContent>
              <Typography className={this.props.classes.link} dangerouslySetInnerHTML={{__html: this.state.registration}}></Typography>
             </CardContent>}
          </Card>
        </Grid>
      </Grid> 
      //  </div>
    );
  }
}

export default withStyles(styles)(withRouter(withTranslation()(Login)));
