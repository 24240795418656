import React, { Component } from "react";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import moment from "moment";
import { withTranslation } from "react-i18next";

import GatewayStore from "../../stores/GatewayStore";


class GatewayCertificate extends Component {
  constructor() {
    super();

    this.state = {
      caCert: null,
      tlsCert: null,
      tlsKey: null,
      buttonDisabled: false,
    };
  }

  requestCertificate = () => {
    this.setState({
      buttonDisabled: true,
    });

    GatewayStore.generateClientCertificate(this.props.match.params.gatewayID, (resp => {
      this.setState({
        tlsKey: resp.tlsKey,
        tlsCert: resp.tlsCert,
        caCert: resp.caCert,
        expiresAt: moment(resp.expiresAt).format("lll"),
      });
    }));
  }

  render() {
    const { t } = this.props
    return(
      <Card>
        <CardContent>
          <Typography gutterBottom>
            {t('gatewayCertTips')}
          </Typography>
          {this.state.tlsCert == null && <Button onClick={this.requestCertificate} disabled={this.state.buttonDisabled}>{t('generateCertificate')}</Button>}
          {this.state.tlsCert != null && <form>
            <TextField
              id="expiresAt"
              label={t('certificateExpiresAt')}
              margin="normal"
              value={this.state.expiresAt}
              helperText={t('certificateExpiresAtHelpText')}
              disabled
              fullWidth
            />
            <TextField
              id="caCert"
              label={t('CACertificate')}
              margin="normal"
              value={this.state.caCert}
              rows={10}
              multiline
              fullWidth
              helperText={t('gatewayCACertHelpText')}
            />
            <TextField
              id="tlsCert"
              label={t('TLSCertificate')}
              margin="normal"
              value={this.state.tlsCert}
              rows={10}
              multiline
              fullWidth
              helperText={t('gatewayTLSCertHelpText')}
            />
            <TextField
              id="tlsKey"
              label={t('TLSKey')}
              margin="normal"
              value={this.state.tlsKey}
              rows={10}
              multiline
              fullWidth
              helperText={t('gatewayTLSKeyHelpText')}
            />
          </form>}
        </CardContent>
      </Card>
    );
  }
}

export default withTranslation()(GatewayCertificate);
