import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import DataTable from "../../components/DataTable";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import DeviceStore from "../../stores/DeviceStore";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Form from "../../components/Form";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import GatewayStore from "../../stores/GatewayStore"
import Button from "@material-ui/core/Button";
import { t } from "i18next";
import Refresh from "mdi-material-ui/Refresh";
import TrendingUp  from "mdi-material-ui/TrendingUp";
import TrendingDown  from "mdi-material-ui/TrendingDown";
import Tooltip from '@material-ui/core/Tooltip';
import HistoryDialog from "../../components/HistoryDialog";
import InternalStore from "../../stores/InternalStore";
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { Link } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog';
import theme from "../../theme";

const CustomDialog = withStyles({
  paper: {
    minWidth: '400px',
  },
  list: {
    padding: 0,
  },
  listItem: {
    borderBottom: '1px solid #e0e0e0',
    padding: '10px 16px',
    '&:last-child': {
      borderBottom: 'none',
    },
  },
})(Dialog);
const CustomTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      borderRadius: 0,
    },
  },
})(TextField);


const useStyles = withStyles((theme) => ({

  


  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(10),
    marginRight: theme.spacing(1),
    width: 100,

  },
  treeStyle: {
    paddingTop: 0,
    paddingBottom: 0,
    fontSize: 12,
    lineHeight: 1.5,
  },

}));
const styles = {

  success: {
    color: theme.palette.success.main,
  },
  failure: {
    color: theme.palette.error.main,
  },

  MuiTableHead: {

    backgroundColor: "#2D8CF0",
  },
  HeadTableCell: {
    color: "#f5f5f5",
    textAlign: "center",
  },
  formControl: {
    minWidth: "100px",
    marginRight: "10px",
    marginTop: "3px",
  },
  textField: {
    marginRight: "10px",
    width: "200px",
    marginTop: "3px",
  },
  valueTableCell: {
    textAlign: "center",
    color: "black",
  },
  Refreshcss: {
    height: "65px",
  },
  online:{
    textAlign: "center",
    color:"green"
  },
  offline:{
    textAlign: "center",
 
    color:"red"
  },
  None: {
    color:'#FBEBFB',
    padding:"8px",
   },
   UnconfirmedDataUp: {
    color:'#FBEBFB',
   padding:"8px",
   backgroundColor:'#FF004D',
  },
   UnconfirmedDataDown: {
    color:'#FBEBFB',
    padding:"8px",
    backgroundColor:'#FF3300',
   },
   JoinAccept: {
    color:'#FBEBFB',
    padding:"8px",
    backgroundColor:'#19BE6B',
   },
   JoinRequest: {
    color:'#FBEBFB',
    padding:"8px",
    backgroundColor:'#034713',
   },
   ConfirmedDataUp: {
    color:'#FBEBFB',
    padding:"8px",
    backgroundColor:'#FFB300',
   },
   ConfirmedDataDown:{
    color:'#FBEBFB',
    padding:"8px",
    backgroundColor:'#FBAD4C',
   }


};
class GatewayHistory extends Component {
  constructor() {
    super();

    this.state = {
      caCert: null,
      tlsCert: null,
      tlsKey: null,
      buttonDisabled: false,
      starttime: "",
      endtime: "",
      types: "",
      msgType: "",
      tab: 0,
      state:"2",
      type:"0",
      fullWidth: true,
      maxWidth: "md",
      errType:'',
      open: false,
    };
    this.getPage = this.getPage.bind(this);
    this.getRow = this.getRow.bind(this);
    this.getRow1 = this.getRow1.bind(this);
    this.getRow2 = this.getRow2.bind(this);
    this.getRow3 = this.getRow3.bind(this);
    this.getRow4 = this.getRow4.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangemsg = this.handleChangemsg.bind(this);
    this.handleChangeTypes = this.handleChangeTypes.bind(this);
    this.handleChangeState = this.handleChangeState.bind(this);
    this.Starttime = this.Starttime.bind(this);
    this.Endtime = this.Endtime.bind(this);
    this.handleChangeErrType = this.handleChangeErrType.bind(this);
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    
    
  }


  getPage(limit, offset, callbackFunc) {
    if (this.state.tab == 0) {
    // console.log("this.props.match.params", this.props.match.params.gatewayID)
    DeviceStore.HistoryList("", this.state.msgType, this.state.types, "protocol", this.props.match.params.gatewayID, this.state.starttime, this.state.endtime, limit, offset, callbackFunc);
    }else if (this.state.tab == 1) {
      
      GatewayStore.GatewayLinkHistoryList(  this.state.type,this.state.state, this.props.match.params.gatewayID, this.state.starttime, this.state.endtime, limit, offset, callbackFunc);
    }else if (this.state.tab == 2) {
      DeviceStore.DownlinkTXAckList("",this.props.match.params.gatewayID,this.state.errType, limit, offset, callbackFunc);
    }else if (this.state.tab == 3&& !this.state.open) {
      console.log("===zhixing-----1")
      DeviceStore.UpDecodeDataList("",this.props.match.params.gatewayID, this.state.starttime, this.state.endtime,limit, offset, callbackFunc);
    } else if (this.state.tab == 3&& this.state.open){
      console.log("===zhixing-----2")
      InternalStore.AsyncTaskList(this.props.match.params.gatewayID,1,limit, offset, callbackFunc);
    }
  }
  getRow(obj) {
    return (
      <TableRow
        key={obj.id}
        hover
      >
        <TableCell className={this.props.classes.valueTableCell} width="10%">{obj.devEUI}</TableCell>
        <TableCell className={this.props.classes.valueTableCell} width="7%">{obj.devAddr}</TableCell>
        <TableCell className={this.props.classes.valueTableCell} width="15%">{obj.payloadHex}</TableCell>
        <TableCell className={this.props.classes.valueTableCell} width="4%">
        {obj.type === "up" ?  <TrendingUp/>
          :obj.type ==="down" ? <TrendingDown/>
         :"---"}
          </TableCell>
        <TableCell className={this.props.classes.valueTableCell} width="7%">
        <Tooltip title={obj.messageType} arrow>
        <span  
          className={ 
            obj.messageType==="ConfirmedDataUp" ? this.props.classes.ConfirmedDataUp
           :obj.messageType==="ConfirmedDataDown" ? this.props.classes.ConfirmedDataDown
           :obj.messageType==="UnconfirmedDataUp" ? this.props.classes.UnconfirmedDataUp 
           :obj.messageType==="UnconfirmedDataDown" ? this.props.classes.UnconfirmedDataDown 
           :obj.messageType==="JoinAccept" ? this.props.classes.JoinAccept 
           :obj.messageType==="JoinRequest" ? this.props.classes.JoinRequest 
           : this.props.classes.None}   
          >
       
          {obj.messageType === "ConfirmedDataUp" ? "CU" 
          :obj.messageType ==="ConfirmedDataDown" ? "CD" 
          :obj.messageType ==="UnconfirmedDataUp" ? "UU" 
          :obj.messageType==="UnconfirmedDataDown" ? "UD" 
          :obj.messageType==="JoinAccept" ? "JA"
          :obj.messageType==="JoinRequest" ? "JR"
         :"---"}
          </span>
          </Tooltip>
          </TableCell>
        <TableCell className={this.props.classes.valueTableCell} width="8%">{obj.frequency}</TableCell>
        <TableCell className={this.props.classes.valueTableCell} width="5%">{obj.loraSnr === undefined ? 0 : (obj.loraSnr ? obj.loraSnr: '---')}</TableCell>
        <TableCell className={this.props.classes.valueTableCell} width="5%">{obj.rssi === undefined ? 0 : (obj.rssi ? obj.rssi: '---')}</TableCell>
        <TableCell className={this.props.classes.valueTableCell} width="10%">{obj.createdAt}</TableCell>
        <TableCell className={this.props.classes.valueTableCell} width="10%"> <HistoryDialog   objectJson={obj.objectJson}  /></TableCell>
      </TableRow>
    );
  }
  getRow1(obj) {

    const classes = useStyles
    return (
      <TableRow
        key={obj.id}
        hover
      >

        <TableCell className={ obj.state=="1"  ? this.props.classes.online : this.props.classes.offline} width="33%">{ obj.state=="0" ? t('offline') :  t('Online1')}</TableCell>
        <TableCell className={this.props.classes.valueTableCell} width="33%">{obj.type}</TableCell>
        <TableCell className={this.props.classes.valueTableCell} width="33%">{obj.createdAt}</TableCell>

      </TableRow>
    );
  }

  getRow2(obj) {
    return (
      <TableRow
        key={obj.id}
        hover
      >
        <TableCell align="center" width="15%">{obj.gatewayID}</TableCell>
        <TableCell align="center" width="15%">{obj.devEUI}</TableCell>
        <TableCell align="center" width="15%">{obj.errType}</TableCell>
        <TableCell align="center" width="15%">{obj.createdAt}</TableCell>
        <TableCell align="center" width="15%">
          <HistoryDialog   objectJson={obj.dataJson}  />
        </TableCell>
      </TableRow>
    );
  }
  getRow3(obj) {
    const classes = useStyles
    return (
      <TableRow
        key={obj.id}
        hover
      >
        <TableCell align="center" width="15%">{obj.devEUI}</TableCell>
        <TableCell align="center" width="15%">{obj.gatewayID}</TableCell>
        <TableCell align="center" width="15%">{obj.createdAt}</TableCell>
        <TableCell align="center" width="15%">
          <HistoryDialog   objectJson={obj.dataJson}  />
        </TableCell>
      </TableRow>
    );
  }
  getRow4(obj) {
   
    const { t } = this.props
    const classes = useStyles


    return (
      <TableRow
        key={obj.id}
        hover
      >
        <TableCell align="center" width="15%">{obj.relevanceId}</TableCell>
        <TableCell align="center" width="5%">{
          obj.type === "1" ? t('UpDecodeData') 
         :"---"}</TableCell>
        <TableCell align="center" width="5%" className={obj.result === '3' ? this.props.classes.success : obj.result === '4' ? this.props.classes.failure : ''}>
          

          {obj.result === "1" ? t('TaskWait') 
          :obj.result ==="2" ? t('TaskInProgress') 
          :obj.result ==="3" ? t('TaskComplete') 
          :obj.result==="4" ? t('TaskFail')    
         :"---"}
        </TableCell>
        <TableCell align="center" width="5%">{obj.taskSeconds}</TableCell>

        <TableCell align="center" width="5%">
        {obj.url !== '' && (
            <Link href={`http://${obj.url}`} target="_blank" rel="noopener" style={{ display: 'block', textAlign: 'center', width: '100%' }}>
              {t('download')}
            </Link>
          )}
        </TableCell>
        <TableCell align="center" width="15%">{obj.CreatedAt}</TableCell>
   
      </TableRow>
    );
  }

  Createsynask=()=> {
    var  params = JSON.stringify({gatewayID:this.props.match.params.gatewayID,starttime:this.state.starttime,endtime:this.state.endtime});
    
    InternalStore.CreateAsyncTask(this.props.match.params.gatewayID,params,1,"dev_"+this.props.match.params.devEUI , resp => {
      console.log("======")
    });


  }

  handleClickOpen = () => {
  
    this.setState({
        open : true,
   
    })
  }
  handleClose = () => {
   
    this.setState({
        open : false
    })
  }

  handleChange(e) {
    this.setState({
      types: e.target.value,

    })
  }
  handleChangemsg(e) {
    this.setState({
      msgType: e.target.value,

    })
  }
  handleChangeErrType(e) {
    this.setState({
      errType: e.target.value,

    })
  }

  handleChangeTypes(e) {
    this.setState({
      type: e.target.value,

    })
  }
  handleChangeState(e) {
    this.setState({
      state: e.target.value,

    })
  }
  Starttime(times) {
    this.setState({
      starttime: times.target.value,
    })

  }
  Endtime(times) {
    this.setState({
      endtime: times.target.value,
    })
  }
  onTabChange = (e, v) => {

    this.setState({
      starttime: '',
      endtime: '',
      tab: v,
    });
  }
  
  render() {

    const classes = useStyles
    const { t } = this.props
    return (
      <Form
        submitLabel={this.props.submitLabel}
        onSubmit={this.onSubmit}
        disabled={this.props.disabled}
      >
        <Tabs value={this.state.tab} onChange={this.onTabChange} indicatorColor="primary">
          <Tab label={t('GatewayHistoricalMessage')} />
          <Tab label={t('GatewayStatusHistory')} />
          <Tab label={t('DownlinkTXAck')} />
          <Tab label={t('UpDecodeData')} />
        </Tabs>

        {this.state.tab === 0 && <div>
     
        <div className={classes.container} noValidate align="right"  >
          <FormControl className={this.props.classes.formControl}>
            <InputLabel shrink id="demo-simple-select-placeholder-label-label">
            {t('msgType')}
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={this.state.msgType}
              onChange={this.handleChangemsg}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={"JoinRequest"}>Join Request</MenuItem>
              <MenuItem value={"JoinAccept"}>Join Accept</MenuItem>
              <MenuItem value={"UnconfirmedDataUp"}>Unconfirmed Data Up</MenuItem>
              <MenuItem value={"UnconfirmedDataDown"}>Unconfirmed Data Down</MenuItem>
              <MenuItem value={"ConfirmedDataUp"}>Confirmed Data Up</MenuItem>
              <MenuItem value={"ConfirmedDataDown"}>Confirmed Data Down</MenuItem>
              <MenuItem value={"RejoinRequest"}>ejoin Request</MenuItem>
              <MenuItem value={"Proprietaryn"}>Proprietaryn</MenuItem>
            </Select>
          </FormControl>
          <FormControl className={this.props.classes.formControl}>
            <InputLabel shrink id="demo-simple-select-placeholder-label-label">
            {t('TransmissionDirection')} 
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={this.state.types}
              onChange={this.handleChange}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={"up"}>up</MenuItem>
              <MenuItem value={"down"}>down</MenuItem>
            </Select>
          </FormControl>


          <TextField
            id="datetime-local"
            label={t('StartingTime')}
            type="datetime-local"
            defaultValue={this.state.starttime}
            onChange={this.Starttime}
            className={this.props.classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            id="datetime-local"
            label={t('EndTime')}
            type="datetime-local"
            defaultValue={this.state.endtime || ""}
            onChange={this.Endtime}
            className={this.props.classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <Button onClick={() => { this.onTabChange(null, 0) }} className={this.props.classes.Refreshcss}><Refresh /></Button>
        </div>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <DataTable
              header={
                <TableRow className={this.props.classes.MuiTableHead} >
                  <TableCell className={this.props.classes.HeadTableCell} width="10%">{t('deviceEUI')}</TableCell>
                  <TableCell className={this.props.classes.HeadTableCell} width="7%" >{t('devAddr')}</TableCell>
                  <TableCell className={this.props.classes.HeadTableCell} width="15%" >Payload</TableCell>
                  <TableCell className={this.props.classes.HeadTableCell} width="4%">{t('TransmissionDirection')}</TableCell>
                  <TableCell className={this.props.classes.HeadTableCell} width="7%">{t('msgType')}</TableCell>
                  <TableCell className={this.props.classes.HeadTableCell} width="110px">{t('freq')}</TableCell>
                  <TableCell className={this.props.classes.HeadTableCell} width="110px">{t('SNR')}</TableCell>
                  <TableCell className={this.props.classes.HeadTableCell} width="110px">{t('RSSI')}</TableCell>
                  <TableCell className={this.props.classes.HeadTableCell} width="10%">{t('createdTime')}</TableCell>
                  <TableCell className={this.props.classes.HeadTableCell} width="8%">Json</TableCell>
                </TableRow>
              }
              getPage={this.getPage}
              getRow={this.getRow}
            />
          </Grid>
        </Grid>
        </div>}
        {this.state.tab === 1 && <div>
     
     <div className={classes.container} noValidate align="right"  >
       <FormControl className={this.props.classes.formControl}>
         <InputLabel shrink id="demo-simple-select-placeholder-label-label">
         
         {t('ConnectionStatus')}
         </InputLabel>
         <Select
           labelId="demo-simple-select-label"
           id="demo-simple-select"
           value={this.state.state}
           onChange={this.handleChangeState}
         >
           <MenuItem value="2">
             <em>{t('All')}</em>
           </MenuItem>
           <MenuItem value={"0"}> {t('DisplayDownLineStatus')}</MenuItem> 
           <MenuItem value={"1"}>{t('DisplayUpLineStatus')} </MenuItem>
          
         </Select>
       </FormControl>
       <FormControl className={this.props.classes.formControl}>
         <InputLabel shrink id="demo-simple-select-placeholder-label-label">
         {t('ConnectionType')}
         </InputLabel>
         <Select
           labelId="demo-simple-select-label"
           id="demo-simple-select"
           value={this.state.type}
           onChange={this.handleChangeTypes}
         >
           <MenuItem value="0">
           <em>{t('All')}</em>
           </MenuItem>
           <MenuItem value={"1"}>MQTT</MenuItem>
           <MenuItem value={"2"}>PFUDP</MenuItem>
         </Select>
       </FormControl>


       <TextField
         id="datetime-local"
         label={t('StartingTime')}
         type="datetime-local"
         defaultValue={this.state.starttime}
         onChange={this.Starttime}
         className={this.props.classes.textField}
         InputLabelProps={{
           shrink: true,
         }}
       />
       <TextField
         id="datetime-local"
         label={t('EndTime')}
         type="datetime-local"
         defaultValue={this.state.endtime || ""}
         onChange={this.Endtime}
         className={this.props.classes.textField}
         InputLabelProps={{
           shrink: true,
         }}
       />
       <Button onClick={() => { this.onTabChange(null, 1) }} className={this.props.classes.Refreshcss}><Refresh /></Button>
     </div>
     <Grid container spacing={4}>
       <Grid item xs={12}>
         <DataTable
           header={
             <TableRow className={this.props.classes.MuiTableHead} >
               <TableCell className={this.props.classes.HeadTableCell} width="33%">{t('ConnectionStatus')}</TableCell>
               <TableCell className={this.props.classes.HeadTableCell} width="33%">{t('ConnectionType')}</TableCell>
               <TableCell className={this.props.classes.HeadTableCell} width="33%">{t('createdTime')}</TableCell>
               
             </TableRow>
           }
           getPage={this.getPage}
           getRow={this.getRow1}
         />
       </Grid>
     </Grid>
     </div>}
     {this.state.tab === 2&& <div>
     
     <div className={classes.container} noValidate align="right"  >
     <FormControl className={this.props.classes.formControl} >
              <InputLabel className={this.props.classes.SelectRadioshrink} htmlFor="age-simple">{t('errType')}</InputLabel>
              <Select
                labelId="errType"
                id="errType"
                value={this.state.errType}
                onChange={this.handleChangeErrType}
              
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {/* <MenuItem value='0'>IGNORED</MenuItem>
                <MenuItem value='1'>OK</MenuItem> */}
                <MenuItem value='2'>TOO_LATE</MenuItem>
                <MenuItem value='3'>TOO_EARLY</MenuItem>
                <MenuItem value='4'>COLLISION_PACKET</MenuItem>
                <MenuItem value='5'>COLLISION_BEACON</MenuItem>
                <MenuItem value='6'>TX_FREQ</MenuItem>
                <MenuItem value='7'>TX_POWER</MenuItem>
                <MenuItem value='8'>GPS_UNLOCKED</MenuItem>
                <MenuItem value='9'>QUEUE_FULL</MenuItem>
                <MenuItem value='10'>INTERNAL_ERROR</MenuItem>
              </Select>
            </FormControl>
       <Button onClick={() => { this.onTabChange(null, 2) }} className={this.props.classes.Refreshcss}><Refresh /></Button>
     </div>
     <Grid container spacing={4}>
            <Grid item xs={12}>
              <DataTable
                header={
                  <TableRow className={this.props.classes.MuiTableHead}>
                    <TableCell className={this.props.classes.HeadTableCell} width="15%">{t('GatewayId')}</TableCell>
                    <TableCell className={this.props.classes.HeadTableCell}>DevEUI</TableCell>
                    <TableCell className={this.props.classes.HeadTableCell} width="15%">{t('errType')}</TableCell>
                    <TableCell className={this.props.classes.HeadTableCell} width="10%">{t('createdTime')}</TableCell>
                    <TableCell className={this.props.classes.HeadTableCell} width="10%">Json</TableCell>      
                  </TableRow>
                }
                getPage={this.getPage}
                getRow={this.getRow2}
              />
            </Grid>
          </Grid>
     </div>}


     {this.state.tab === 3&& <div>
          <div className={classes.container} noValidate align="right"  >
          
          
         


          
        <ButtonGroup  className={this.props.classes.task} >
        <CustomTextField
              id="datetime-local"
              label={t('StartingTime')}
              type="datetime-local"
              defaultValue={this.state.starttime}
              onChange={this.Starttime}
              className={this.props.classes.textField1}
              InputLabelProps={{
                shrink: true,
              }}
            />



            <CustomTextField
              id="datetime-local"
              label={t('EndTime')}
              type="datetime-local"
              defaultValue={this.state.endtime || ""}
              onChange={this.Endtime}
              className={this.props.classes.textField1}
              InputLabelProps={{
                shrink: true,
              }}
              />

          
          <Button variant="outlined" onClick={() => { this.Createsynask() }} >{t('CreateTask')}</Button>
          <Button variant="outlined"onClick={() => { this.handleClickOpen() }} >{t('CheckTask')}</Button>
          <Button onClick={() => { this.onTabChange(null, 3) }} > <Refresh /></Button>
        </ButtonGroup>
          
          </div>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <DataTable
                header={
                  <TableRow className={this.props.classes.MuiTableHead}>
                    <TableCell className={this.props.classes.HeadTableCell}>{t('DeviceEui')}</TableCell>
                    <TableCell className={this.props.classes.HeadTableCell} width="15%">{t('GatewayId')}</TableCell>
                    <TableCell className={this.props.classes.HeadTableCell} width="10%">{t('createdTime')}</TableCell>
                    <TableCell className={this.props.classes.HeadTableCell} width="10%">Json</TableCell>
                  </TableRow>
                }
                getPage={this.getPage}
                getRow={this.getRow3}
              />
            </Grid>
          </Grid>
        </div>}


        <div>
         <CustomDialog
            fullWidth={this.state.fullWidth}
            maxWidth={this.state.maxWidth}
            open={this.state.open}
            onClose={this.handleClose}
          >
             <Grid container spacing={4}>
                <Grid item xs={12}>
                  <DataTable
                    header={
                      <TableRow className={this.props.classes.MuiTableHead}>
                        <TableCell className={this.props.classes.HeadTableCell}>{t('GatewayEui')}</TableCell>
                        <TableCell className={this.props.classes.HeadTableCell}>{t('TaskType')}</TableCell>
                        <TableCell className={this.props.classes.HeadTableCell}>{t('TaskResult')}</TableCell>
                        <TableCell className={this.props.classes.HeadTableCell}>{t('TaskSeconds')}</TableCell>
                        <TableCell className={this.props.classes.HeadTableCell} width="5%">{t('download')}</TableCell>
                        <TableCell className={this.props.classes.HeadTableCell} width="10%">{t('createdTime')}</TableCell>
                        
                      </TableRow>
                    }

            

    
                    getPage={this.getPage}
                    getRow={this.getRow4}
                  />
                </Grid>
              </Grid>
          </CustomDialog>
     

          </div>


      </Form>
    );
  }
}
export default withStyles(styles)(withTranslation()(GatewayHistory));