import React from "react";

import { withStyles } from "@material-ui/core/styles";
import TextField from '@material-ui/core/TextField';
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Typography from '@material-ui/core/Typography';

import {Controlled as CodeMirror} from "react-codemirror2";
import "codemirror/mode/javascript/javascript";
import { withTranslation } from "react-i18next";

import FormComponent from "../../classes/FormComponent";
import Form from "../../components/Form";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import AutocompleteSelect from "../../components/AutocompleteSelect";
import NetworkServerStore from "../../stores/NetworkServerStore";
import FormControlOrig from "@material-ui/core/FormControl";
import InternalStore from "../../stores/InternalStore";


const styles = {
  codeMirror: {
    zIndex: 1,
  },
  formLabel: {
    fontSize: 12,
  },
};


class ExtraChannelsForm extends FormComponent {
  constructor() {
    super();
    this.state = {
      bands:[],

    };
    this.onCodeChange = this.onCodeChange.bind(this);
    this.getNetworkServerOption = this.getNetworkServerOption.bind(this);
    this.getNetworkServerOptions = this.getNetworkServerOptions.bind(this);
    
  }
  onCodeChange(field, editor, data, newCode) {
    let object = this.state.object;
    object[field] = newCode;
    this.setState({
      object: object,
    });
  }
  getNetworkServerOption(id, callbackFunc) {
    NetworkServerStore.get(id, resp => {
      callbackFunc({label: resp.name, value: resp.id});
    });
  }

  getNetworkServerOptions(search, callbackFunc) {
    NetworkServerStore.list(0, 999, 0, resp => {
      const options = resp.result.map((ns, i) => {return {label: ns.name, value: ns.id}});
      callbackFunc(options);
    });
  }

  frequencyBandlist = (search, callbackFunc) => {
    InternalStore.getBandNameList(resp => {
      console.log(resp.result)
      let band =[]
      for (var i=0;i<resp.result.length;i++){
        var M2 ={}
        M2["label"]=resp.result[i].bandName
        M2["value"]=resp.result[i].bandName
        band[i]=M2
      } 
      this.setState({
        bands:band,
      });
      callbackFunc(this.state.bands);
    });
    
  }

  render() {
    if (this.state.object === undefined) {
      return(<div></div>);
    }
    const { t } = this.props

    return(
      <Form
        submitLabel={this.props.submitLabel}
        onSubmit={this.onSubmit}
      >
        <FormControl fullWidth margin="normal" >
            <FormLabel className={this.props.classes.formLabel} required>{t('FrequencyBand')}</FormLabel>
            <AutocompleteSelect
              id="bandName"
              label={t('selectFrequencyBand')}
              value={this.state.object.bandName || ""}
              onChange={this.onChange}
              getOptions={this.frequencyBandlist}
            />
            <FormHelperText>
              {t('selectFrequencyBandHelpText')}
            </FormHelperText>
          </FormControl>

   

          {/* <FormControl fullWidth margin="normal">
            <FormGroup>
              <FormControlLabel
                label={t('disabled')}
                control={
                  <Checkbox
                    id="isDisabled"
                    checked={!!this.state.object.isDisabled}
                    onChange={this.onChange}
                    color="primary"
                  />
                }
              />
            </FormGroup>
          </FormControl> */}
  
          {!this.props.update && <FormControlOrig margin="normal" fullWidth>
            <FormLabel className={this.props.classes.formLabel} required>{t('networkServer')}</FormLabel>
            <AutocompleteSelect
              id="networkServerID"
              label={t('selectNetworkServer')}
              value={this.state.object.networkServerID || ""}
              onChange={this.onChange}
              getOption={this.getNetworkServerOption}
              getOptions={this.getNetworkServerOptions}
            />
            <FormHelperText>
              {t('selectServiceProfileHelpText4')}
            </FormHelperText>
          </FormControlOrig>}
         
     
      </Form>
    );
  }
}
export default withStyles(styles)(withTranslation()(ExtraChannelsForm));
