import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Plus from "mdi-material-ui/Plus";
import { withTranslation } from "react-i18next";
import TitleBar from "../../components/TitleBar";
import TitleBarTitle from "../../components/TitleBarTitle";
import TableCellLink from "../../components/TableCellLink";
import TitleBarButton from "../../components/TitleBarButton";
import DataTable from "../../components/DataTable";
import InternalStore from "../../stores/InternalStore";
import { Link } from "react-router-dom";
import Button from '@material-ui/core/Button';
import theme from "../../theme";
import Refresh from "mdi-material-ui/Refresh";
import LoadingDialog from "../../components/LoadingDialog";

const styles = {
  online:{
    color:"green"
  },
  offline:{
 
    color:"red",
  },
  buttons: {
    textAlign: "right",
  },
  button: {
    marginLeft: 2 * theme.spacing(1),
  },
  buttonloading: {
    marginLeft: 2 * theme.spacing(1),
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  RadioGroupCss: {
    display: 'block',
  },
  GroupCss: {
    textAlign: 'right',
    border:'dotted'
  },

  DialogContentTextCss
  : {
    textAlign: 'center',
  },
  input: {
    display: 'none',
    textAlign: 'center',
  },
  CloudUploadCss: {
    textAlign: 'center',
    fontSize:'-webkit-xxx-large'
  },


  
};

class ListExtraChannelss extends Component {
  constructor() {
    super();

    this.state = {
      loading:false,
      FadeDialog:false,
      spDialog:false,
    };
    this.renovate = this.renovate.bind(this);
    this.getPage = this.getPage.bind(this);
    this.getRow = this.getRow.bind(this);
  }

  getPage(limit, offset, callbackFunc) {
    InternalStore.GetExtraChannelslist({
      limit: limit,
      offset: offset,
    }, callbackFunc);
  }

  getRow(obj) {
    return(
      <TableRow
        id={obj.extraChannelsID}
        hover
      >
        <TableCellLink to={`/extra-channels/${obj.id}`}>{obj.bandName}</TableCellLink>
        <TableCellLink to={`/network-servers/${obj.networkServerID}`}>{obj.networkServerName}</TableCellLink>
        {/* <TableCell >{ obj.isDisabled ? 'true' :  'false'} </TableCell> */}
      </TableRow>
    );
  }
  renovate() {
    InternalStore.RenovateExtraChannel( resp => {

    });
  }


  render() {
    const { t } = this.props
    return(
      <Grid container spacing={4}>
        <Grid item xs={12} className={this.props.classes.buttons}>
                <Button variant="outlined" onClick={() => { this.renovate() }}  className={this.props.classes.button}><LoadingDialog/>{t('renovate')}</Button>
                <Button variant="outlined" className={this.props.classes.button} component={Link} to={`/extra-channels/create`}>
                  <Plus className={this.props.classes.icon} />
                  {t('create')}
                </Button>
              </Grid>
        <Grid item xs={12}>
          
          <DataTable
            header={
              <TableRow>
                <TableCell>{t('FrequencyBand')}</TableCell>
                <TableCell>{t('networkServer')}</TableCell>
                {/* <TableCell>{t('disabled')}</TableCell> */}
              </TableRow>
            }
            getPage={this.getPage}
            getRow={this.getRow}
          />
        </Grid>
      </Grid>
      
    );
    
  }
}

export default withStyles(styles)(withTranslation()(ListExtraChannelss));