import React from "react";

import TextField from '@material-ui/core/TextField';
import { withRouter } from 'react-router-dom';
import { withTranslation } from "react-i18next";

import FormComponent from "../../classes/FormComponent";
import Form from "../../components/Form";
import InternalStore from "../../stores/InternalStore";


class AuthorizationForm extends FormComponent {
  constructor() {
    super();
    this.state = {
      token: null,
      id: null,
    };
    this.onSubmit = this.onSubmit.bind(this);
  }
  onSubmit = () => {
    let authorization = this.state.object;
    InternalStore.createAuthorization(authorization, resp => {
      this.props.history.push("/authorization");
    });
  }

  render() {

    if (this.state.object === undefined) {
      return null;
    }
    const { t } =this.props



    return(
      <Form
        submitLabel={this.props.submitLabel}
        onSubmit={this.onSubmit}
      >
        <TextField
          id="license"
          label="license"
          helperText={t('AuthorizationLicenseString')}
          margin="normal"
          value={this.state.object.license || ""}
          onChange={this.onChange}
          required
          fullWidth
        />
      </Form>
    );
  }
}

export default withRouter(withTranslation()(AuthorizationForm));