import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";
import Paper from '@material-ui/core/Paper';
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Grid from '@material-ui/core/Grid';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

import moment from "moment";
import { Map, Marker } from 'react-leaflet';
import { Line, Bar } from "react-chartjs-2";
import { withTranslation } from "react-i18next";

import MapTileLayer from "../../components/MapTileLayer";
import GatewayStore from "../../stores/GatewayStore";
import Heatmap from "../../components/Heatmap";
// import Select from '@material-ui/core/Select';
// import MenuItem from '@material-ui/core/MenuItem';
// import FormControl from '@material-ui/core/FormControl';


const styles = {
  chart: {
    height: 300,
  },
  online:{
    color:"green"
  },
  offline:{
 
    color:"red",
  },
  mapcss:{
    height:"100%",
  },
  mapcss2:{
    height:"710px",
  },
  formControl: {
    minWidth: "100px",
    marginRight: "10px",
   // marginTop: "3px",
  },

  display:{
    display : 'none'
  },
  hide:{
  display : 'block'
}
  

};

/*
class DetailsCard extends Component {
  render() {
    const { t } = this.props
    return(
      <Card>
        <CardHeader title={t('gatewayDetails')} />
        <CardContent>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>{t('gatewayId')}</TableCell>
                <TableCell>{this.props.gateway.id}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t('altitude')}</TableCell>
                <TableCell>{this.props.gateway.location.altitude} {t('meters')}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t('GPSCoordinates')}</TableCell>
                <TableCell>{this.props.gateway.location.latitude}, {this.props.gateway.location.longitude}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t('lastSeenAt')}</TableCell>
                <TableCell>{this.props.lastSeenAt}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    );
  }
}
DetailsCard = withTranslation()(DetailsCard);
*/

//网关信息卡片
class InfosCard extends Component {
  constructor() {
    super();
    this.state = {
      command:"",
    };
    
    this.getData = this.getData.bind(this);
    //this.SetCommand = this.SetCommand.bind(this);
  }
  componentDidMount(){
    this.getData()
  }
  getData(){
      //获取网关信息
   
      GatewayStore.getInfo(this.props.gateway.id, resp => {
    
        this.setState({
          data: resp,
          command:resp.command
        })
      })
  }
  // SetCommand(e) {

  //   if (e.target.value!=""){
  //     this.setState({
  //       command:e.target.value
  //     })
  //   this.state.data.command=e.target.value
  //   GatewayStore.gatewayAddCommand(this.props.gateway.id,e.target.value, resp => {
  //   });
  // }
    
  //}
  render() {
    
    const { t } = this.props
    let createTime = '---'
    if(this.state.data !== undefined){
      createTime = moment(Number(this.state.data.createTime)).format('lll');
    }
    return(
      <Card>
        <CardHeader title={t('gatewayInfo')} />
        <CardContent>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>{t('gatewayId')}</TableCell>
                <TableCell>{this.props.gateway.id}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t('gatewayName')}</TableCell>
                <TableCell>{this.props.gateway.name}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t('gatewayType')}</TableCell>
                <TableCell>{this.state.data === undefined ? '' : (this.state.data.gwType ? this.state.data.gwType.toUpperCase() : '---')} </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t('frequencyRegion')}</TableCell>
                <TableCell>{this.state.data === undefined ? '' : (this.state.data.region ? this.state.data.region.toUpperCase() : '---')}</TableCell>
              </TableRow>
              {/* <TableRow>
                <TableCell>{t('subnetType')}</TableCell>
                <TableCell>{this.state.data === undefined ? '' : (this.state.data.subnet ? this.state.data.subnet.toUpperCase() : '---')}</TableCell>
              </TableRow> */}
              <TableRow>
                <TableCell>ClassB</TableCell>

                <TableCell>{
                this.state.data === undefined ? '' 
                : this.state.data.enableClassB === '1' ? t('enabled2')
                : this.state.data.enableClassB === '2' ? t('disabled2')
                : '---'
                } 
                
                 </TableCell>
              </TableRow>
             
              {/* <TableRow>
                <TableCell>Pktfwd</TableCell>
                <TableCell className={ this.state.data === undefined ? '' : this.state.data.enablePFW? this.props.classes.online : this.props.classes.offline}>{this.state.data === undefined ? '' : (this.state.data.enablePFW ? t('enabled2') : t('disabled2'))}</TableCell>


              </TableRow> */}
              <TableRow  >
                <TableCell>{t('onlineStatus')}</TableCell>
                 <TableCell className={ this.state.data === undefined ? '' : this.state.data.online? this.props.classes.online : this.props.classes.offline}>{this.state.data === undefined ? '' : (this.state.data.online ? t('online') : t('offline'))} </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>MQTT</TableCell>
                {/* <TableCell>{this.state.data === undefined ? '' : (this.state.data.mqtt ? t('enabled2') : t('disabled2'))}</TableCell> */}
                <TableCell >{this.state.data === undefined ? '' : (this.state.data.mqtt ? t('enabled2') : t('disabled2'))}</TableCell>

              
              </TableRow>
              <TableRow>
                <TableCell>{t('gatewayDownlinkTXPower')}</TableCell>
                <TableCell>{this.state.data === undefined ? '' : this.state.data.txPower}</TableCell>
              </TableRow>
              {/* <TableRow>
                <TableCell>{t('TXPower')}</TableCell>
                <TableCell>{this.state.data === undefined ? '' : (this.state.data.transPower ? t('default') : t('custom'))}</TableCell>
              </TableRow> */}
              {/* <TableRow>
                <TableCell>{t('HTTPPushInterface')}</TableCell>
                <TableCell>{this.state.data === undefined ? '' : ('' === this.state.data.pushUrl ? '---' : this.state.data.pushUrl)}</TableCell>
              </TableRow> */}
              <TableRow>
                <TableCell>{t('altitude')}</TableCell>
                <TableCell>{this.props.gateway.location.altitude} {t('meters')}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t('latitude')}</TableCell>
                <TableCell>{this.props.gateway.location.latitude}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t('longitude')}</TableCell>
                <TableCell>{this.props.gateway.location.longitude}</TableCell>
              </TableRow>
              
              <TableRow>
                <TableCell>{t('GPSTimeSyncStatus')}</TableCell>
                {/* <TableCell>{this.state.data === undefined ? '' : (this.state.data.gpsTimeSync ? t('synced') : t('unsynced'))}</TableCell> */}
                <TableCell className={ this.state.data === undefined ? '' : this.state.data.gpsTimeSync ? this.props.classes.online : this.props.classes.offline}>{this.state.data === undefined ? '' : (this.state.data.gpsTimeSync ? t('synced') : t('unsynced'))}</TableCell>

              </TableRow>
              {/* <TableRow>
                <TableCell>{t('PendingCommand')}</TableCell>
                <TableCell>{this.state.data === undefined ? '' : this.state.data.command}</TableCell>
              </TableRow> */}

              {/* <TableRow>
                <TableCell>{t('PendingCommand')}</TableCell>
                <TableCell>
               
                <FormControl className={this.props.classes.formControl}>
                  <Select
                    value={this.state.command}
                    onChange={this.SetCommand}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                  >
                    <MenuItem value={"nothing"}>nothing</MenuItem>
                    <MenuItem value={"restart"}>restart</MenuItem>
                    <MenuItem value={"remote"}>remote</MenuItem>
                  </Select>
                </FormControl>
                </TableCell>
              </TableRow> */}


              <TableRow>
                <TableCell>{t('port')}</TableCell>
                <TableCell>{this.state.data === undefined ? '' : this.state.data.port}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t('lastSeenAt')}</TableCell>
                <TableCell>{this.state.data === undefined ? '' : ('' === this.state.data.statTime ? '---' : this.state.data.statTime)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t('createTime')}</TableCell>
                <TableCell>{this.state.data === undefined ? '' : ('' === this.state.data.createTime ? '---' : this.state.data.createTime)}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    );
  }
}
InfosCard = withStyles(styles)(withTranslation()(InfosCard));

//最后一帧上行数据卡片
class LastFrameDataCard extends Component {
  constructor() {
    super();
    this.state = {};
    this.getData = this.getData.bind(this);
  }
  componentDidMount(){
    this.getData()
  }
  getData(){


    //获取网关最新一帧上行数据
    GatewayStore.getFrame(this.props.gateway.id, resp => {
      this.setState({
        data: resp
      })
    })
  }
  render() {
    const { t } = this.props
    let createTime = '---'
    let msgType = '---'
    const lorawanMsgType = [
      "Join Request",
      "Join Accept",
      "Unconfirmed Data Up",
      "Unconfirmed Data Down",
      "Confirmed Data Up",
      "Confirmed Data Down",
      "Rejoin Request",
      "Proprietary",
    ]
    if(this.state.data !== undefined){
      createTime = moment(Number(this.state.data.createTime)).format('lll');
      if(this.state.data.msgType >= 0 && this.state.data.msgType < lorawanMsgType.length){
        msgType = lorawanMsgType[this.state.data.msgType]
      }
    }
    return(
      <Card>
        <CardHeader title={t('lastFrameData')} />
        <CardContent>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>DevAddr</TableCell>
                <TableCell>{this.state.data === undefined ? '' : ('' === this.state.data.devAddr ? '---' : this.state.data.devAddr)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t('msgType')}</TableCell>
                <TableCell>{msgType}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t('freq2')}</TableCell>
                <TableCell>{this.state.data === undefined ? '' : (this.state.data.freq ? this.state.data.freq : '---')}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t('RSSI2')}</TableCell>
                <TableCell>{this.state.data === undefined ? '' : (this.state.data.rssi ? this.state.data.rssi : '---')}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t('SNR2')}</TableCell>
                <TableCell>{this.state.data === undefined ? '' : (this.state.data.snr ? this.state.data.snr : '---')}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t('SF')}</TableCell>
                <TableCell>{this.state.data === undefined ? '' : (this.state.data.sf ? this.state.data.sf : '---')}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t('BW')}</TableCell>
                <TableCell>{this.state.data === undefined ? '' : (this.state.data.bw ? this.state.data.bw : '---')}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t('LoRaModulate')}</TableCell>
                <TableCell>{this.state.data === undefined ? '' : (this.state.data.lora ? 'LoRa' : 'FSK')}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t('GPSTime')}</TableCell>
                <TableCell>{this.state.data === undefined ? '' : (this.state.data.gpsTime ? this.state.data.gpsTime : '---')}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t('lastSeen2')}</TableCell>
                <TableCell>{this.state.data === undefined ? '' : (this.state.data.createTime ? this.state.data.createTime : '---')}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    );
  }
}

LastFrameDataCard = withStyles(styles)(withTranslation()(LastFrameDataCard));

//网关网络状态卡片
class NetworkStatusCard extends Component {
  constructor() {
    super();
    this.state = {
    };
    this.getData = this.getData.bind(this);

  }
  componentDidMount(){
    this.getData()
  }
  getData(){
      //获取网关网络状态
      GatewayStore.getNetwork(this.props.gateway.id, resp => {

        this.setState({
          data: resp
        })
      })
  }

  render() {
    const { t } = this.props
    let createTime = '---'
    if(this.state.data !== undefined){
      createTime = moment(Number(this.state.data.createTime)).format('lll');
    }

    return(
      <Card className={ this.state.data === undefined ? '' : this.state.data.createTime=='' ? this.props.classes.display : this.props.classes.hide}>
         
        <CardHeader title={t('gatewayNetworkStatus')}   />
      
        <CardContent>
          <Table>
            <TableBody> 
              <TableRow>
                <TableCell>{t('networkType')}</TableCell>
                <TableCell>{this.state.data === undefined ? '' : this.state.data.nwkType}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t('WANIP')}</TableCell>
                <TableCell>{this.state.data === undefined ? '' : this.state.data.wanIp === '' ? '--' : this.state.data.wanIp}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t('LANIP')}</TableCell>
                <TableCell>{this.state.data === undefined ? '' : this.state.data.lanIp === '' ? '---': this.state.data.lanIp}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t('LTESignalStrength')}</TableCell>
                <TableCell>{this.state.data === undefined ? '' : this.state.data.rssi === 0 ? '---' : this.state.data.rssi}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t('latency')}</TableCell>
                <TableCell>{this.state.data === undefined ? '' : this.state.data.nwkDelay === '' ? '---' : this.state.data.nwkDelay}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t('lastUpdated')}</TableCell>
                <TableCell>{this.state.data === undefined ? '' : this.state.data.createTime === '' ? '---' : this.state.data.createTime}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    );
  }
}
NetworkStatusCard = withStyles(styles)(withTranslation()(NetworkStatusCard));


// //频率计划切换状态卡片
// class FreqScheduleSwitchStateCard extends Component {
//   constructor() {
//     super();
//     this.state = {};
//     this.getData = this.getData.bind(this);
//     this.formatDate = this.formatDate.bind(this);
//   }
//   componentDidMount(){
//     this.getData()
//   }
//   getData(){
//     //获取网关频率计划切换状态信息
//     GatewayStore.GetChannelPlanSwitch(this.props.gateway.id, resp => {
//       this.setState({
//         data: resp
//       })
//     })
//   }
//   formatDate(timestamp){
//     if(!timestamp || timestamp <= 0){
//       return '---'
//     }
//     if( typeof seconds != 'number'){
//       timestamp = Number(timestamp)
//     }
//     return moment(Number(timestamp)).format('lll');
//   }
//   render() {
//     const { t } = this.props
//     return(
//       <Card>
//         <CardHeader title={t('frequencyScheduleSwitchingState')} />
//         <CardContent>
//           <Table>
//             <TableBody>
//               <TableRow>
//                 <TableCell>{t('currentFrequencyPlan')}</TableCell>
//                 <TableCell>{this.state.data === undefined ? '' : (this.state.data.currentChannelPlan ? this.state.data.currentChannelPlan.toUpperCase() : '---')}</TableCell>
//               </TableRow>
//               <TableRow>
//                 <TableCell>{t('targetFrequencyPlan')}</TableCell>
//                 <TableCell>{this.state.data === undefined ? '' : (this.state.data.nextChannelPlan ? this.state.data.nextChannelPlan.toUpperCase() : '---')}</TableCell>
//               </TableRow>
//               <TableRow>
//                 <TableCell>{t('dataDeliveryInterval')}</TableCell>
//                 <TableCell>{this.state.data === undefined ? '' : (this.state.data.interval ? this.state.data.interval : '---')}</TableCell>
//               </TableRow>
//               <TableRow>
//                 <TableCell>{t('switchTime')}</TableCell>
//                 <TableCell>{this.state.data === undefined ? '' : this.formatDate(this.state.data.switchTime)}</TableCell>
//               </TableRow>
//               <TableRow>
//                 <TableCell>{t('responseState')}</TableCell>
//                 <TableCell>{this.state.data === undefined ? '' : (0 === this.state.data.taskId ?  '---' : this.state.data.confirmed ? t('hasResponse') : t('notResponding'))}</TableCell>
//               </TableRow>
//               <TableRow>
//                 <TableCell>{t('dataIssuedCount')}</TableCell>
//                 <TableCell>{this.state.data === undefined ? '' : (this.state.data.triggerCount ? this.state.data.triggerCount : '---')}</TableCell>
//               </TableRow>
//               <TableRow>
//                 <TableCell>{t('nextDataReleaseTime')}</TableCell>
//                 <TableCell>{this.state.data === undefined ? '' : this.formatDate(this.state.data.nextTime)}</TableCell>
//               </TableRow>
//               <TableRow>
//                 <TableCell>{t('createTime')}</TableCell>
//                 <TableCell>{this.state.data === undefined ? '' : this.formatDate(this.state.data.createTime)}</TableCell>
//               </TableRow>
//             </TableBody>
//           </Table>
//         </CardContent>
//       </Card>
//     );
//   }
// }
// FreqScheduleSwitchStateCard = withTranslation()(FreqScheduleSwitchStateCard);

//网关系统状态卡片
class SystemStatusCard extends Component {
  constructor() {
    super();
    this.state = {};
    this.getData = this.getData.bind(this);
    this.formatTime = this.formatTime.bind(this);
  }
  componentDidMount(){
    this.getData()
  }
  getData(){

    //获取网关系统信息
    GatewayStore.getSystem(this.props.gateway.id, resp => {

      this.setState({
        data: resp
      })
    })
  }
  formatTime(seconds){
    if( typeof seconds != 'number'){
      seconds = Number(seconds)
    }
   let t = moment.duration(seconds, 'seconds')
   return `${t.days()}D ${t.hours()}H ${t.minutes()}M ${t.seconds()}S`
  }
  render() {
    const { t } = this.props
    let createTime = '---'
    if(this.state.data !== undefined){
      createTime = moment(Number(this.state.data.createTime)).format('lll');
    }
    return(
      <Card className={ this.state.data === undefined ? '' : this.state.data.createTime=='' ? this.props.classes.display : this.props.classes.hide}>
        <CardHeader title={t('gatewaySystemStatus')} />
        <CardContent>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>{t('subModel')}</TableCell>
                <TableCell>{this.state.data === undefined ? '' : this.state.data.model === '' ? '---' : this.state.data.model}</TableCell>
              </TableRow>
              {/* <TableRow>
                <TableCell>{t('firmwareDate')}</TableCell>
                <TableCell>{this.state.data === undefined ? '' : this.state.data.firmwareDate === '' ? '---' : this.state.data.firmwareDate}</TableCell>
              </TableRow> */}
              <TableRow>
                <TableCell>{t('firmwareVersion')}</TableCell>
                <TableCell>{this.state.data === undefined ? '' : this.state.data.firmwareVersion === '' ? '---' : this.state.data.firmwareVersion}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t('hardwareVersion')}</TableCell>
                <TableCell>{this.state.data === undefined ? '' : this.state.data.hardwareVersion === '' ? '---' : this.state.data.hardwareVersion}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t('kernelVersion')}</TableCell>
                <TableCell>{this.state.data === undefined ? '' : this.state.data.kernelVersion === '' ? '---' : this.state.data.kernelVersion}</TableCell>
              </TableRow>
              {/* <TableRow>
                <TableCell>{t('SDKVersion')}</TableCell>
                <TableCell>{this.state.data === undefined ? '' : this.state.data.sdkVersion === '' ? '---' : this.state.data.sdkVersion}</TableCell>
              </TableRow> */}
              <TableRow>
                <TableCell>{t('PKTFWDVersion')}</TableCell>
                <TableCell>{this.state.data === undefined ? '' : this.state.data.pktfwdVersion === '' ? '---' : this.state.data.pktfwdVersion}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t('PKTFWDStatus')}</TableCell>
                <TableCell className={this.state.data === undefined ? '' : this.state.data.pktfwdRunning ? this.props.classes.online : this.props.classes.offline}>{this.state.data === undefined ? '' : this.state.data.pktfwdRunning  ? t('online') : t('offline')}</TableCell>
                {/* <TableCell >{this.state.data === undefined ? '' : this.state.data.pktfwdRunning  ? t('online') : t('offline')}</TableCell> */}
  
              </TableRow>
              <TableRow>
                <TableCell>{t('gatewayRunningTime')}</TableCell>
                <TableCell>{this.state.data === undefined ? '' : this.state.data.uptime  ? this.state.data.uptime : '---'}</TableCell>
              </TableRow>
              {/* <TableRow>
                <TableCell>{t('SDKRunningTime')}</TableCell>
                <TableCell>{this.state.data === undefined ? '' : this.state.data.sdkUptime  ? this.formatTime(this.state.data.sdkUptime) : '---'}</TableCell>
              </TableRow> */}
              <TableRow>
                <TableCell>{t('CPUUsageRate')}</TableCell>
                <TableCell>{this.state.data === undefined ? '' : this.state.data.cpuUsage === '' ? '---' : this.state.data.cpuUsage+"%"}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t('memoryUsageRate')}</TableCell>
                <TableCell>{this.state.data === undefined ? '' : this.state.data.memUsage === '' ? '---' : this.state.data.memUsage+"%"}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t('rootUsageRate')}</TableCell>
                <TableCell>{this.state.data === undefined ? '' : this.state.data.rootDiskUsage === '' ? '---' : this.state.data.rootDiskUsage}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t('logUsageRate')}</TableCell>
                <TableCell>{this.state.data === undefined ? '' : this.state.data.logDiskUsage === '' ? '---' : this.state.data.logDiskUsage}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t('temperature')}</TableCell>
                <TableCell>{this.state.data === undefined ? '' : this.state.data.temperature === '' ? '---' : this.state.data.temperature }</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t('PowerType')}</TableCell>
                <TableCell>{
                this.state.data === undefined ? '' 
                : this.state.data.PowerType === '0' ? t('BatteryPowered')
                : this.state.data.PowerType === '1' ? t('ExternalPowerSupply')
                : this.state.data.PowerType === '2' ? t('BatteryPoweredAndExternalPowerSupply')
                : this.state.data.PowerType === '3' ? t('Unknown')
                : '---'
                
                } 
                {this.state.data === undefined ? '' : this.state.data.Voltage === '' ? '' : "("}
                {this.state.data === undefined ? '' : this.state.data.Voltage}
                {this.state.data === undefined ? '' : this.state.data.Voltage === '' ? '' : ")"}
                 </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t('BatteryStatus')}</TableCell>
                <TableCell>{this.state.data === undefined ? '' : this.state.data.Power === '' ? '---' : this.state.data.Power }</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t('lastUpdated')}</TableCell>
                <TableCell>{this.state.data === undefined ? '' : this.state.data.createTime === '' ? '---' : this.state.data.createTime }</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    );
  }
}

SystemStatusCard = withStyles(styles)(withTranslation()(SystemStatusCard));

class GatewayDetails extends Component {
  constructor() {
    super();
    this.state = {};
    this.loadStats = this.loadStats.bind(this);
  }

  componentDidMount() {
    this.loadStats();
  }

  // loadStats() {
  //   const end = moment().toISOString()
  //   const start = moment().subtract(30, "days").toISOString()

  //   GatewayStore.getStats(this.props.match.params.gatewayID, start, end, resp => {
  //     let statsDown = {
  //       labels: [],
  //       datasets: [
  //         {
  //           label: "rx received",
  //           borderColor: "rgba(33, 150, 243, 1)",
  //           backgroundColor: "rgba(0, 0, 0, 0)",
  //           lineTension: 0,
  //           pointBackgroundColor: "rgba(33, 150, 243, 1)",
  //           data: [],
  //         },
  //       ],
  //     }

  //     let statsUp = {
  //       labels: [],
  //       datasets: [
  //         {
  //           label: "tx emitted",
  //           borderColor: "rgba(33, 150, 243, 1)",
  //           backgroundColor: "rgba(0, 0, 0, 0)",
  //           lineTension: 0,
  //           pointBackgroundColor: "rgba(33, 150, 243, 1)",
  //           data: [],
  //         },
  //       ],
  //     }

  //     for (const row of resp.result) {
  //       statsUp.labels.push(moment(row.timestamp).format("Do"));
  //       statsDown.labels.push(moment(row.timestamp).format("Do"));
  //       statsUp.datasets[0].data.push(row.txPacketsEmitted);
  //       statsDown.datasets[0].data.push(row.rxPacketsReceivedOK);
  //     }

  //     this.setState({
  //       statsUp: statsUp,
  //       statsDown: statsDown,
  //     });
  //   });
  // }
  loadStats() {
    const end = moment().toISOString()
    const start = moment().subtract(7, "days").toISOString()
    console.log("===this.props.match.params.gatewayID=====",this.props.match.params.gatewayID)
    GatewayStore.getStats(this.props.match.params.gatewayID, start, end, resp => {
      console.log("===resp=====",resp)
      let statsDown = {
        labels: [],
        datasets: [
          {
            label: "rx received",
            borderColor: "rgba(33, 150, 243, 1)",
            backgroundColor: "rgba(0, 0, 0, 0)",
            lineTension: 0,
            pointBackgroundColor: "rgba(33, 150, 243, 1)",
            data: [],
          },
        ],
      };

      let statsUp = {
        labels: [],
        datasets: [
          {
            label: "tx emitted",
            borderColor: "rgba(33, 150, 243, 1)",
            backgroundColor: "rgba(0, 0, 0, 0)",
            lineTension: 0,
            pointBackgroundColor: "rgba(33, 150, 243, 1)",
            data: [],
          },
        ],
      };

      let statsDownError = {
        labels: [],
        datasets: [],
      };
      let statsDownErrorSets = {};

      let statsUpFreq = {
        labels: [],
        datasets: [],
      };
      let statsUpFreqSet = [];

      let statsDownFreq = {
        labels: [],
        datasets: [],
      };
      let statsDownFreqSet = [];


      let statsUpDr = {
        labels: [],
        datasets: [],
      };
      let statsUpDrSet = [];

      let statsDownDr = {
        labels: [],
        datasets: [],
      };
      let statsDownDrSet = [];


      
      

      for (const row of resp.result) {
        statsUp.labels.push(moment(row.timestamp).format("YYYY-MM-DD"));
        statsUp.datasets[0].data.push(row.txPacketsEmitted);

        statsDown.labels.push(moment(row.timestamp).format("YYYY-MM-DD"));
        statsDown.datasets[0].data.push(row.rxPacketsReceivedOK);

        statsDownError.labels.push(moment(row.timestamp).format("YYYY-MM-DD"));
        Object.entries(row.txPacketsPerStatus).forEach(([k, v]) => {
          if (statsDownErrorSets[k] === undefined) {
            statsDownErrorSets[k] = [];
          }

          // fill gaps with 0s
          for (let i = statsDownErrorSets[k].length; i < statsDownError.labels.length - 1; i++) {
            statsDownErrorSets[k].push(0);
          }

          statsDownErrorSets[k].push(v);
        });


    

  

  
        statsUpFreq.labels.push(moment(row.timestamp).format("YYYY-MM-DD"));
        Object.entries(row.rxPacketsPerFrequency).forEach(([k, v]) => {
          if (statsUpFreqSet[k] === undefined) {
            statsUpFreqSet[k] = [];
          }

          // fill gaps with 0s
          for (let i = statsUpFreqSet[k].length; i < statsUpFreq.labels.length - 1; i++) {
            statsUpFreqSet[k].push(0);
          }

          statsUpFreqSet[k].push(v);
        });

        statsDownFreq.labels.push(moment(row.timestamp).format("YYYY-MM-DD"));
        Object.entries(row.txPacketsPerFrequency).forEach(([k, v]) => {
          if (statsDownFreqSet[k] === undefined) {
            statsDownFreqSet[k] = [];
          }

          // fill gaps with 0s
          for (let i = statsDownFreqSet[k].length; i < statsDownFreq.labels.length - 1; i++) {
            statsDownFreqSet[k].push(0);
          }

          statsDownFreqSet[k].push(v);
        });


        statsUpDr.labels.push(moment(row.timestamp).format("YYYY-MM-DD"));
        Object.entries(row.rxPacketsPerDr).forEach(([k, v]) => {
          if (statsUpDrSet[k] === undefined) {
            statsUpDrSet[k] = [];
          }

          // fill gaps with 0s
          for (let i = statsUpDrSet[k].length; i < statsUpDr.labels.length - 1; i++) {
            statsUpDrSet[k].push(0);
          }

          statsUpDrSet[k].push(v);
        });

        statsDownDr.labels.push(moment(row.timestamp).format("YYYY-MM-DD"));
        Object.entries(row.txPacketsPerDr).forEach(([k, v]) => {
          if (statsDownDrSet[k] === undefined) {
            statsDownDrSet[k] = [];
          }

          // fill gaps with 0s
          for (let i = statsDownDrSet[k].length; i < statsDownDr.labels.length - 1; i++) {
            statsDownDrSet[k].push(0);
          }

          statsDownDrSet[k].push(v);
        });
      }

      let backgroundColors = ['#8bc34a', '#ff5722', '#ff9800', '#ffc107', '#ffeb3b', '#cddc39', '#4caf50', '#009688', '#00bcd4', '#03a9f4', '#2196f3', '#3f51b5', '#673ab7', '#9c27b0', '#e91e63','#2196f2','#2196f7','#2196F3','#8A3719','#607F31','#8BB1CC','#DD3549','#10D773','#599EFF','#FFC509','#97CA86','#712555','#5A837A','#789115','#1C5E22','#005650','#9DA065','#023E4A','#27C6D9','#32686D','#ff5722', '#ff9800', '#ffc107', '#ffeb3b', '#cddc39', '#4caf50', '#009688', '#00bcd4', '#03a9f4','#8bc34a', '#ff5722', '#ff9800', '#ffc107', '#ffeb3b', '#cddc39', '#4caf50', '#009688', '#00bcd4', '#03a9f4', '#2196f3', '#3f51b5', '#673ab7', '#9c27b0', '#e91e63'];

      Object.entries(statsDownErrorSets).forEach(([k, v]) => {
        statsDownError.datasets.push({
          label: k,
          data: v,
          backgroundColor: backgroundColors.shift(),
        });
      });
      Object.entries(statsUpFreqSet).forEach(([k, v]) => {
        statsUpFreq.datasets.push({
          label: k,
          data: v,
          backgroundColor: backgroundColors.shift(),
        });
        console.log("===backgroundColors.shift()==2221",backgroundColors.shift())
      });
      Object.entries(statsDownFreqSet).forEach(([k, v]) => {
        statsDownFreq.datasets.push({
          label: k,
          data: v,
          backgroundColor: backgroundColors.shift(),
        });
        console.log("===backgroundColors.shift()==111",backgroundColors.shift())
      });
      Object.entries(statsUpDrSet).forEach(([k, v]) => {
        statsUpDr.datasets.push({
          label: k,
          data: v,
          backgroundColor: backgroundColors.shift(),
        });
        console.log("===backgroundColors.shift()==",backgroundColors.shift())
      });
      Object.entries(statsDownDrSet).forEach(([k, v]) => {
        statsDownDr.datasets.push({
          label: k,
          data: v,
          backgroundColor: backgroundColors.shift(),
        });
      });
      console.log("=statsUp==",statsUp)
      console.log("=statsDown==",statsDown)
      console.log("=statsUpFreq==",statsUpFreq)
      console.log("=statsDownFreq==",statsDownFreq)
   
      console.log("=statsUpDr==",statsUpDr)
      console.log("=statsDownDr==",statsDownDr)
      console.log("=statsDownError==",statsDownError)

      this.setState({
        statsUp: statsUp,
        statsDown: statsDown,
        statsUpFreq: statsUpFreq,
        statsDownFreq: statsDownFreq,
        statsUpDr: statsUpDr,
        statsDownDr: statsDownDr,
        statsDownError: statsDownError,
      });
    });
  }

  render() {
    // if (this.props.gateway === undefined || this.state.statsDown === undefined || this.state.statsUp === undefined) {
    //   return(<div></div>);
    // }
    if (this.props.gateway === undefined || this.state.statsDown === undefined || this.state.statsUp === undefined || this.state.statsUpFreq === undefined) {
      return(<div></div>);
    }
    const { t } = this.props

    const style = {
      height: 400,
    };

    const statsOptions = {
      legend: {
        display: false,
      },
      maintainAspectRatio: false,
      scales: {
        yAxes: [{
          ticks: {
            beginAtZero: true,
          },
        }],
      },
    }
    const barOptions = {
      animation: false,
      plugins: {
        legend: {
          display: true,
        },
      },
      maintainAspectRatio: false,
      scales: {
        y: {
          beginAtZero: true,
        },
        x: {
          // type: "time",
        },
      },
    };

    let position = [];
    if (typeof(this.props.gateway.location.latitude) !== "undefined" && typeof(this.props.gateway.location.longitude !== "undefined")) {
      position = [this.props.gateway.location.latitude, this.props.gateway.location.longitude]; 
    } else {
      position = [0,0];
    }

    let lastSeenAt = "Never";
    if (this.props.lastSeenAt !== null) {
      lastSeenAt = moment(this.props.lastSeenAt).format("lll");
    }

    return(
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <InfosCard gateway={this.props.gateway} lastSeenAt={lastSeenAt} />
            </Grid>
            <Grid item xs={12}>
              <SystemStatusCard gateway={this.props.gateway} lastSeenAt={lastSeenAt} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item  xs={12} sm={6} >
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <NetworkStatusCard gateway={this.props.gateway} lastSeenAt={lastSeenAt} />
            </Grid>
            <Grid item xs={12}>
              <LastFrameDataCard gateway={this.props.gateway} lastSeenAt={lastSeenAt} />
            </Grid>
   
            <Grid item xs={12}>
              <Paper className={this.props.classes.mapcss} >
                <Map center={position} zoom={15}  animate={true} scrollWheelZoom={false} className={this.props.classes.mapcss2}>
                  <MapTileLayer  className={this.props.classes.mapcss2}/>
                  <Marker position={position}  className={this.props.classes.mapcss2}/>
                </Map>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
    {/* <Grid item xs={6}>
          <DetailsCard gateway={this.props.gateway} lastSeenAt={lastSeenAt} />
        </Grid>
        <Grid item xs={6}>
          <Paper>
            <Map center={position} zoom={15} style={style} animate={true} scrollWheelZoom={false}>
              <MapTileLayer />
              <Marker position={position} />
            </Map>
          </Paper>
        </Grid> */}
        <Grid item xs={12}>
          <Card>
            <CardHeader title={t('framesReceived')} />
            <CardContent className={this.props.classes.chart}>
              <Line height={75} options={statsOptions} data={this.state.statsDown} redraw />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardHeader title={t('framesTransmitted')} />
            <CardContent className={this.props.classes.chart}>
              <Line height={75} options={statsOptions} data={this.state.statsUp} redraw />
            </CardContent>
          </Card>
        </Grid>








  
        <Grid item xs={6}>
          <Card>
            <CardHeader title="Received / frequency" />
            <CardContent className={this.props.classes.chart}>
            <Bar data={this.state.statsUpFreq} options={barOptions} />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card>
            <CardHeader title="Transmitted / frequency" />
            <CardContent className={this.props.classes.chart}>
              <Bar data={this.state.statsDownFreq} options={barOptions} />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card>
            <CardHeader title="Received / DR" />
            <CardContent className={this.props.classes.chart}>
              <Bar data={this.state.statsUpDr} options={barOptions} />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card>
            <CardHeader title="Transmitted / DR" />
            <CardContent className={this.props.classes.chart}>
              <Bar data={this.state.statsDownDr} options={barOptions} />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card>
            <CardHeader title="Transmission / Ack status" />
            <CardContent className={this.props.classes.chart}>
              <Bar data={this.state.statsDownError} options={barOptions} />
            </CardContent>
          </Card>
        </Grid>
   

      </Grid>
    );
  }
}

export default withStyles(styles)(withTranslation()(GatewayDetails));
