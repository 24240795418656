import React, { Component } from "react";

import { withRouter } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from "@material-ui/core/CardContent";
import { withTranslation } from "react-i18next";

import GatewayExecuteCommandForm from "./GatewayExecuteCommandForm";





class GatewayExecuteCommand extends Component {
    // constructor() {
    //     super();
    //     this.onSubmit = this.onSubmit = this.onSubmit.bind(this);
    //   }
    
      render() {
        const { t } =this.props
        return(
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <GatewayExecuteCommandForm
                    object={this.props.gateway}
                    
                    onSubmit={this.onSubmit}
                    match={this.props.match}
                    submitLabel={t('ExecuteCommand')}
                  />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        );
      }
    
}
export default withRouter(withTranslation()(GatewayExecuteCommand));