import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { withTranslation } from "react-i18next";
import FormComponent from "../../classes/FormComponent";
import Form from "../../components/Form";
import Grid from "@material-ui/core/Grid";
import DataTable from "../../components/DataTable";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import DeviceQueueStore from "../../stores/DeviceQueueStore";
import DeviceStore from "../../stores/DeviceStore";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import MenuItem from '@material-ui/core/MenuItem';
import Refresh from "mdi-material-ui/Refresh";
import theme from "../../theme";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';

import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import JSONTree from "../../components/JSONTree";
import CodeGreaterThan  from "mdi-material-ui/CodeGreaterThan"; 

import TrendingUp  from "mdi-material-ui/TrendingUp";
import TrendingDown  from "mdi-material-ui/TrendingDown";
import Tooltip from '@material-ui/core/Tooltip';
import HistoryDialog from "../../components/HistoryDialog";
import InternalStore from "../../stores/InternalStore";
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { Link } from '@material-ui/core';

// 定义不同结果对应的文本和样式类

const CustomDialog = withStyles({
  paper: {
    minWidth: '400px',
  },
  list: {
    padding: 0,
  },
  listItem: {
    borderBottom: '1px solid #e0e0e0',
    padding: '10px 16px',
    '&:last-child': {
      borderBottom: 'none',
    },
  },
})(Dialog);
const CustomTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      borderRadius: 0,
    },
  },
})(TextField);
const styles = {
  success: {
    color: theme.palette.success.main,
  },
  failure: {
    color: theme.palette.error.main,
  },


  buttons: {
    textAlign: "center",
  },
  formLabel: {
    fontSize: 12,
  },
  task: {
    // height: "65px",
    // marginRight:"20px"
    marginTop: "12px"
  },
  Refreshcss: {
    height: "65px",
  },
  MuiTableHead: {

    backgroundColor: "#2D8CF0",
  },
  HeadTableCell: {
    color: "#f5f5f5",
    textAlign: "center",
  },
  valueTableCell: {
    textAlign: "center",
    color: "black",
    
  },
  DatavalueTableCell: {
    textAlign: "center",
    color: "black",
    whiteSpace :"break-spaces",
  },
  SelectRadio: {
    width: "500px",
  },
  formControl: {
    minWidth: "100px",
    marginRight: "10px",
    marginTop: "3px",
  },
  textField: {
    marginRight: "10px",
    width: "200px",
    marginTop: "3px",
  },
  textField1: {
    // marginRight: "10px",
    width: "200px",
    
    // marginTop: "3px",
    
  },
  progress: {
    marginTop: 4 * theme.spacing(1),
  },
  DialogCss: {
    width: "50%",
  },
  button: {
    marginLeft: 2 * theme.spacing(1),
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  center: {
    textAlign: "center",
  },
  progress: {
    marginTop: 4 * theme.spacing(1),
  },
  headerColumn: {
    marginRight: 6 * theme.spacing(1),
  },
  headerColumnFixedSmall: {
    width: 145,
  },
  headerColumnFixedWide: {
    width: 175,
  },
  treeStyle: {
    paddingTop: 0,
    paddingBottom: 0,
    fontSize: 12,
    lineHeight: 1.5,
  },
  
  None: {
    color:'#FBEBFB',
    padding:"8px",
   },
   
  
   UnconfirmedDataUp: {
    color:'#FBEBFB',
   padding:"8px",
   backgroundColor:'#FF004D',
  },
   UnconfirmedDataDown: {
    color:'#FBEBFB',
    padding:"8px",
    backgroundColor:'#FF3300',
   },
   JoinAccept: {
    color:'#FBEBFB',
    padding:"8px",
    backgroundColor:'#19BE6B',
   },
   JoinRequest: {
    color:'#FBEBFB',
    padding:"8px",
    backgroundColor:'#034713',
   },
   ConfirmedDataUp: {
    color:'#FBEBFB',
    padding:"8px",
    backgroundColor:'#FFB300',
   },
   ConfirmedDataDown:{
    color:'#FBEBFB',
    padding:"8px",
    backgroundColor:'#FBAD4C',
   }


   
};



const useStyles = withStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

class DeviceForm extends FormComponent {
  constructor() {
    super();
    this.state = {
      tab: 0,
      variables: [],
      tags: [],
      starttime: "",
      endtime: "",
      pending: "",
      types: "",
      spDialog: false,
      applicationjson: "",
      limit: 10,
      offset: 0,
      msgType: "",
      fullWidth: true,
      maxWidth: "md",
      mapCss:{},
      errType:"",
      open: false,

    };
    this.getPage = this.getPage.bind(this);
    this.getRow1 = this.getRow1.bind(this);
    this.getRow0 = this.getRow0.bind(this);
    this.getRow2 = this.getRow2.bind(this);
    this.getRow3 = this.getRow3.bind(this);
    this.getRow4 = this.getRow4.bind(this);
    this.getRow5 = this.getRow5.bind(this);

    this.handleChange = this.handleChange.bind(this);
    this.handleChangePending = this.handleChangePending.bind(this);
    this.handleChangemsg = this.handleChangemsg.bind(this);
    this.Starttime = this.Starttime.bind(this);
    this.Endtime = this.Endtime.bind(this);
    this.handleChangeErrType = this.handleChangeErrType.bind(this);
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);

    
    
  }
  setKVArrays = (props) => {
    let variables = [];
    let tags = [];
    this.setState({
      variables: variables,
      tags: tags,
    });
  }



  getPage(limit, offset, callbackFunc) {
    
    
    if (this.state.tab == 0) {
      DeviceStore.HistoryList(this.props.match.params.devEUI, this.state.msgType, "up", "application", "", this.state.starttime, this.state.endtime, limit, offset, callbackFunc);
    } else if (this.state.tab == 1) {
      DeviceStore.HistoryList(this.props.match.params.devEUI, this.state.msgType, this.state.types, "protocol", "", this.state.starttime, this.state.endtime, limit, offset, callbackFunc);
    } else if (this.state.tab == 2) {
      DeviceQueueStore.ListDetails(this.props.match.params.devEUI, this.state.pending, this.state.starttime, this.state.endtime, limit, offset, callbackFunc);
    }else if (this.state.tab == 3) {
        DeviceStore.DownlinkTXAckList(this.props.match.params.devEUI,"",this.state.errType, limit, offset, callbackFunc);
    }else if (this.state.tab == 4&& !this.state.open) {
      DeviceStore.UpDecodeDataList(this.props.match.params.devEUI,"", this.state.starttime, this.state.endtime,limit, offset, callbackFunc);
    } else if (this.state.open){
      console.log("zhixing------------")
      InternalStore.AsyncTaskList(this.props.match.params.devEUI,1,limit, offset, callbackFunc);
    }
  }

  getRow0(obj) {
    const classes = useStyles
    return (
      <TableRow
        key={obj.id}
        hover
      >
        <TableCell className={this.props.classes.valueTableCell} width="8%">{obj.gatewayId}</TableCell>
        <TableCell className={this.props.classes.DatavalueTableCell} width="40%">{obj.dataJson === undefined ? '' : (obj.dataJson ? obj.dataJson: '---')}</TableCell>

        
        <TableCell className={this.props.classes.DatavalueTableCell} width="10%">
        <Tooltip title={obj.messageType} arrow>
        <span  
          className={ 
            obj.messageType==="ConfirmedDataUp" ? this.props.classes.ConfirmedDataUp
           :obj.messageType==="ConfirmedDataDown" ? this.props.classes.ConfirmedDataDown
           :obj.messageType==="UnconfirmedDataUp" ? this.props.classes.UnconfirmedDataUp 
           :obj.messageType==="UnconfirmedDataDown" ? this.props.classes.UnconfirmedDataDown 
           :obj.messageType==="JoinAccept" ? this.props.classes.JoinAccept 
           :obj.messageType==="JoinRequest" ? this.props.classes.JoinRequest 
           : this.props.classes.None}   
          >
       
          {obj.messageType === "ConfirmedDataUp" ? "CU" 
          :obj.messageType ==="ConfirmedDataDown" ? "CD" 
          :obj.messageType ==="UnconfirmedDataUp" ? "UU" 
          :obj.messageType==="UnconfirmedDataDown" ? "UD" 
          :obj.messageType==="JoinAccept" ? "JA"
          :obj.messageType==="JoinRequest" ? "JR"
         :"---"}
          
          
          </span>
          </Tooltip>
        </TableCell>
        <TableCell className={this.props.classes.valueTableCell} width="5%">{obj.dr}</TableCell>
        <TableCell className={this.props.classes.valueTableCell} width="8%">{obj.frequency}</TableCell>
        <TableCell className={this.props.classes.valueTableCell} width="5%">{obj.loraSnr}</TableCell>
        <TableCell className={this.props.classes.valueTableCell} width="5%">{obj.rssi}</TableCell>
        <TableCell className={this.props.classes.valueTableCell} width="5%">{obj.fCnt}</TableCell>
        <TableCell className={this.props.classes.valueTableCell} width="5%">{obj.fPort === undefined ? 0 : (obj.fPort ? obj.fPort: '---')}</TableCell>
        <TableCell className={this.props.classes.valueTableCell} width="8%">{obj.createdAt}</TableCell>
        <TableCell className={this.props.classes.valueTableCell} width="8%"><HistoryDialog   objectJson={obj.objectJson}  /></TableCell>

      </TableRow>
    );
  }
  getRow1(obj) {

    const classes = useStyles
    return (
      <TableRow
        key={obj.id}
        hover
      >

        <TableCell className={this.props.classes.valueTableCell} width="15%">{obj.gatewayId}</TableCell>
        <TableCell className={this.props.classes.valueTableCell} width="15%">{obj.payloadHex}</TableCell>
        <TableCell className={this.props.classes.valueTableCell} width="10%">{obj.devAddr}</TableCell>
        <TableCell className={this.props.classes.valueTableCell} width="8%">  
        {obj.type === "up" ?  <TrendingUp/>
          :obj.type ==="down" ? <TrendingDown/>

         :"---"}</TableCell>

        <TableCell className={this.props.classes.valueTableCell} width="10%">
          <Tooltip title={obj.messageType} arrow>
          <span  
          className={ 
            obj.messageType==="ConfirmedDataUp" ? this.props.classes.ConfirmedDataUp
           :obj.messageType==="ConfirmedDataDown" ? this.props.classes.ConfirmedDataDown
           :obj.messageType==="UnconfirmedDataUp" ? this.props.classes.UnconfirmedDataUp 
           :obj.messageType==="UnconfirmedDataDown" ? this.props.classes.UnconfirmedDataDown 
           :obj.messageType==="JoinAccept" ? this.props.classes.JoinAccept 
           :obj.messageType==="JoinRequest" ? this.props.classes.JoinRequest 
           : this.props.classes.None}   
          >
       
          {obj.messageType === "ConfirmedDataUp" ? "CU" 
          :obj.messageType ==="ConfirmedDataDown" ? "CD" 
          :obj.messageType ==="UnconfirmedDataUp" ? "UU" 
          :obj.messageType==="UnconfirmedDataDown" ? "UD" 
          :obj.messageType==="JoinAccept" ? "JA"
          :obj.messageType==="JoinRequest" ? "JR"
         :"---"}
          
          
          </span>
          </Tooltip>
        </TableCell>
        <TableCell className={this.props.classes.valueTableCell} width="8%">{obj.frequency}</TableCell>
        <TableCell className={this.props.classes.valueTableCell} width="8%">{obj.loraSnr === undefined ? 0 : (obj.loraSnr ? obj.loraSnr: '---')}</TableCell>
        <TableCell className={this.props.classes.valueTableCell} width="8%">{obj.rssi === undefined ? 0 : (obj.rssi ? obj.rssi: '---')}</TableCell>
        <TableCell className={this.props.classes.valueTableCell} width="8%">{obj.fCnt}</TableCell>
        <TableCell className={this.props.classes.valueTableCell} width="10%">{obj.createdAt}</TableCell>
        <TableCell className={this.props.classes.valueTableCell} width="10%">
        <HistoryDialog   objectJson={obj.objectJson}  />
        </TableCell>
      </TableRow>
    );
  }
  getRow2(obj) {
    return (
      <TableRow
        key={obj.id}
        hover
      >
        <TableCell align="center" width="15%">{obj.devEUI}</TableCell>
        <TableCell align="center" >{obj.confirmed}</TableCell>
        <TableCell align="center" >{obj.pending}</TableCell>
        <TableCell align="center">{obj.fPort}</TableCell>
        <TableCell align="center">{obj.fCnt}</TableCell>
        <TableCell align="center">{obj.data}</TableCell>
        <TableCell align="center">{obj.createdAt}</TableCell>
        <TableCell align="center">{obj.sendTime}</TableCell>
      </TableRow>
    );
  }
  getRow3(obj) {
    const classes = useStyles
    return (
      <TableRow
        key={obj.id}
        hover
      >
        <TableCell align="center" width="15%">{obj.devEUI}</TableCell>
        <TableCell align="center" width="15%">{obj.gatewayID}</TableCell>
        <TableCell align="center" width="15%">{obj.errType}</TableCell>
        <TableCell align="center" width="15%">{obj.createdAt}</TableCell>
        <TableCell align="center" width="15%">
          <HistoryDialog   objectJson={obj.dataJson}  />
        </TableCell>
      </TableRow>
    );
  }
  getRow4(obj) {
    const classes = useStyles
    return (
      <TableRow
        key={obj.id}
        hover
      >
        <TableCell align="center" width="15%">{obj.devEUI}</TableCell>
        <TableCell align="center" width="15%">{obj.gatewayID}</TableCell>
        <TableCell align="center" width="15%">{obj.createdAt}</TableCell>
        <TableCell align="center" width="15%">
          <HistoryDialog   objectJson={obj.dataJson}  />
        </TableCell>
      </TableRow>
    );
  }
  getRow5(obj) {
   
    const { t } = this.props
    const classes = useStyles


    return (
      <TableRow
        key={obj.id}
        hover
      >
        <TableCell align="center" width="15%">{obj.relevanceId}</TableCell>
        <TableCell align="center" width="5%">{
          obj.type === "1" ? t('UpDecodeData') 
         :"---"}</TableCell>
        <TableCell align="center" width="5%" className={obj.result === '3' ? this.props.classes.success : obj.result === '4' ? this.props.classes.failure : ''}>
          

          {obj.result === "1" ? t('TaskWait') 
          :obj.result ==="2" ? t('TaskInProgress') 
          :obj.result ==="3" ? t('TaskComplete') 
          :obj.result==="4" ? t('TaskFail')    
         :"---"}
        </TableCell>
        <TableCell align="center" width="5%">{obj.taskSeconds}</TableCell>

        <TableCell align="center" width="5%">
        {obj.url !== '' && (
            <Link href={`http://${obj.url}`} target="_blank" rel="noopener" style={{ display: 'block', textAlign: 'center', width: '100%' }}>
              {t('download')}
            </Link>
          )}
        </TableCell>
        <TableCell align="center" width="15%">{obj.CreatedAt}</TableCell>
   
      </TableRow>
    );
  }
  Createsynask=()=> {
    var  params = JSON.stringify({devEUI:this.props.match.params.devEUI,starttime:this.state.starttime,endtime:this.state.endtime});
    
    InternalStore.CreateAsyncTask(this.props.match.params.devEUI,params,1,"dev_"+this.props.match.params.devEUI , resp => {
      console.log("======")
    });


  }
  //查看任务list


  onTabChange = (e, v) => {

    this.setState({
      starttime: '',
      endtime: '',
      msgType: '',
      types: '',
      tab: v,
    });
  }

  Starttime(times) {
    this.setState({
      starttime: times.target.value,
    })

  }
  Endtime(times) {
    this.setState({
      endtime: times.target.value,
    })
  }


  handleChange(e) {
    this.setState({
      types: e.target.value,

    })
  }
  handleChangemsg(e) {
    this.setState({
      msgType: e.target.value,

    })
  }

  handleChangeErrType(e) {
    this.setState({
      errType: e.target.value,

    })
  }
  handleChangePending(e) {
    this.setState({
      pending: e.target.value,
    })
  }

  handleClickOpen = () => {
  
    this.setState({
        open : true,
   
    })
  }

  handleClose = () => {
   
    this.setState({
        open : false
    })
  }



  render() {
    const { t } = this.props
    const classes = useStyles


    if (this.state.object === undefined) {
      return null;
    }
    return (
      
      <Form
        submitLabel={this.props.submitLabel}
        onSubmit={this.onSubmit}
        disabled={this.props.disabled}
      >
        <Tabs value={this.state.tab} onChange={this.onTabChange} indicatorColor="primary">
          <Tab label={t('AppHistory')} />
          <Tab label={t('MacHistory')} />
          <Tab label={t('DownlinkMessageQueue')} />
          <Tab label={t('DownlinkTXAck')} />
          <Tab label={t('UpDecodeData')} />
        </Tabs>

        {this.state.tab === 0 && <div>
          <div className={classes.container} noValidate align="right"  >
          <FormControl className={this.props.classes.formControl} >
              <InputLabel className={this.props.classes.SelectRadioshrink} shrink id="demo-simple-select-placeholder-label-label">{t('msgType')}</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={this.state.msgType}
                onChange={this.handleChangemsg}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value={"UnconfirmedDataUp"}>Unconfirmed Data Up</MenuItem>
                <MenuItem value={"ConfirmedDataUp"}>Confirmed Data Up</MenuItem>
              </Select>
            </FormControl>


            <TextField
              id="datetime-local"
              label={t('StartingTime')}
              type="datetime-local"
              defaultValue={this.state.starttime}
              onChange={this.Starttime}
              className={this.props.classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              id="datetime-local"
              label={t('EndTime')}
              type="datetime-local"
              defaultValue={this.state.endtime || ""}
              onChange={this.Endtime}
              className={this.props.classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
            />

            <Button onClick={() => { this.onTabChange(null, 0) }} className={this.props.classes.Refreshcss}><Refresh /></Button>
          </div>

          <Grid container spacing={0}>
            <Grid item xs={12}>
              <DataTable

                header={

                  <TableRow className={this.props.classes.MuiTableHead} >
                    <TableCell className={this.props.classes.HeadTableCell} width="300px" >{t('GatewayId')}</TableCell>
                    <TableCell className={this.props.classes.HeadTableCell} width="300px">Data</TableCell>
                    {/* <TableCell className={this.props.classes.HeadTableCell} width="110px">{t('TransmissionDirection')}</TableCell> */}
                    <TableCell className={this.props.classes.HeadTableCell} width="10%">{t('msgType')}</TableCell>
                    <TableCell className={this.props.classes.HeadTableCell} width="110px">{t('DR')}</TableCell>
                    <TableCell className={this.props.classes.HeadTableCell} width="110px">{t('freq')}</TableCell>
                    <TableCell className={this.props.classes.HeadTableCell} width="110px">{t('SNR')}</TableCell>
                    <TableCell className={this.props.classes.HeadTableCell} width="110px">{t('RSSI')}</TableCell>
                    <TableCell className={this.props.classes.HeadTableCell} width="110px">{t('frameCounter')}</TableCell>
                    <TableCell className={this.props.classes.HeadTableCell} width="110px">{t('fPort')}</TableCell>
                    <TableCell className={this.props.classes.HeadTableCell} width="150px">{t('createdTime')}</TableCell>
                    <TableCell className={this.props.classes.HeadTableCell} width="150px">Json</TableCell>
                  </TableRow>
                }
                getPage={this.getPage}
                getRow={this.getRow0}
              />
            </Grid>
          </Grid>

        </div>}

        {this.state.tab === 1 && <div>
          <div className={classes.container} noValidate align="right"  >

            <FormControl className={this.props.classes.formControl} >
              <InputLabel className={this.props.classes.SelectRadioshrink} shrink id="demo-simple-select-placeholder-label-label">{t('msgType')}</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={this.state.msgType}
                onChange={this.handleChangemsg}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value={"JoinRequest"}>Join Request</MenuItem>
                <MenuItem value={"JoinAccept"}>Join Accept</MenuItem>
                <MenuItem value={"UnconfirmedDataUp"}>Unconfirmed Data Up</MenuItem>
                <MenuItem value={"UnconfirmedDataDown"}>Unconfirmed Data Down</MenuItem>
                <MenuItem value={"ConfirmedDataUp"}>Confirmed Data Up</MenuItem>
                <MenuItem value={"ConfirmedDataDown"}>Confirmed Data Down</MenuItem>
                {/* <MenuItem value={"RejoinRequest"}>ejoin Request</MenuItem>
                <MenuItem value={"Proprietaryn"}>Proprietaryn</MenuItem> */}
              </Select>
            </FormControl>
            <FormControl className={this.props.classes.formControl} >
              <InputLabel shrink id="demo-simple-select-placeholder-label-label">
                {t('TransmissionDirection')}   
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={this.state.types}
                onChange={this.handleChange}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value={"up"}>up</MenuItem>
                <MenuItem value={"down"}>down</MenuItem>
              </Select>
            </FormControl>


            <TextField
              id="datetime-local"
              label={t('StartingTime')}
              type="datetime-local"
              defaultValue={this.state.starttime}
              onChange={this.Starttime}
              className={this.props.classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              id="datetime-local"
              label={t('EndTime')}
              type="datetime-local"
              defaultValue={this.state.endtime || ""}
              onChange={this.Endtime}
              className={this.props.classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
            />

            <Button onClick={() => { this.onTabChange(null, 1) }} className={this.props.classes.Refreshcss}><Refresh /></Button>

          </div>
          <Grid container spacing={0}>

            <Grid item xs={12}>
              <DataTable
                header={
                  <TableRow className={this.props.classes.MuiTableHead}>
                    <TableCell className={this.props.classes.HeadTableCell} width="15%">{t('GatewayId')}</TableCell>
                    <TableCell className={this.props.classes.HeadTableCell} width="15%" >Payload</TableCell>
                    <TableCell className={this.props.classes.HeadTableCell} width="10%" >{t('devAddr')}</TableCell>
                    <TableCell className={this.props.classes.HeadTableCell} width="10%">{t('TransmissionDirection')}</TableCell>
                    <TableCell className={this.props.classes.HeadTableCell} width="10%">{t('msgType')}</TableCell>
                    {/* <TableCell className={this.props.classes.HeadTableCell} width="10%">{t('DR')}</TableCell> */}
                    <TableCell className={this.props.classes.HeadTableCell} width="10%">{t('freq')}</TableCell>
                    <TableCell className={this.props.classes.HeadTableCell} width="10%">{t('SNR')}</TableCell>
                    <TableCell className={this.props.classes.HeadTableCell} width="10%">{t('RSSI')}</TableCell>
                    <TableCell className={this.props.classes.HeadTableCell} width="10%">{t('frameCounter')}</TableCell>
                    {/* <TableCell className={this.props.classes.HeadTableCell} width="10%">{t('fPort')}</TableCell> */}
                    <TableCell className={this.props.classes.HeadTableCell} width="10%">{t('createdTime')}</TableCell>
                    <TableCell className={this.props.classes.HeadTableCell} width="10%">Json</TableCell>

                  </TableRow>
                }
                getPage={this.getPage}
                getRow={this.getRow1}
              />
            </Grid>
          </Grid>
        </div>}

        {this.state.tab === 2 && <div>
          <div className={classes.container} noValidate align="right"  >

            <FormControl className={this.props.classes.formControl} >
              <InputLabel shrink id="demo-simple-select-placeholder-label-label">
                Pending
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={this.state.pending}
                onChange={this.handleChangePending}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value={"true"}>True</MenuItem>
                <MenuItem value={"false"}>False</MenuItem>
              </Select>
            </FormControl>


            <TextField
              id="datetime-local"
              label={t('StartingTime')}
              type="datetime-local"
              defaultValue={this.state.starttime}
              onChange={this.Starttime}
              className={this.props.classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              id="datetime-local"
              label={t('EndTime')}
              type="datetime-local"
              defaultValue={this.state.endtime || ""}
              onChange={this.Endtime}
              className={this.props.classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <Button onClick={() => { this.onTabChange(null, 2) }} className={this.props.classes.Refreshcss}><Refresh /></Button>
          </div>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <DataTable
                header={
                  <TableRow className={this.props.classes.MuiTableHead}>
                    <TableCell className={this.props.classes.HeadTableCell}>DevEUI</TableCell>
                    <TableCell className={this.props.classes.HeadTableCell}>Confirmed</TableCell>
                    <TableCell className={this.props.classes.HeadTableCell}>Pending</TableCell>
                    <TableCell className={this.props.classes.HeadTableCell}>Fport</TableCell>
                    <TableCell className={this.props.classes.HeadTableCell}>FCnt</TableCell>
                    <TableCell className={this.props.classes.HeadTableCell}>Data</TableCell>
                    <TableCell className={this.props.classes.HeadTableCell}>{t('createdTime')}</TableCell>
                    <TableCell className={this.props.classes.HeadTableCell}>{t('SendTime')}</TableCell>
                  </TableRow>
                }
                getPage={this.getPage}
                getRow={this.getRow2}
              />
            </Grid>
          </Grid>
        </div>}
        {this.state.tab === 3 && <div>
          <div className={classes.container} noValidate align="right"  >
          <FormControl className={this.props.classes.formControl} >
              <InputLabel className={this.props.classes.SelectRadioshrink} htmlFor="age-simple">{t('errType')}</InputLabel>
              <Select
                labelId="errType"
                id="errType"
                value={this.state.errType}
                onChange={this.handleChangeErrType}
              
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {/* <MenuItem value='0'>IGNORED</MenuItem>
                <MenuItem value='1'>OK</MenuItem> */}
                <MenuItem value='2'>TOO_LATE</MenuItem>
                <MenuItem value='3'>TOO_EARLY</MenuItem>
                <MenuItem value='4'>COLLISION_PACKET</MenuItem>
                <MenuItem value='5'>COLLISION_BEACON</MenuItem>
                <MenuItem value='6'>TX_FREQ</MenuItem>
                <MenuItem value='7'>TX_POWER</MenuItem>
                <MenuItem value='8'>GPS_UNLOCKED</MenuItem>
                <MenuItem value='9'>QUEUE_FULL</MenuItem>
                <MenuItem value='10'>INTERNAL_ERROR</MenuItem>
              </Select>
            </FormControl>
            


          
            <Button onClick={() => { this.onTabChange(null, 3) }} className={this.props.classes.Refreshcss}><Refresh /></Button>
          </div>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <DataTable
                header={
                  <TableRow className={this.props.classes.MuiTableHead}>
                    <TableCell className={this.props.classes.HeadTableCell}>DevEUI</TableCell>
                    <TableCell className={this.props.classes.HeadTableCell} width="15%">{t('GatewayId')}</TableCell>
                    <TableCell className={this.props.classes.HeadTableCell} width="15%">{t('errType')}</TableCell>
                    <TableCell className={this.props.classes.HeadTableCell} width="10%">{t('createdTime')}</TableCell>
                    <TableCell className={this.props.classes.HeadTableCell} width="10%">Json</TableCell>
                   
                    
                  </TableRow>
                }
                getPage={this.getPage}
                getRow={this.getRow3}
              />
            </Grid>
          </Grid>
        </div>}

        {this.state.tab === 4 && <div>
          <div className={classes.container} noValidate align="right"  >
          
          
         


          
        <ButtonGroup  className={this.props.classes.task} >
        <CustomTextField
              id="datetime-local"
              label={t('StartingTime')}
              type="datetime-local"
              defaultValue={this.state.starttime}
              onChange={this.Starttime}
              className={this.props.classes.textField1}
              InputLabelProps={{
                shrink: true,
              }}
            />



            <CustomTextField
              id="datetime-local"
              label={t('EndTime')}
              type="datetime-local"
              defaultValue={this.state.endtime || ""}
              onChange={this.Endtime}
              className={this.props.classes.textField1}
              InputLabelProps={{
                shrink: true,
              }}
              />

          
          <Button variant="outlined" onClick={() => { this.Createsynask() }} >{t('CreateTask')}</Button>
          <Button variant="outlined"onClick={() => { this.handleClickOpen() }} >{t('CheckTask')}</Button>
          <Button onClick={() => { this.onTabChange(null, 3) }} > <Refresh /></Button>
        </ButtonGroup>
          
          </div>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <DataTable
                header={
                  <TableRow className={this.props.classes.MuiTableHead}>
                    <TableCell className={this.props.classes.HeadTableCell}>{t('DeviceEui')}</TableCell>
                    <TableCell className={this.props.classes.HeadTableCell} width="15%">{t('GatewayId')}</TableCell>
                    <TableCell className={this.props.classes.HeadTableCell} width="10%">{t('createdTime')}</TableCell>
                    <TableCell className={this.props.classes.HeadTableCell} width="10%">Json</TableCell>
                  </TableRow>
                }
                getPage={this.getPage}
                getRow={this.getRow4}
              />
            </Grid>
          </Grid>
        </div>}
        


          <div>
         <CustomDialog
            fullWidth={this.state.fullWidth}
            maxWidth={this.state.maxWidth}
            open={this.state.open}
            onClose={this.handleClose}
          >
             <Grid container spacing={4}>
                <Grid item xs={12}>
                  <DataTable
                    header={
                      <TableRow className={this.props.classes.MuiTableHead}>
                        <TableCell className={this.props.classes.HeadTableCell}>{t('DeviceEui')}</TableCell>
                        <TableCell className={this.props.classes.HeadTableCell}>{t('TaskType')}</TableCell>
                        <TableCell className={this.props.classes.HeadTableCell}>{t('TaskResult')}</TableCell>
                        <TableCell className={this.props.classes.HeadTableCell}>{t('TaskSeconds')}</TableCell>
                        <TableCell className={this.props.classes.HeadTableCell} width="5%">{t('download')}</TableCell>
                        <TableCell className={this.props.classes.HeadTableCell} width="10%">{t('createdTime')}</TableCell>
                        
                      </TableRow>
                    }

            

    
                    getPage={this.getPage}
                    getRow={this.getRow5}
                  />
                </Grid>
              </Grid>
            {/* <DialogActions>
              <Button color="primary" onClick={this.handleClose}>{t('close')}</Button>
            </DialogActions> */}
          </CustomDialog>
     

          </div>

      </Form>


    );
  }
}
export default withStyles(styles)(withTranslation()(DeviceForm));
