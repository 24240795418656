import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { IconButton } from "@material-ui/core";

import { withStyles } from '@material-ui/core/styles';
import CloseIcon from 'mdi-material-ui/Close';
import JSONTree from "./JSONTree";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import CodeGreaterThan  from "mdi-material-ui/CodeGreaterThan";
import Grid from "@material-ui/core/Grid";

import { withTranslation } from 'react-i18next';

const styles = {
    closeBtn: {
        float: 'right'
    },
    searchBox: {
    },
    treeStyle: {
        paddingTop: 0,
        paddingBottom: 0,
        fontSize: 12,
        lineHeight: 1.5,
      },
}

class HistoryDialog extends Component{

    constructor(){
        super()
        this.state = {
            open: false,
            search: '',
            fullWidth: true,
            maxWidth: "sm",
            applicationjson:"",
        }
    }

   handleClickOpen = () => {
    var jdata = JSON.parse(this.props.objectJson)

    this.setState({
        open : true,
        applicationjson: jdata,
    })
  }
   handleClose = () => {
   
    this.setState({
        open : false
    })
  }




  render (){
     const { t } = this.props;
      return(
        <div>
        <IconButton
              color="inherit"
              onClick={this.handleClickOpen}
            >
              <CodeGreaterThan />
      </IconButton>
        <Dialog
            fullWidth={this.state.fullWidth}
            maxWidth={this.state.maxWidth}
            open={this.state.open}
            onClose={this.handleClose}
          >
            <DialogContent  >
                 <ExpansionPanel>
                  <ExpansionPanelDetails>
                      <Grid item xs className={this.props.classes.treeStyle}>
                        <JSONTree data={this.state.applicationjson} />
                      </Grid>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
            </DialogContent>
            <DialogActions>
              <Button color="primary" onClick={this.handleClose}>{t('close')}</Button>
            </DialogActions>
          </Dialog>
      </div>
      );
    }
}
export default withStyles(styles)(withRouter(withTranslation()(HistoryDialog)));