import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";

import Grid from "@material-ui/core/Grid";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import IconButton from '@material-ui/core/IconButton';

import Plus from "mdi-material-ui/Plus";
import Delete from "mdi-material-ui/Delete";

import { withTranslation } from "react-i18next";
import DialogContent from '@material-ui/core/DialogContent';
import TitleBar from "../../components/TitleBar";
import TitleBarTitle from "../../components/TitleBarTitle";
import TitleBarButton from "../../components/TitleBarButton";
import DataTable from "../../components/DataTable";
import InternalStore from "../../stores/InternalStore";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Button from "@material-ui/core/Button";
import TableBody from "@material-ui/core/TableBody";
import DialogTitle from '@material-ui/core/DialogTitle';
import ContentCopy from "mdi-material-ui/ContentCopy";
import copy from "copy-to-clipboard";
import CodeGreaterThan  from "mdi-material-ui/CodeGreaterThan";
import Table from "@material-ui/core/Table";
const useStyles = withStyles((theme) => ({
  }));
  const styles = {
    valueTableCell: {
      textAlign: "left",
      color: "black",
    },
    HeadTableCell: {
      textAlign: "left",
    },
  };

class ListOrganizationAPIKeys extends Component {
 

  constructor() {
    super();
    this.state = {
      id: "",
      spDialog: false,
      fullWidth: true,
      maxWidth: "sm",
      tab:1,
      key:{},

    };
    this.Detail = this.Detail.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
    this.onCopy = this.onCopy.bind(this);
    this.onDelete = this.onDelete.bind(this);
    



  }
  onDelete(id)  {
    if (window.confirm(this.props.t('deleteApiKeyTips'))) {
      InternalStore.deleteAPIKey(id, resp => {
        this.props.history.push(`/organizations/${this.props.match.params.organizationID}/api-keys`);
      });
    }
  }
  onCopy(s){
    var  str = s.replace(/\s*/g,"");
    copy(str); 
  }  

  Detail(id) {
   

    InternalStore.getAPIKey(id, resp => {
      if ((resp.jwtToken).length >50){
        resp.exhibitToken=(resp.jwtToken).slice(0,50)+"..."

        this.setState({
          key: resp,

        })
      }else{
        resp.exhibitToken="----"
        this.setState({
          key: resp,
  
        })
      }

    });
    this.setState({
      spDialog: true,
      id: id,
    })
    console.log(this.state.spDialog)
  }
  closeDialog() {
    this.setState({
      spDialog: false,
    });
  }

  getPage = (limit, offset, callbackFunc) => {
    InternalStore.GetAuthorizationState(1, resp => {
        if (!resp.state){
          this.props.history.push(`/organizations/${this.props.match.params.organizationID}`);
        }
  
      }
    )
    InternalStore.listAPIKeys({
      organizationID: this.props.match.params.organizationID,
      limit: limit,
      offset: offset,
    }, callbackFunc);
  }

  getRow = (obj) => {
    return (
      <TableRow
        key={obj.id}
        hover
      >
        <TableCell className={this.props.classes.valueTableCell} >{obj.id}</TableCell>
        <TableCell className={this.props.classes.valueTableCell} >{obj.name}</TableCell>
        <TableCell className={this.props.classes.valueTableCell}>
          <IconButton onClick={this.Detail.bind(this,obj.id)}><CodeGreaterThan /></IconButton>
        </TableCell>
        <TableCell align="right" className={this.props.classes.valueTableCell}>
        <IconButton aria-label="delete" onClick={this.onDelete.bind(this,obj.id)}><Delete  /></IconButton>
        </TableCell>
      </TableRow>
    );
  }

  render() {
    const { t } = this.props
    return (

      <Grid container >
        <Grid container spacing={4}>
          <TitleBar
            buttons={
              <TitleBarButton
                label={t('create')}
                icon={<Plus />}
                to={`/organizations/${this.props.match.params.organizationID}/api-keys/create`}
              />
            }
          >
            <TitleBarTitle title={t('organizationApikeys')} />
          </TitleBar>
          <Grid item xs={12}>
            <DataTable
              header={
                <TableRow>
                  <TableCell className={this.props.classes.HeadTableCell} >ID</TableCell>
                  <TableCell className={this.props.classes.HeadTableCell} >{t('name')}</TableCell>
                  <TableCell className={this.props.classes.HeadTableCell} >{t('details')}</TableCell>
                  <TableCell className={this.props.classes.HeadTableCell} ></TableCell>
                </TableRow>
              }
              getPage={this.getPage}
              getRow={this.getRow}
            />
          </Grid>
        </Grid>
       
        <Dialog
          fullWidth={this.state.fullWidth}
          maxWidth={this.state.maxWidth}
          open={this.state.spDialog}
          onClose={this.closeDialog}
          aria-labelledby="max-width-dialog-title"

        >
          <DialogTitle>{t('APIKeyDetails')}</DialogTitle>
          <DialogContent  >

          <Table>
            <TableBody>
            <TableRow>
                <TableCell>Id</TableCell>
                <TableCell width="400px" >{this.state.key === undefined ? '' : (this.state.key.id ? this.state.key.id: '--')}</TableCell>    
                <TableCell align="right"   onClick={()=>{this.onCopy(this.state.key.id)}} >
                  <IconButton
                    color="primary"
                    title={t('copy')}
                  >
                    <ContentCopy/>
                  </IconButton>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell width="400px" >{this.state.key === undefined ? '' : (this.state.key.name ? this.state.key.name: '--')}</TableCell>    
                <TableCell align="right"   onClick={()=>{this.onCopy(this.state.key.name)}} >
                  <IconButton
                    color="primary"
                    title={t('copy')}
                  >
                    <ContentCopy/>
                  </IconButton>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>Token</TableCell>
                <TableCell width="400px" >{this.state.key === undefined ? '' : (this.state.key.exhibitToken ? this.state.key.exhibitToken: '--')}</TableCell>    
                <TableCell align="right"   onClick={()=>{this.onCopy(this.state.key.jwtToken)}} >
                  <IconButton
                    color="primary"
                    title={t('copy')}
                  >
                    <ContentCopy/>
                  </IconButton>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t('createdTime')}</TableCell>
                <TableCell width="400px" >{this.state.key === undefined ? '' : (this.state.key.createdAt ? this.state.key.createdAt: '--')}</TableCell>    
                <TableCell align="right"   onClick={()=>{this.onCopy(this.state.key.createdAt)}} >
                  <IconButton
                    color="primary"
                    title={t('copy')}
                  >
                    <ContentCopy/>
                  </IconButton>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={this.closeDialog}>{t('close')}</Button>
          </DialogActions>
        </Dialog> 
       
        </Grid>

    );
  }
}


export default withStyles(styles)(withTranslation()(ListOrganizationAPIKeys));
