import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import IconButton from '@material-ui/core/IconButton';

import Plus from "mdi-material-ui/Plus";
import Delete from "mdi-material-ui/Delete";

import { withTranslation } from "react-i18next";

import TitleBar from "../../components/TitleBar";
import TitleBarTitle from "../../components/TitleBarTitle";
import TitleBarButton from "../../components/TitleBarButton";
import DataTable from "../../components/DataTable";
import InternalStore from "../../stores/InternalStore";


const styles = {

  normal:{
    color:"green"
  },
  abnormal:{
 
    color:"red",
  }
};

class AuthorizationRow extends Component {
  onDelete = () => {
    if (window.confirm(this.props.t('deleteAuthorizationTips'))) {
      InternalStore.deleteAuthorization(this.props.obj.id, resp => {
      
        this.props.history.push("/authorization");
      });
    }
  }

  render() {
    const { t } = this.props
    return(
      <TableRow hover>
        <TableCell >{this.props.obj.license}</TableCell>
        <TableCell>{this.props.obj.lastCheckTime}</TableCell>
        <TableCell className={ this.props.obj.state==1 ? this.props.classes.normal : this.props.classes.abnormal}>{this.props.obj.state == 0 ? t('Invalid') :  t('Normal')} </TableCell>
        <TableCell>{this.props.obj.createdAt}</TableCell>
        {/* <TableCell align="right">
          <IconButton aria-label="delete"><Delete onClick={this.onDelete} /></IconButton>
        </TableCell> */}
      </TableRow> 
    );
  }
}


AuthorizationRow = withStyles(styles)(withTranslation()(AuthorizationRow));

class ListAdminAuthorization extends Component {
  getPage = (limit, offset, callbackFunc) => {
    InternalStore.listAuthorization({
      limit: limit,
      offset: offset,
    }, callbackFunc);
  }

  getRow = (obj) => {
    return(<AuthorizationRow key={obj.id} obj={obj} />);
  }


  render() {
    const { t } = this.props
    return(
      <Grid container spacing={4}>
        <TitleBar
          buttons={
            <TitleBarButton
              key={1}
              label={t('CreateOrUpdate')}
              icon={<Plus />}
              to={`/authorization/create`}
            />
          }
        >
          <TitleBarTitle title={t('AuthorizationManagement')} />
        </TitleBar>
        <Grid item xs={12}>
          <DataTable
            header={
              <TableRow>
              {/* <TableCell>ID</TableCell> */}
              <TableCell>Licennse</TableCell>
              
              <TableCell>{t('Expiredate')}</TableCell>
              <TableCell>{t('state')}</TableCell>
              <TableCell>{t('createdTime')}</TableCell>
              
              
              {/* <TableCell></TableCell> */}
            </TableRow>
            }
            getPage={this.getPage}
            getRow={this.getRow}
          />
        </Grid>
      </Grid>
    );
  }
}

export default withTranslation()(ListAdminAuthorization);
