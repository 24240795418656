export default{
    dashboard: "首页",
    applications: "应用管理",
    multicastGroups: "多播组管理",
    gateways: "网关管理",
    organizationsManage: "组织管理",
    setting: "系统配置",
    changeLang: "更换语言",
    search: "搜索",
    searchTips: "搜索组织、应用程序、网关或设备",
    changePassword: "修改密码",
    logout: "退出",
    login: "登录",
    usernameOrEmail: "用户名/邮箱",
    password: '密码',
    orgDashboard: '组织. 首页',
    orgUsers: '组织. 用户',
    orgApiKey: '组织. API 密钥',
    serviceProfiles: '服务配置',
    deviceProfiles: '设备配置',
    sysDashboard: '系统.首页',
    networkServers: '网络服务器',
    gatewayProfiles: '网关配置',
    allUsers: '全部用户',
    apiKeys: 'API 密钥',
    organizations: '组织列表',
    dashboard2: '仪表板',
    activeDevices: '活跃设备',
    neverSeen: 'Never Seen',
    inactive: '无效',
    active: '有效',
    activeGateways: '活跃网关',
    deviceDataRateUsage: '设备数据速率使用',
    noDataAvailable: '没有可用数据',
    gateways2: '网关地图',
    add: '添加',
    name: '名称',
    server: '服务器',
    networkServer: '网络服务器',
    create: '创建',
    help: '帮助',
    close: '关闭',
    gatewayProfileHelp: '网关配置帮助',
    gatewayProfileHelpContent1: '网关配置文件的唯一目的是使用网关配置文件设置的配置属性（重新）配置一个或多个网关。',
    gatewayProfileHelpContent2: '当 Zysmart 网络服务器检测到网关的配置与其网关配置文件不同步时，它将向网关推送配置命令以更新其配置。',
    gatewayProfileHelpContent3: '请注意，此功能是可选的，只能与 Zysmart Concentratord 组件结合使用。',
    gatewayProfileHelpContent4: '还要注意，网关配置文件不会改变设备的行为方式。要配置设备必须使用的通道计划，请更新Zysmart网络服务器配置。',
    displayName: '显示名称',
    canHaveGateways: '拥有网关权限',
    users: '用户列表',
    email: '邮箱',
    admin: '管理员',
    globalApikeys: '全局API密钥',
    users2: '用户',
    changePassword2: "修改密码",
    updatePassword: '更新密码',
    kind: '类别',
    delete: '删除',
    organizations2: '组织',
    configuration: '配置',
    organizationUsers: '组织-用户列表',
    organizationAdmin: '组织-管理权限',
    gatewayAdmin: '网关-管理权限',
    deviceAdmin: '设备-管理权限',
    organizationApikeys: '组织-API密钥',
    deleteApiKeyTips: "确定要删除此API密钥吗？",
    createApiKey: '创建API密钥',
    ApiKeyId: 'API密钥 ID',
    ApiKeyName: 'API密钥名称',
    token: '令牌',
    tokenHelpText: '使用此 API 密钥发出 API 请求时使用此令牌。 此令牌只提供一次。',
    apiKayNameHelpText: 'API密钥的描述性名称',
    networkServer2: '网络服务器',
    gateways3: '网关列表',
    lastSeen: '最近活跃',
    gatewayId: '网关 ID',
    gatewayActivity30d: '网关活动 (30天内)',
    serviceProfiles2: '服务配置',
    description: '描述',
    applications2: '应用列表',
    multicastGroups2: '多播组列表',
    addNetworkServer: '添加网络服务器',
    general: '常规',
    gatewayDiscovery: '网关发现',
    TlsCertificates: 'TLS 证书',
    networkServerName: '网络服务器名称',
    networkServerNameHelpText: '用于标识网络服务器的名称。',
    networkServerServer: '网络服务器地址',
    networkServerServerHelpText: "网络服务器的'主机名:端口', 例如 'localhost:8000'",
    enableGatewayDiscovery: '启用网关发现',
    enableGatewayDiscoveryHelpText: '为此网络服务器启用网关发现功能。',
    intervalPerDay: '间隔(每天)',
    intervalPerDayHelpText: "Zysmart应用服务器每天将通过每个网关广播的网关发现“ping”的数量。",
    TXFrequency: '发送频率 (Hz)',
    TXFrequencyHelpText: "用于传输网关发现“ping”的频率 (Hz)。 请查阅 LoRaWAN 区域参数规范，了解每个区域有效的通道。",
    TXDataRate: '发送数据速率',
    TXDataRateHelpText: "用于传输网关发现“ping”的数据速率。 请查阅 LoRaWAN 区域参数规范，了解每个区域的有效数据速率。",
    gatewayDiscoveryTips: 'Zysmart应用服务器到Zysmart网络服务器连接的证书',
    CACertificate: 'CA证书',
    CACertificateHelpText: '将CA证书（PEM）文件的内容粘贴到上述文本框中。留空可禁用TLS。',
    TLSCertificate: 'TLS证书',
    TLSCertificateHelpText: '将TLS证书（PEM）文件的内容粘贴到上述文本框中。留空可禁用TLS。',
    TLSKey: 'TLS密钥',
    TLSKeyHelpText: "将TLS密钥（PEM）文件的内容粘贴到上述文本框中。留空可禁用TLS。注意: 出于安全原因，提交后无法检索TLS密钥（该字段留空）。当使用空的TLS密钥字段（但填充了TLS证书字段）重新提交表单时，密钥不会被覆盖。",
    gatewayDiscoveryTips2: 'Zysmart 网络服务器到 Zysmart 应用程序服务器连接的证书',
    deleteDeviceProfileTips: '确定要删除此设备配置文件吗？',
    deleteNetworkServerTips: '确定要删除此网络服务器吗？',
    updateNetworkServer: '更新网络服务器',
    createGatewayProfile: '创建网关配置',
    addANetworkServer: '添加网络服务器？',
    addANetworkServerTips1: "Zysmart 应用服务器未连接到 Zysmart 网络服务器。 您是否知道 Zysmart 应用服务器可以连接到多个 Zysmart 网络服务器实例，例如支持多个区域？",
    addANetworkServerTips2: "您想现在连接到网络服务器吗？",
    dismiss: '不理会',
    addExtraChannel: '添加额外通道',
    gatewayNameHelpText: '标识网关配置文件的短名称。',
    statsInterval: '统计间隔（秒）',
    statsIntervalHelpText: '网关报告其统计信息的统计时间间隔。建议值为30秒。',
    enabledChannels: '启用的通道',
    enabledChannelsHelpText: 'LoRaWAN 区域参数规范中指定的此网关配置文件中的活动通道。 用逗号分隔通道，例如 0, 1, 2. 额外通道不得包含在此列表中。',
    selectNetworkServer: '选择网络服务器',
    extraChannel: '额外通道',
    modulation: '调制',
    bandwidth: '带宽 (kHz)',
    frequency: '频率 (Hz)',
    spreadingFactors: '扩频因子',
    spreadingFactorsHelpText: '定义多个扩展因子时，通道将在网关上配置为多SF通道。',
    bitrate: '码率',
    deleteGatewayProfileTips: '确定要删除此网关配置文件吗？',
    updateGatewayProfile: '更新网关配置',
    createOrganization: '创建组织',
    organizationName: '组织名称',
    organizationNameHelpText: '名称只能包含字母、数字和破折号。',
    maxNumberOfGateways: '最大网关数量',
    maxNumberOfGatewaysHelpText: '可以添加到该组织的最大网关数（0 = 无限制）。',
    gateways4: '网关',
    organizationCanHaveGateways: '组织能否拥有网关',
    organizationCanHaveGatewaysHelpText: '选中后，这意味着组织管理员能够将自己的网关添加到网络中。 请注意，网关的使用不限于此组织。',
    devices: '设备',
    maxNumberOfDevices: '最大设备数量',
    maxNumberOfDevicesHelpText: '可以添加到此组织的最大设备数（0 = 无限制）。',
    updateOrganization: '更新组织',
    createUser: '创建用户',
    emailAddress: '邮箱地址',
    optionalNote: '可选注释',
    optionalNoteHelpText: '可选注释，例如 电话号码、地址、评论...',
    permissions: '权限',
    isActive: '启用状态',
    isGlobalAdmin: '全局管理员',
    deleteUserTips: '确定要删除此用户吗？',
    updateUser: '更新用户',
    addUser: '添加用户',
    addUserTips: '没有额外权限的用户将能够查看此组织下的所有资源，并能够发送和接收设备数据。',
    userIsOrganizationAdmin: '用户是否为组织管理员',
    userIsOrganizationAdminHelpText: '组织管理员用户可以在组织中添加和修改资源。',
    userIsDeviceAdmin: '用户是否为设备管理员',
    userIsDeviceAdminHelpText: '设备管理员用户可以添加和修改组织中与设备相关的资源。',
    userIsGatewayAdmin: '用户是否为网关管理员',
    userIsGatewayAdminHelpText: '网关管理员用户可以在组织中添加和修改网关。',
    organizationUsers2: '组织-用户',
    gotoUser: '转到用户页面',
    deleteOrgUserTips: '确定要删除此组织用户吗？（这不会删除用户本身）',
    createServiceProfile: '创建服务配置',
    serviceProfileName: '服务配置名称',
    serviceProfileNameHelpText: '用于标识服务配置文件的名称。',
    networkServerSelectHelpText: "将在其上提供此服务配置文件的网络服务器。 创建服务配置文件后，此值无法更改。",
    addGatewayMetaData: '添加网关元数据',
    addGatewayMetaDataHelpText: '网关元数据 (RSSI, SNR, GW geoloc., etc.) 添加到发送到应用服务器的数据包',
    enableNetworkGeolocation: '启用网络地理定位',
    enableNetworkGeolocationHelpText: '启用后，网络服务器将尝试解析此服务配置文件下设备的位置。 请注意，您需要有支持精细时间戳功能的网关，并且需要配置网络服务器以提供地理定位支持。',
    deviceStatusRequestFrequency: '设备状态请求频率',
    deviceStatusRequestFrequencyHelpText: '发起终端设备状态请求的频率(请求/天)。设置为0表示禁用。',
    reportDeviceBatteryLevelToAppServer: '向应用服务器报告设备电池电量',
    reportDeviceLinkMarginToAppServer: '向应用服务器报告设备链接余量',
    minimumAllowedDataRate: '最小允许数据速率',
    minimumAllowedDataRateHelpText: '最小允许数据速率。 用于 ADR。',
    maximumAllowedDataRate: '最大允许数据速率',
    maximumAllowedDataRateHelpText: '最大允许数据速率。 用于 ADR。',
    privateGateways: '私有网关',
    privateGatewaysHelpText: '此服务配置文件下的网关是私有的。这意味着这些网关只能由相同服务配置文件下的设备使用。',
    deleteServiceProfileTips: '确定要删除此服务配置文件吗？',
    updateServiceProfile: '更新服务配置',
    addAServiceProfile: '添加服务配置文件？',
    addAServiceProfileTips1: '所选组织还没有服务配置文件。 服务配置文件将组织连接到网络服务器并定义组织可以在此网络服务器上使用的功能。',
    addAServiceProfileTips2: '要创建服务配置文件吗？',
    createDeviceProfile: '创建设备配置',
    codec: '编解码器',
    tags: '标签',
    deviceProfileName: '设备配置名称',
    deviceProfileNameHelpText: '用于标识设备配置文件的名称。',
    deviceSelectNetworkServerHelpText: "将在其上配置此设备配置文件的网络服务器。 创建设备配置文件后，此值无法更改。",
    LoRaWANMACVersion: 'LoRaWAN MAC 版本',
    selectLoRaWANMACVersion: '选择 LoRaWAN MAC 版本',
    selectLoRaWANMACVersionHelpText: '该设备支持的LoRaWAN MAC 版本。',
    LoRaWANRegionalParametersRevision: 'LoRaWAN 区域参数修订',
    selectLoRaWANRegionalParametersRevision: '选择 LoRaWAN 区域参数修订',
    selectLoRaWANRegionalParametersRevisionHelpText: '设备支持的区域参数规范的修订。',
    ADRAlgorithm: 'ADR 算法',
    selectADRAlgorithm: '选择 ADR 算法',
    selectADRAlgorithmHelpText: '将用于控制设备数据速率的 ADR 算法。',
    maxEIRP: '最大 EIRP',
    maxEIRPHelpText: '设备支持的最大 EIRP。',
    uplinkInterval: '上行间隔（秒）',
    uplinkIntervalHelpText: '设备预期的上行报文发送时间间隔(秒)。这用于确定设备是活动状态的还是非活动状态的。',
    deviceSupportsOTAA: '设备支持 OTAA',
    RX1Delay: 'RX1 延迟',
    RX1DelayHelpText: 'RX1 延迟（有效值为 0 - 15）。',
    RX1DataRateOffset: 'RX1 数据速率偏移',
    RX2DataRate: 'RX2 数据速率',
    RX2DataRateHelpText: '有效值请参考LoRaWAN区域参数规范。',
    RX2ChannelFrequency: 'RX2 通道频率 (Hz)',
    factoryPresetFrequencies: '出厂预设频率 (Hz)',
    factoryPresetFrequenciesHelpText: '出厂预设频率 (Hz) 列表，逗号分隔。',
    deviceSupportsClassB: '设备支持 Class-B',
    ClassBConfirmedDownlinkTimeout: 'Class-B 下行确认超时',
    ClassBConfirmedDownlinkTimeoutHelpText: 'Class-B 下行确认传输超时（秒）。',
    ClassBPingSlotPeriodicity: 'Class-B ping-slot 周期',
    ClassBPingSlotPeriodicityHelpText: 'Class-B ping-slot 周期。',
    selectClassBPingSlotPeriodicity: '选择 Class-B ping-slot 周期',
    ClassBPingSlotDataRate: 'Class-B ping-slot 数据速率',
    ClassBPingSlotFrequency: 'Class-B ping-slot 频率 (Hz)',
    payloadCodec: '数据编解码器',
    selectPayloadCodec: '选择数据编解码器',
    selectPayloadCodecHelpText: '通过定义数据编解码器，Zysmart 应用服务器可以为您编码和解码设备的二进制数据。',
    deviceSupportsClassC: '设备支持 Class-C',
    deviceSupportsClassCHelpText: '当设备在激活后立即作为 Class-C 设备运行时，请选择此选项。 如果它在更改为 Class-C 时发送 DeviceModeInd mac-command，请不要选择此选项。',
    ClassCConfirmedDownlinkTimeout: 'Class-C 下行确认超时',
    ClassCConfirmedDownlinkTimeoutHelpText: 'Class-C 下行确认传输超时（秒）。',
    tagsTips: '标签可用于存储额外的键/值数据。',
    addTag: '添加标签',
    everySecond: '每秒',
    everyNSecond: '每 {{s}} 秒',
    updateDeviceProfile: '更新设备配置',
    deleteOrganizationTips: '确定要删除此组织吗？',
    organization: '组织',
    application: '应用',
    device: '设备',
    gateway: '网关',
    multicastGroups3: '多播组',
    createMulticastGroup: '创建多播组',
    deleteMulticastGroupTips: '确定要删除此多播组吗？',
    updateMulticastGroup: '更新多播组',
    deviceName: '设备名称',
    deviceEUI: '设备EUI',
    addDevice: '添加设备',
    device2: '设备',
    selectDevice: '选择设备',
    selectDeviceHelpText: "按设备名称或设备EUI搜索。 只能添加与多播组相同的服务配置文件的设备（并且是可见的）。",
    deleteMultGroupDeviceTips: '确定要从多播组中删除此设备吗？ 这不会删除设备本身。',
    multicastGroupName: '多播组名称',
    multicastGroupNameHelpText: '多播组的名称。',
    selectServiceProfile: '选择服务配置',
    selectServiceProfileHelpText: "此多播组将附加到的服务配置文件。请注意，在创建多播组后，不能更改此值。",
    multicastAddress: '多播地址',
    multicastNetworkSessionKey: '多播网络 session key',
    multicastApplicationSessionKey: '多播应用 session key',
    frameCounter: '帧计数器',
    dataRate: '数据速率',
    dataRateHelpText: '传输多播帧时使用的数据速率。 有效值请参阅 LoRaWAN 区域参数规范。',
    frequencyHelpText: '传输多播帧时使用的频率。有效值请参考 LoRaWAN 区域参数规范。',
    multicastGroupType: '多播组类型',
    selectMulticastGroupType: '选择多播组类型',
    selectMulticastGroupTypeHelpText: '多播组类型定义了网络服务器调度多播帧的方式。',
    createGateway: '创建网关',
    gatewayDetails: '网关详情',
    gatewayConfiguration: '网关配置',
    certificate: '证书',
    liveLoRaWANFrames: '实时 LoRaWAN 帧',
    deleteGatewayTips: '确定要删除此网关吗？',
    updateGateway: '更新网关',
    gatewayCertTips: '如果网络需要，网关需要客户端证书才能连接到网络。必须在网关上配置此证书。生成证书后，只能检索一次证书。',
    generateCertificate: '生成证书',
    certificateExpiresAt: '证书到期时间',
    certificateExpiresAtHelpText: '证书在此日期到期。 确保在此到期日期之前为您的网关生成和配置新证书。',
    gatewayCACertHelpText: "CA 证书用于验证服务器的证书。 将此作为文本文件存储在您的网关上，例如 命名为“ca.pem”。",
    gatewayTLSCertHelpText: "将此作为文本文件存储在您的网关上，例如 命名为“cert.pem”",
    gatewayTLSKeyHelpText: "将此作为文本文件存储在您的网关上，例如 命名为“key.pem”",
    framesReceived: '接收帧',
    framesTransmitted: '传输帧',
    altitude: '海拔高度',
    GPSCoordinates: 'GPS坐标',
    lastSeenAt: '最近活跃时间',
    noGatewayDiscoveryTips1: '目前还没有可用的网关发现数据。这可能意味着:',
    noGatewayDiscoveryTips2: "目前还没有发现“ping”",
    noGatewayDiscoveryTips3: 'Zysmart 应用服务器中已禁用网关ping功能',
    noGatewayDiscoveryTips4: "发现“ping”没有被任何其他网关接收到",
    freq: '频率',
    meter: '米',
    RSSI: '信号强度',
    SNR: '信噪比',
    gatewayFrameTips: '下面的帧是网关看到的原始（和加密的）LoRaWAN PHYPayload 帧。 此数据仅用于调试。',
    pause: '暂停',
    resume: '恢复',
    download: '下载',
    clear: '清除',
    notConnectedToWebsocketAPI: '未连接到websocket API',
    FPGAIDHelpText: 'geoloc 集中器板的 FPGA ID。 这仅适用于具有地理定位功能的 v2 网关。 （可选）',
    fineTimestampDecryptionKey: '精细时间戳解密密钥',
    fineTimestampDecryptionKeyHelpText: '精细时间戳AES解密密钥。设置后，Zysmart网络服务器将解密精细的时间戳。这仅适用于具有地理定位功能的v2网关。（可选）',
    configuration2: '配置',
    metadata: '元数据',
    addBoardConfiguration: '添加 board 配置',
    gatewayName: '网关名称',
    gatewayNameHelpText2: '名称只能包含字母、数字和破折号。',
    gatewayDescription: '网关描述',
    selectNetworkServerHelpText: '选择网关将连接到的网络服务器。 当下拉列表中没有可用的网络服务器时，请确保该组织存在服务配置文件。',
    selectServiceProfileHelpText2: '选择必须添加网关的服务配置文件。可用的服务配置文件取决于所选的网络服务器，必须首先选择该服务器。',
    GatewayProfile: '网关配置',
    selectGatewayProfile: '选择网关配置',
    selectGatewayProfileHelpText: '（可选）将网关配置文件分配给网关时，Zysmart 网络服务器将尝试根据网关配置文件更新网关。请注意，这需要一个带有Zysmart Concentrator的网关。',
    gatewayDiscoveryEnabled: '启用网关发现',
    gatewayDiscoveryEnabledHelpText: '启用后（并且 Zysmart 网络服务器配置为启用了网关发现功能），网关将发送定期 ping 以测试同一网络中其他网关的覆盖范围。',
    gatewayAltitude: '网关海拔高度 (米)',
    gatewayAltitudeHelpText: '当网关有板载 GPS 时，该值将在网络收到网关的统计信息时自动设置。',
    gatewayLocation: '网关位置',
    setToCurrentLocation: '设置为当前位置',
    gatewayLocationTips: '将标记拖到网关的位置。 当网关有板载 GPS 时，该值将在网络接收到网关的统计信息时自动设置。',
    gatewayMateDataTips: 'Zysmart 网关桥可以使用元数据来推送有关网关的信息（例如 ip / 主机名、序列号、温度……）。 当收到网关统计信息时，此信息会自动更新。',
    deviceProfile: '设备配置',
    linkMargin: '链接余量',
    battery: '电池',
    updateDevice: '更新设备',
    port: '端口',
    // portHelpText: '请注意，端口值必须大于0。',
    portHelpText: '请注意，端口值必须是1-223。',
    confirmedDownlink: '下行确认',
    base64EncodedString: 'Base64 编码字符串',
    sexadecimalEncodedString: '16进制 编码字符串',
    deviceEncodeTips: '设备必须配置支持编解码器的设备配置文件，该编解码器能够对给定（JSON）数据进行编码。',
    applications3: '应用',
    details: '详情',
    activation: '激活',
    deviceData: '设备数据',
    LoRaWANFrames: 'LoRaWAN 帧',
    keysOTAA: '密钥 (OTAA)',
    HistoryMessages: '历史数据',
    deleteDeviceTips: '确定要删除此设备吗？',
    setDeviceKeys: '设置设备密钥',
    networkKey: '网络密钥 (LoRaWAN 1.1)',
    deviceKeyHelpText: '适用于LoRaWAN 1.1设备。如果您的设备不支持LoRaWAN 1.1，请先更新设备配置。',
    applicationKey: '应用密钥',
    applicationKey2: '应用密钥 (LoRaWAN 1.1)',
    deviceKeyHelpText2: '适用于LoRaWAN 1.0设备。如果您的设备支持LoRaWAN 1.1，请先更新设备配置。',
    addADeviceProfile: '添加设备配置？',
    addADeviceProfileTips1: '所选应用程序无权访问任何设备配置文件。 设备配置文件定义设备的功能和引导参数。 您可以为不同类型的设备创建多个设备配置文件。',
    addADeviceProfileTips2: '要创建设备配置文件吗？',
    createDevice: '创建设备',
    deviceAddress: '设备地址',
    deviceAddressHelpText: '虽然可以输入任何设备地址，但请注意，与LoRaWAN兼容的设备地址由AddrPrefix（源自NetID）+NwkAddr组成。',
    meters: '米',
    networkSessionKey: '网络会话密钥 (LoRaWAN 1.0)',
    applicationSessionKey: '应用会话密钥 (LoRaWAN 1.0)',
    uplinkFrameCounter: '上行帧计数器',
    downlinkFrameCounter: '下行帧计数器 (网络)',
    networkSessionEncryptionKey: '网络会话加密密钥',
    servingNetworkSessionIntegrityKey: '服务网络会话完整性密钥',
    forwardingNetworkSessionIntegrityKey: '转发网络会话完整性密钥',
    applicationSessionKey2: '应用会话密钥',
    downlinkFrameCounter2: '下行帧计数器 (应用)',
    ReActivateDevice: '（重新）激活设备',
    deviceNotActivatedTips: '这个设备还没有被激活。',
    deviceDataHelpTips1: '这些是发布到应用程序的事件（使用JSON封送器）。请参阅数据集成（文档），了解有关将其与应用程序集成的更多信息。',
    deviceDataHelpTips2: '为方便起见，ID值从base64转换为HEX。',
    deviceProfile2: '设备配置',
    enabled: '启用',
    disabled: '禁用',
    status: '状态',
    state: '状态',
    enqueueDownlinkPayload: '下行数据入队',
    enqueuePayload: '数据入队',
    base64Encoded: 'Base64 编码',
    sexadecimalEncoded: '16进制 编码',
    downlinkQueue: '下行队列',
    confirmed: '确认',
    base64EncodedPayload: 'Base64 编码数据',
    
    hexEncodedPayload: '16进制 编码数据',
    pending: '待办',
    createdTime:"创建时间",
    updatedTime:"修改时间",
    flushDeviceQueueTips: '确定要刷新设备队列吗？',
    variables: '变量',
    deviceNameHelpText: '名称只能包含字母、数字和破折号。',
    deviceDescription: '设备描述',
    disableFrameCounterValidation: '禁用帧计数器验证',
    disableFrameCounterValidationHelpText: '请注意，禁用帧计数器验证会损害安全性，因为它使人们能够执行重放攻击。',
    deviceIsDisabled: '设备禁用',
    deviceIsDisabledHelpText: 'Zysmart 网络服务器将忽略接收到的上行帧和来自禁用设备的加入请求。',
    deviceVariablesTips: '变量可用于替换示例集成中的占位符，例如 如果集成需要配置特定于设备的令牌。',
    addVariable: '添加变量',
    deviceTagsTips: '标签可用作设备过滤器，并在事件中作为附加元数据公开以进行聚合。',
    updateApplication: '更新应用',
    createApplication: '创建应用',
    applicationConfiguration: '应用配置',
    integrations: '集成',
    deleteAppTips: '确定要删除此应用程序吗？这也将删除此应用程序中的所有设备。',
    applicationName: '应用名称',
    applicationNameHelpText: '名称只能包含字母、数字和破折号。',
    applicationDescription: '应用描述',
    selectServiceProfileHelpText3: "此应用程序将附加到的服务配置文件。请注意，在创建应用程序后，不能更改此值。",
    importantNote: '重要提示',
    selectPayloadCodecHelpText2: 'payload 字段已移至设备配置文件。 为了向后兼容和迁移，现有的编解码器设置仍然可见。 设备配置文件上的编解码器设置优先于应用程序编解码器设置。',
    appPayloadCodecTips: '注意: 数据编解码器字段已移至设备配置文件。',
    toggleMSBOrLSBTips: '切换输入的字节顺序。有些设备使用LSB。',
    generateRandomKey: '生成随机密钥。',
    generateRandomDeviceAddress: '生成随机设备地址。',
    generateRandomID: '生成随机设备地址 ID。',
    value: '值',
    addADeviceProfileTips3: '所选组织还没有设备配置文件。 设备配置文件定义设备的功能和引导参数。 您可以为不同类型的设备创建多个设备配置文件。',
    gatewayInfo: '网关信息',
    gatewayName2: '网关名称',
    gatewayType: '网关类型',
    frequencyRegion: '频率区域',
    subnetType: '子网类型',
    pktfwd: 'Pktfwd',
    onlineStatus: '在线状态',
    gatewayDownlinkTXPower: '网关下行发射功率',
  
    TXPower: '发射功率',
    HTTPPushInterface: 'HTTP 推送接口',
    GPSTimeSyncStatus: 'GPS 时间同步状态',
    createTime: '创建时间',
    lastFrameData: '网关最新一帧上行数据',
    msgType: '消息类型',
    freq2: '频率 (MHz)',
    RSSI2: '信号强度 (dBm)',
    SNR2: '信噪比 (dB)',
    SF: '扩频因子',
    BW: '带宽 (KHz)',
    LoRaModulate: 'LoRa 调制',
    GPSTime: 'GPS 时间',
    lastSeen2: '最后通信时间',
    gatewayNetworkStatus: '网关网络状态',
    networkType: '网络类型',
    WANIP: '公网 IP',
    LANIP: '内网 IP',
    LTESignalStrength: 'LTE 信号强度',
    latency: '网络延迟',
    lastUpdated: '更新时间',
    frequencyScheduleSwitchingState: '频率计划切换状态',
    currentFrequencyPlan: '当前频率计划',
    targetFrequencyPlan: '目标频率计划',
    dataDeliveryInterval: '数据下发间隔',
    switchTime: '切换时间',
    responseState: '响应状态',
    dataIssuedCount: '数据下发次数',
    nextDataReleaseTime: '下次数据下发时间',
    gatewaySystemStatus: '网关系统状态',
    subModel: '子型号',
    firmwareDate: '固件日期',
    firmwareVersion: '固件版本',
    hardwareVersion: '硬件版本',
    kernelVersion: '内核版本',
    SDKVersion: 'SDK 版本',
    PKTFWDVersion: 'Pktfwd 版本',
    PKTFWDStatus: 'Pktfwd 状态',
    gatewayRunningTime: '网关运行时长',
    SDKRunningTime: 'SDK 运行时长',
    CPUUsageRate: 'CPU 使用率',
    memoryUsageRate: '内存使用率',
    rootUsageRate: '根分区使用率',
    logUsageRate: '日志分区使用率',
    temperature: '温度',
    latitude: '纬度',
    longitude: '经度',
    fCntUp: '上行帧计数器',
    fCntDown: '下行帧计数器',
    fPort: '节点端口',
    copy: '复制',
    deviceName2: '设备名称',
    activationType: '入网类型',
    supportClassB: '支持ClassB类',
    supportClassC: '支持ClassC类',
    deviceCreateTime: '设备创建时间',
    macVersion: '协议版本',
    DR: '数据速率',
    ADR: 'ADR标志位',
    latestFrameData: '最新一帧数据',
    communicationGatewayList: '通信网关列表',
    enabled2: '已开启',
    disabled2: '未开启',
    online: '在线',
    offline: '离线',
    default: '默认',
    custom: '自定义',
    synced: '已同步',
    unsynced: '未同步',
    hasResponse: '已响应',
    notResponding: '未响应',
    StartingTime:'开始时间',
    EndTime:'结束时间',
    Check:'查看',
    AppHistory:'应用层历史数据',
    MacHistory:'协议层历史数据',
    DownlinkMessageQueue:'下行消息列表',
    TransmissionDirection:'传输方向',
    GatewayId:'网关ID',
    gatewayDownlinkTXPowerHelpText: '发射功率只能允许0-27，设置0系统将会获取默认设置。',
    GatewayHistoricalMessage:'网关历史消息',
    GatewayStatusHistory:'网关状态历史',
    ConnectionStatus:'连接状态',
    ConnectionType:'连接方式',
    All:'全部',
    DisplayUpLineStatus:'显示上线状态',
    DisplayDownLineStatus:'显示下线状态',
    AuthorizationManagement:'授权管理',
    deleteAuthorizationTips: "确定要删除此授权吗？",
    createAuthorization: '创建或更新LIcense',
    AuthorizationLicenseString:'授权License字符串',
    Expiredate:'到期时间',
    Expired:'过期',
    Normal:'正常',
    selectFrequencyBand: '选择频段',
    selectFrequencyBandHelpText: '请选择频段',
    FrequencyBands:'频段设置',
    Online1:'上线',
    FrequencyBand:'频段',
    CreateOrUpdate:'创建或更新',
    selectChannel: '选择通道 (请先选择频段)',
    selectBandChHelpText: '请先选择频段',
    ChannelSettings:'通道设置',
    PendingCommand:'待执行命令',
    Channel:'通道',
    DeviceQuantity:'设备数量',
    Integrated:'集成',
    OnlineDocumentation:'在线文档',
    APIKeyDetails:'密钥详情',
    SystemSettings:'系统设置',
    UpdateSystemSettings:'更新系统设置',
    SiteLanguage:'站点语言',
    SiteLanguageHelpTex:'必须选中一种语言',
    Display:'显示',
    SiteDocumentationHelpTex:'顶部菜单显示站点文档',
    SiteName:'站点名称',
    SiteDocumentation:'站点文档',
    LogoHelpTex: 'logo 格式要求PNG 485*96',
    FaviconHelpTex:'favicon 格式要求PNG 32*32',
    AuthorizationHelpText:'权限配置加载中......',
    Import:'导入',
    DeviceBatchImport:'设备批量导入',
    DownloadTemplate:'下载模板',
    UplinkQueue:'上行队列',
    SendTime:'发送时间',
    BatteryStatus:'电池状态',
    PowerType:'供电方式',
    BatteryPowered:'电池供电',
    ExternalPowerSupply:'外部供电',
    BatteryPoweredAndExternalPowerSupply:'电池供电+外部供电',
    Unknown:'未知',
    DisableAdr:'禁用 Adr',
    rx1DelayHelpText: '请注意，数值值必须是1-15。',
    Command:'命令',
    ExecuteCommand:'执行命令',
    PleaseSelectCmmand :'请先选择命令',
    CommandDetails:'命令详情',
    PleaseSelectCommandDetails :'请先选择命令详情',
    WaitForExecuteCommand:'待执行命令',
    ExtraChannels:'额外信道',
    CreateExtraChannels:'创建额外信道',
    ExtraChannelsBandNameHelpText: '频段是唯一的，重复的频段不可创建。',
    selectServiceProfileHelpText4: "此应用程序将附加到额外频段的配置文件。请注意，在创建额外频段后，不能更改此值。此值不能为空。",
    Subchannel:'子信道',
    CreateSubchannel:'创建子信道',
    deleteExtraChannelsItemTips: '确定要删除此子信道吗？',
    renovate:'刷新',
    open:'开启',
    update:'更新',
    extraChannelsHelpText: '输入的信道必须是在全局进行初始化过的， 用逗号分隔通道，例如 867100000,867300000,867500000 默认通道不得包含在此列表中。',
    LoadingDialogHelpText:'加载中......',
    createExtraChannelItemTips: '额外信道最多只能添加5个',
    deleteExtraChannelItTips:'确定要删除此额外信道吗?',
    Received:'接收',
    errType:'错误类型',
    DownlinkTXAck:'下行ACK',
    UpDecodeData:'上行解码数据',
    TaskSeconds:'任务秒数',
    RelevanceId:'相关ID',
    TaskType:'任务类型',
    TaskResult:'任务结果',
    TaskWait:'待处理',
    TaskInProgress:'处理中',
    TaskComplete:'处理成功',
    TaskFail:'处理失败',
    UpDecodeData:"上行解析数据",
    CreateTask:'创建任务',
    CheckTask:'查看任务',
    DeviceEui:'设备EUI',
    GatewayEui:'网关Eui',




   
}