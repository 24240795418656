import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import FormControl from '@material-ui/core/FormControl';
import Form from "../../components/Form";
import FormLabel from "@material-ui/core/FormLabel";
import AutocompleteSelect from "../../components/AutocompleteSelect";
import GatewayStore from "../../stores/GatewayStore";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';



import FormHelperText from '@material-ui/core/FormHelperText';

const styles = {
    input: {
          display: 'none',
        },
    language:{
        display : 'none'
      },
    language1:{
      display : 'block'
    }
  }


class GatewayExecuteCommandForm extends Component {
    constructor() {
        super();
    
        this.state = {
          MqttCommand: {},
          tab:0,
        };
        this.MqttOnChange = this.MqttOnChange.bind(this);
        this.HttpOnChange = this.HttpOnChange.bind(this);
    }
    onTabChange = (e, v) => {
      this.setState({
        tab: v,
      });
    }
    MqttOnChange(e) {
        console.log("===",this.props.object.id)
        let MqttCommand = this.state.MqttCommand;   

        if (this.props.object.id != "" ) {     
          MqttCommand.gwId=this.props.object.id
        }         

        if (e.target.id === "action" ) {    
          MqttCommand.action = e.target.value;    
        }

        if (e.target.id === "do" ) {    
          var p = new Object()
          p.do = e.target.value;    
          MqttCommand.params=p
        }
        this.setState({
          MqttCommand: MqttCommand,
        });
        
      }

      HttpOnChange(e) {

      
        if (e.target.value!=""){
          this.props.object.command=e.target.value
        }
      }

      onSubmit = () => {
        if (this.state.tab==0){
          GatewayStore.createMqttExecuteCommand(this.state.MqttCommand, resp => {
            this.setState({
              MqttCommand: {},
            });
            
          });
        }else if (this.state.tab==1){  
          GatewayStore.gatewayAddCommand(this.props.object.id,this.props.object.command, resp => {


          });
        }
        
      }
    
    GetMqttCommandType = (search, callbackFunc) => {
        const marshalerOptions = [
          {value: "restart", label: "restart"},
          {value: "remote", label: "remote"},
          {value: "report", label: "report"},
        ];
    
        callbackFunc(marshalerOptions);
      }

      GetHttpCommandType = (search, callbackFunc) => {
        const marshalerOptions = [
          {value: "nothing", label: "nothing"},
          {value: "restart", label: "restart"},
          {value: "remote", label: "remote"},
        ];
    
        callbackFunc(marshalerOptions);
      }

      GetDo = (search, callbackFunc) => {
        const marshalerOptions = [
          {value: "start", label: "start"},
          {value: "stop", label: "stop"},
        ];
    
        callbackFunc(marshalerOptions);
      }
    render() {
   
        const { t } =this.props
    
    
    
        return(
            <Form
              submitLabel={this.props.submitLabel}
              onSubmit={this.onSubmit}
              disabled={this.props.disabled}
            >
              <Tabs value={this.state.tab} onChange={this.onTabChange} indicatorColor="primary">
                <Tab label={t('MQTT')} />
                <Tab label={t('HTTP')} />
              </Tabs>
              {this.state.tab === 0 && <div>
                <FormControl fullWidth margin="normal">
                <FormLabel required>{t('Command')}</FormLabel>
                <AutocompleteSelect
                    id="action"
                    label="Select Command"
                    value={this.state.MqttCommand.action }
                    onChange={this.MqttOnChange}
                    getOptions={this.GetMqttCommandType}
                    required
                />
                <FormHelperText>{t('PleaseSelectCmmand')}</FormHelperText>
                </FormControl>


                <FormControl fullWidth margin="normal" className={ this.state.MqttCommand.action!="remote" ? this.props.classes.language : this.props.classes.language1}>
                <FormLabel required>{t('CommandDetails')}</FormLabel>
                <AutocompleteSelect
                    id="do"
                    label="Select CommandDetails"
                    value={this.state.MqttCommand.do }
                    onChange={this.MqttOnChange}
                    getOptions={this.GetDo}
                    
                />
                <FormHelperText>{t('PleaseSelectCommandDetails')}</FormHelperText>
                </FormControl>
                
              </div>}   
              {this.state.tab === 1 && <div>
                <FormControl fullWidth margin="normal">
                <FormLabel required>{t('WaitForExecuteCommand')}</FormLabel>
                <AutocompleteSelect
                    id="command"
                    label="Select Command"
                   value={this.props.object.command }
                    onChange={this.HttpOnChange}
                    getOptions={this.GetHttpCommandType}
                    required
                />
                <FormHelperText>{t('PleaseSelectCmmand')}</FormHelperText>
                </FormControl>

              </div>} 
            </Form>
            
      
          );
      }
    
}
export default withStyles(styles)(withTranslation()(GatewayExecuteCommandForm));