import React from "react";

import { withStyles } from "@material-ui/core/styles";
import TextField from '@material-ui/core/TextField';
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import { withTranslation } from "react-i18next";

import FormComponent from "../../classes/FormComponent";
import AESKeyField from "../../components/AESKeyField";
import DevAddrField from "../../components/DevAddrField";
import Form from "../../components/Form";
import AutocompleteSelect from "../../components/AutocompleteSelect";
import ServiceProfileStore from "../../stores/ServiceProfileStore";
import theme from "../../theme";


const styles = {
  formLabel: {
    fontSize: 12,
  },
  link: {
    color: theme.palette.primary.main,
  },
};


class MulticastGroupForm extends FormComponent {
  constructor() {
    super();
    this.getServiceProfileOption = this.getServiceProfileOption.bind(this);
    this.getServiceProfileOptions = this.getServiceProfileOptions.bind(this);
    this.getPingSlotPeriodOptions = this.getPingSlotPeriodOptions.bind(this);
  }

  getServiceProfileOption(id, callbackFunc) {
    ServiceProfileStore.get(id, resp => {
      callbackFunc({label: resp.serviceProfile.name, value: resp.serviceProfile.id});
    });
  }

  getServiceProfileOptions(search, callbackFunc) {
    ServiceProfileStore.list(this.props.match.params.organizationID, 0, 999, 0, resp => {
      const options = resp.result.map((sp, i) => {return {label: sp.name, value: sp.id}});
      callbackFunc(options);
    });
  }

  getRandomKey(len) {
    let cryptoObj = window.crypto || window.msCrypto;
    let b = new Uint8Array(len);
    cryptoObj.getRandomValues(b);

    return Buffer.from(b).toString('hex');
  }

  getRandomMcAddr = (cb) => {
    cb(this.getRandomKey(4));
  }

  getRandomSessionKey = (cb) => {
    cb(this.getRandomKey(16));
  }


  getGroupTypeOptions(search, callbackFunc) {
    const options = [
      {value: "CLASS_B", label: "Class-B"},
      {value: "CLASS_C", label: "Class-C"},
    ];

    callbackFunc(options);
  }

  getPingSlotPeriodOptions(search, callbackFunc) {
    const pingSlotPeriodOptions = [
      {value: 32 * 1, label: this.props.t('everySecond')},
      {value: 32 * 2, label: this.props.t('everyNSecond', {s: 2})},
      {value: 32 * 4, label: this.props.t('everyNSecond', {s: 4})},
      {value: 32 * 8, label: this.props.t('everyNSecond', {s: 8})},
      {value: 32 * 16, label: this.props.t('everyNSecond', {s: 16})},
      {value: 32 * 32, label: this.props.t('everyNSecond', {s: 32})},
      {value: 32 * 64, label: this.props.t('everyNSecond', {s: 64})},
      {value: 32 * 128, label: this.props.t('everyNSecond', {s: 128})},
    ];

    callbackFunc(pingSlotPeriodOptions);
  }

  render() {
    if (this.state.object === undefined) {
      return null;
    }

    const { t } = this.props

    return(
      <Form
        submitLabel={this.props.submitLabel}
        onSubmit={this.onSubmit}
      >
        <TextField
          id="name"
          label={t('multicastGroupName')}
          margin="normal"
          value={this.state.object.name || ""}
          onChange={this.onChange}
          helperText={t('multicastGroupNameHelpText')}
          fullWidth
          required
        />
        {!this.props.update && <FormControl fullWidth margin="normal">
          <FormLabel className={this.props.classes.formLabel} required>{t('serviceProfiles2')}</FormLabel> 
          <AutocompleteSelect
            id="serviceProfileID"
            label={t('selectServiceProfile')}
            value={this.state.object.serviceProfileID || ""}
            onChange={this.onChange}
            getOption={this.getServiceProfileOption}
            getOptions={this.getServiceProfileOptions}
            margin="none"
            required
          />
          <FormHelperText>
            {t('selectServiceProfileHelpText')}
          </FormHelperText>
        </FormControl>}
        <DevAddrField
          id="mcAddr"
          label={t('multicastAddress')}
          margin="normal"
          value={this.state.object.mcAddr || ""}
          onChange={this.onChange}
          disabled={this.props.disabled}
          randomFunc={this.getRandomMcAddr}
          fullWidth
          required
          random
        />
        <AESKeyField
          id="mcNwkSKey"
          label={t('multicastNetworkSessionKey')}
          margin="normal"
          value={this.state.object.mcNwkSKey || ""}
          onChange={this.onChange}
          disabled={this.props.disabled}
          fullWidth
          required
          random
        />
        <AESKeyField
          id="mcAppSKey"
          label={t('multicastApplicationSessionKey')}
          margin="normal"
          value={this.state.object.mcAppSKey || ""}
          onChange={this.onChange}
          disabled={this.props.disabled}
          fullWidth
          required
          random
        />
        <TextField
          id="fCnt"
          label={t('frameCounter')}
          margin="normal"
          type="number"
          value={this.state.object.fCnt || 0}
          onChange={this.onChange}
          required
          fullWidth
        />
        <TextField
          id="dr"
          label={t('dataRate')}
          helperText={t('dataRateHelpText')}
          margin="normal"
          type="number"
          value={this.state.object.dr || 0}
          onChange={this.onChange}
          required
          fullWidth
        />
        <TextField
          id="frequency"
          label={t('frequency')}
          helperText={t('frequencyHelpText')}
          margin="normal"
          type="number"
          value={this.state.object.frequency || 0}
          onChange={this.onChange}
          required
          fullWidth
        />
        <FormControl fullWidth margin="normal">
          <FormLabel className={this.props.classes.formLabel} required>{t('multicastGroupType')}</FormLabel>
          <AutocompleteSelect
            id="groupType"
            label={t('selectMulticastGroupType')}
            value={this.state.object.groupType || ""}
            onChange={this.onChange}
            getOptions={this.getGroupTypeOptions}
            required
          />
          <FormHelperText>
            {t('selectMulticastGroupTypeHelpText')}
          </FormHelperText>
        </FormControl>
        {this.state.object.groupType === "CLASS_B" && <FormControl fullWidth margin="normal">
          <FormLabel className={this.props.classes.formLabel} required>{t('ClassBPingSlotPeriodicity')}</FormLabel>
          <AutocompleteSelect
            id="pingSlotPeriod"
            label={t('selectClassBPingSlotPeriodicity')}
            value={this.state.object.pingSlotPeriod || ""}
            onChange={this.onChange}
            getOptions={this.getPingSlotPeriodOptions}
            required
          />
          <FormHelperText>{t('ClassBPingSlotPeriodicityHelpText')}</FormHelperText>
        </FormControl>}
      </Form>
    );
  }
}

export default withStyles(styles)(withTranslation()(MulticastGroupForm));
