import React from "react";

import { withStyles } from "@material-ui/core/styles";
import TextField from '@material-ui/core/TextField';
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Typography from '@material-ui/core/Typography';

import {Controlled as CodeMirror} from "react-codemirror2";
import "codemirror/mode/javascript/javascript";
import { withTranslation } from "react-i18next";

import FormComponent from "../../classes/FormComponent";
import Form from "../../components/Form";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const styles = {
  codeMirror: {
    zIndex: 1,
  },
  formLabel: {
    fontSize: 12,
  },
};


class ExtraChannelItemForm extends FormComponent {
  constructor() {
    super();
    this.onCodeChange = this.onCodeChange.bind(this);
  }
  onCodeChange(field, editor, data, newCode) {
    let object = this.state.object;
    object[field] = newCode;
    this.setState({
      object: object,
    });
  }

  render() {
    if (this.state.object === undefined) {
      return(<div></div>);
    }
    const { t } = this.props

    return(
      <Form
        submitLabel={this.props.submitLabel}
        onSubmit={this.onSubmit}
      >
          <TextField
            id="frequency"
            label={"frequency"}
            type="number"
            margin="normal"
            value={this.state.object.frequency || 0}
            onChange={this.onChange}
            required
            fullWidth
          />
           <TextField
            id="minDr"
            label={"minDr"}
            type="number"
            margin="normal"
            value={this.state.object.minDr || 0}
            onChange={this.onChange}
            required
            fullWidth
          />
           <TextField
            id="maxDr"
            label={"maxDr"}
            type="number"
            margin="normal"
            value={this.state.object.maxDr || 0}
            onChange={this.onChange}
            required
            fullWidth
          />
          <FormControl fullWidth margin="normal">
            <FormGroup>
              <FormControlLabel
                label={t('disabled')}
                control={
                  <Checkbox
                    id="isDisabled"
                    checked={!!this.state.object.isDisabled}
                    onChange={this.onChange}
                    color="primary"
                  />
                }
              />
            </FormGroup>
          </FormControl>

      </Form>
    );
  }
}
export default withStyles(styles)(withTranslation()(ExtraChannelItemForm));
