import React from "react";

import { withStyles } from "@material-ui/core/styles";
import TextField from '@material-ui/core/TextField';
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { withTranslation } from "react-i18next";

import FormComponent from "../../classes/FormComponent";
import Form from "../../components/Form";
import KVForm from "../../components/KVForm";
import EUI64Field from "../../components/EUI64Field";
import AutocompleteSelect from "../../components/AutocompleteSelect";
import DeviceProfileStore from "../../stores/DeviceProfileStore";
//import jsondata from '../../stores/FrequencyBand';
import InternalStore from "../../stores/InternalStore";


const styles = {
  formLabel: {
    fontSize: 12,
  },
  formwidth: {
    width: "50%",
  }
};



class DeviceForm extends FormComponent {
  constructor() {
    super();
    this.getDeviceProfileOption = this.getDeviceProfileOption.bind(this);
    this.getDeviceProfileOptions = this.getDeviceProfileOptions.bind(this);
    this.frequencyBandlist = this.frequencyBandlist.bind(this);
    //this.banchlist = this.banchlist.bind(this);
    

    

    this.state = {
      tab: 0,
      variables: [],
      tags: [],
      chlist:[],
      recordband:"",
      bands:[],
    };
  }

  componentDidMount() {
    super.componentDidMount();

    this.setKVArrays(this.props.object || {});
  }

  componentDidUpdate(prevProps) {
    super.componentDidUpdate(prevProps);

    if (prevProps.object !== this.props.object) {
      this.setKVArrays(this.props.object || {});
    }
  }

  setKVArrays = (props) => {
    let variables = [];
    let tags = [];

    if (props.variables !== undefined) {
      for (let key in props.variables) {
        variables.push({key: key, value: props.variables[key]});
      }
    }

    if (props.tags !== undefined) {
      for (let key in props.tags) {
        tags.push({key: key, value: props.tags[key]});
      }
    }

    this.setState({
      variables: variables,
      tags: tags,
    });
  }

  getDeviceProfileOption(id, callbackFunc) {
    DeviceProfileStore.get(id, resp => {
      callbackFunc({label: resp.deviceProfile.name, value: resp.deviceProfile.id});
    });
  }

  getDeviceProfileOptions(search, callbackFunc) {
    DeviceProfileStore.list(0, this.props.match.params.applicationID, 999, 0, resp => {
      const options = resp.result.map((dp, i) => {return {label: dp.name, value: dp.id}});
      callbackFunc(options);
    });
  }

  onTabChange = (e, v) => {
    this.setState({
      tab: v,
    });
  }

  frequencyBandlist = (search, callbackFunc) => {
    var num =0;
    InternalStore.getBandNameList(resp => {
        for (var i=0;i<resp.result.length;i++){
          var M =new Map ();
          M["label"]=resp.result[i].bandName
          M["value"]=resp.result[i].bandName
         this.state.bands[num]=M
          num++;
        } 
        callbackFunc(this.state.bands)
    });
    if (this.state.object.frequencyBand !=""&&this.state.object.frequencyBand != undefined){
      if (this.state.recordband!=this.state.object.frequencyBand && this.state.recordband!=""){
        this.setState({
          recordband: this.state.object.frequencyBand,
        });
        this.state.object.chBand=""
      }
    }
  }

  // banchlist = (search, callbackFunc) => {
  //   this.setState({
  //     recordband:this.state.object.frequencyBand,
     
  //   });
  //   if (this.state.object.frequencyBand != "" && this.state.object.frequencyBand != undefined) {
  //     const options1 = jsondata.list.map((sp, i) => {
  //       if (this.state.object.frequencyBand == sp.name) {
  //         const chlist = sp.chlist.map((n, v) => {
  //           return { label: n, value: n }
  //         })
  //         this.setState({

  //           chlist: chlist,
  //         });
  //         callbackFunc(chlist);
  //       }
  //     });
  //   }
  // }
  render() {
    if (this.state.object === undefined) {
      return null;
    }

    const { t } = this.props

    const variables = this.state.variables.map((obj, i) => <KVForm key={i} index={i} object={obj} onChange={this.onChangeKV("variables")} onDelete={this.onDeleteKV("variables")} />);
    const tags = this.state.tags.map((obj, i) => <KVForm key={i} index={i} object={obj} onChange={this.onChangeKV("tags")} onDelete={this.onDeleteKV("tags")} />);

    return(
      <Form
        submitLabel={this.props.submitLabel}
        onSubmit={this.onSubmit}
        disabled={this.props.disabled}
      >
        <Tabs value={this.state.tab} onChange={this.onTabChange} indicatorColor="primary">
          <Tab label={t('general')} />
          <Tab label={t('variables')} />
          
          <Tab label={t('tags')} />
        </Tabs>

        {this.state.tab === 0 && <div>
          <TextField
            id="name"
            label={t('deviceName')}
            helperText={t('deviceNameHelpText')}
            margin="normal"
            value={this.state.object.name || ""}
            onChange={this.onChange}
            inputProps={{
              pattern: "[\\w-]+",
            }}
            fullWidth
            required
          />
          <TextField
            id="description"
            label={t('deviceDescription')}
            margin="normal"
            value={this.state.object.description || ""}
            onChange={this.onChange}
            fullWidth
            required
          />
          {!this.props.update && <EUI64Field
            margin="normal"
            id="devEUI"
            label={t('deviceEUI')}
            onChange={this.onChange}
            value={this.state.object.devEUI || ""}
            fullWidth
            required
            random
          />}
          <FormControl fullWidth margin="normal" >
            <FormLabel className={this.props.classes.formLabel} required>{t('FrequencyBands')}</FormLabel>
            <AutocompleteSelect
              id="frequencyBand"
              label={t('selectFrequencyBand')}
              value={this.state.object.frequencyBand || ""}
              triggerReload={this.state.object.frequencyBand || ""}
              onChange={this.onChange}
              getOptions={this.frequencyBandlist}
              // clearable={true}
            />

            <FormHelperText>
              {t('selectFrequencyBandHelpText')}
            </FormHelperText>
          </FormControl>
          {/* <FormControl fullWidth margin="normal" className={this.props.classes.formwidth}>
            <FormLabel className={this.props.classes.formLabel} required></FormLabel>
            <AutocompleteSelect
              id="chBand"
              label={t('selectChannel')}
              value={this.state.object.chBand|| ""}
              triggerReload={this.state.object.chBand|| ""}
              onChange={this.onChange}
              getOptions={this.banchlist}
              clearable={true}
            />
          </FormControl> */}

          <FormControl fullWidth margin="normal">
            <FormLabel className={this.props.classes.formLabel} required>{t('deviceProfile2')}</FormLabel>
            <AutocompleteSelect
              id="deviceProfileID"
              label={t('deviceProfile2')}
              value={this.state.object.deviceProfileID}
              onChange={this.onChange}
              getOption={this.getDeviceProfileOption}
              getOptions={this.getDeviceProfileOptions}
            />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <FormGroup>
              <FormControlLabel
                label={t('disableFrameCounterValidation')}
                control={
                  <Checkbox
                    id="skipFCntCheck"
                    checked={!!this.state.object.skipFCntCheck}
                    onChange={this.onChange}
                    color="primary"
                  />
                }
              />
            </FormGroup>
            <FormHelperText>
              {t('disableFrameCounterValidationHelpText')}
            </FormHelperText>
          </FormControl>
          <FormControl fullWidth margin="normal">
            <FormGroup>
              <FormControlLabel
                label={t('deviceIsDisabled')}
                control={
                  <Checkbox
                    id="isDisabled"
                    checked={!!this.state.object.isDisabled}
                    onChange={this.onChange}
                    color="primary"
                  />
                }
              />
            </FormGroup>
            <FormHelperText>
              {t('deviceIsDisabledHelpText')}
            </FormHelperText>
          </FormControl>
        </div>}

        {this.state.tab === 1 && <div>
          <FormControl fullWidth margin="normal">
            <Typography variant="body1">
              {t('deviceVariablesTips')}
            </Typography>
            {variables}
           
          </FormControl>
          <Button variant="outlined" onClick={this.addKV("variables")}>{t('addVariable')}</Button>
        </div>}

        {this.state.tab === 2 && <div>
          <FormControl fullWidth margin="normal">
            <Typography variant="body1">
              {t('deviceTagsTips')}
            </Typography>
            {tags}
          </FormControl>
          <Button variant="outlined" onClick={this.addKV("tags")}>{t('addTag')}</Button>
        </div>}
      </Form>
    );
  }
}

export default withStyles(styles)(withTranslation()(DeviceForm));
