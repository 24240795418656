
import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import Chip from '@material-ui/core/Chip';
import theme from "../../theme";
import MenuItem from '@material-ui/core/MenuItem';

import { withStyles } from "@material-ui/core/styles";
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import {Controlled as CodeMirror} from "react-codemirror2";
import "codemirror/mode/javascript/javascript";
import { withTranslation } from "react-i18next";

import FormComponent from "../../classes/FormComponent";
import Form from "../../components/Form";
import KVForm from "../../components/KVForm";
import DurationField from "../../components/DurationField";
import AutocompleteSelect from "../../components/AutocompleteSelect";
import NetworkServerStore from "../../stores/NetworkServerStore";
import { FormLabel } from "../../../node_modules/@material-ui/core";
// import jsondata from '../../stores/FrequencyBand';
import InternalStore from "../../stores/InternalStore";
import FormGroup from "@material-ui/core/FormGroup";
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';



const styles = {
  formLabel: {
    fontSize: 12,
  },
  codeMirror: {
    zIndex: 1,
  },
  formControl: {
   margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  }
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};



class DeviceProfileForm extends FormComponent {

  constructor() {
    super();
    this.state = {
      tab: 0,
      tags: [],
      bands:[],
      channel_list: [],
      frequencys:[],
      channelisnull:true
    };

    this.onTabChange = this.onTabChange.bind(this);
    this.getNetworkServerOptions = this.getNetworkServerOptions.bind(this);
    this.getMACVersionOptions = this.getMACVersionOptions.bind(this);
    this.getRegParamsOptions = this.getRegParamsOptions.bind(this);
    this.getPingSlotPeriodOptions = this.getPingSlotPeriodOptions.bind(this);
    this.getPayloadCodecOptions = this.getPayloadCodecOptions.bind(this);
    this.onCodeChange = this.onCodeChange.bind(this);
    this.getStyles = this.getStyles.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
 
  componentDidMount() {
    super.componentDidMount();
    this.setKVArray(this.props.object || {});
    if (this.props.object !== undefined && this.props.object.channels !== undefined && this.props.object.channelsStr === undefined) {
      let object = this.props.object;
      object.channelsStr = object.channels.join(", ");
      this.setState({
        object: object,
      });
    }
    if (this.props.object !== undefined && this.props.object.extraChannels !== undefined ) {
      let object = this.props.object;
     // object.extra_channelsStr = object.extraChannels.join(", ");
      this.setState({
        object: object,
        channel_list:this.props.object.extraChannels,
      });
    }  
    if (this.props.object !== undefined && this.props.object.rfRegion!== undefined){

 
      InternalStore.GetExtraChannelItemlistByBandName(this.props.object.rfRegion, resp => {

        let listone=[]
        for (let index = 0; index < resp.result.length; index++){
          console.log(resp.result[index]);

          listone[index] = resp.result[index].frequency; // 将数组的元素设置为它的自己
        } 

        this.setState({
          frequencys: listone,
        });
        if (listone.length>0){
          this.setState({
            channelisnull: false,
          });
        }

        
      });
    }

  }


  handleChange (event){
    let object = this.state.object;
    object.extraChannels = event.target.value;
    this.setState({
      object: object,
      channel_list:event.target.value,
    });
  }


  componentDidUpdate(prevProps) {
    super.componentDidUpdate(prevProps);

    if (prevProps.object !== this.props.object) {
      this.setKVArray(this.props.object || {});
    }
  }

  getNetworkServerOptions(search, callbackFunc) {
    NetworkServerStore.list(this.props.match.params.organizationID, 999, 0, resp => {
      const options = resp.result.map((ns, i) => {return {label: ns.name, value: ns.id}});
      callbackFunc(options);
    });
  }

  getMACVersionOptions(search, callbackFunc) {
    const macVersionOptions = [
      {value: "1.0.0", label: "1.0.0"},
      {value: "1.0.1", label: "1.0.1"},
      {value: "1.0.2", label: "1.0.2"},
      {value: "1.0.3", label: "1.0.3"},
      {value: "1.1.0", label: "1.1.0"},
    ];

    callbackFunc(macVersionOptions);
  }

  getRegParamsOptions(search, callbackFunc) {
    const regParamsOptions = [
      {value: "A", label: "A"},
      {value: "B", label: "B"},
    ];

    callbackFunc(regParamsOptions);
  }

  getPingSlotPeriodOptions(search, callbackFunc) {
    const pingSlotPeriodOptions = [
      {value: 32 * 1, label:  this.props.t('everySecond')},
      {value: 32 * 2, label: this.props.t('everyNSecond', {s: 2})},
      {value: 32 * 4, label: this.props.t('everyNSecond', {s: 4})},
      {value: 32 * 8, label: this.props.t('everyNSecond', {s: 8})},
      {value: 32 * 16, label: this.props.t('everyNSecond', {s: 16})},
      {value: 32 * 32, label: this.props.t('everyNSecond', {s: 32})},
      {value: 32 * 64, label: this.props.t('everyNSecond', {s: 64})},
      {value: 32 * 128, label: this.props.t('everyNSecond', {s: 128})},
    ];

    callbackFunc(pingSlotPeriodOptions);
  }

  getPayloadCodecOptions(search, callbackFunc) {
    const payloadCodecOptions = [
      {value: "", label: "None"},
      {value: "CAYENNE_LPP", label: "Cayenne LPP"},
      {value: "CUSTOM_JS", label: "Custom JavaScript codec functions"},
    ];

    callbackFunc(payloadCodecOptions);
  }

  getADRAlgorithmsOptions = (search, callbackFunc) => {
    if (this.state.object.networkServerID === undefined) {
      callbackFunc([]);
    } else {
      NetworkServerStore.getADRAlgorithms(this.state.object.networkServerID, resp => {
        const options = resp.adrAlgorithms.map((adr, i) => {return {value: adr.id, label: adr.name}});
        callbackFunc(options);
      })
    }
  }

  onCodeChange(field, editor, data, newCode) {
    let object = this.state.object;
    object[field] = newCode;
    this.setState({
      object: object,
    });
  }

  onTabChange(e, v) {
    this.setState({
      tab: v,
    });
  }

  onChange(e) {
    super.onChange(e);
    if (e.target.id === "factoryPresetFreqsStr") {
      let object = this.state.object;
      let freqsStr = e.target.value.split(",");
      object["factoryPresetFreqs"] = freqsStr.map((f, i) => parseInt(f, 10));
      this.setState({
        object: object,
      });
    }
    let object = this.state.object;
    if(e.target.id === "channelsStr") {
      let channelsStr = e.target.value.split(",");
      object["channels"] = channelsStr.map((c, i) => parseInt(c, 10));
    }
    if(e.target.id === "extra_channelsStr") {
      let extra_channelsStr = e.target.value.split(",");
      object["extraChannels"] = extra_channelsStr.map((c, i) => parseInt(c, 10));
    }
    console.log("=e.target.id==",e.target.id)
   if(e.target.id == "rfRegion") {
      this.setState({//置空
        frequencys: []
      });
      let listone=[];
      InternalStore.GetExtraChannelItemlistByBandName(e.target.value, resp => {
        for (let index = 0; index < resp.result.length; index++){
          listone[index] = resp.result[index].frequency; // 将数组的元素设置为它的自己
        } 
        this.setState({
          frequencys: listone
        });
        if (listone.length>0){
          this.setState({
            channelisnull: false,
          });
        }

      });
   }

    this.setState({
      object: object,
    });
  }

  setKVArray = (props) => {
    let tags = [];

    if (props.tags !== undefined) {
      for (let key in props.tags) {
        tags.push({key: key, value: props.tags[key]});
      }
    }

    this.setState({
      tags: tags,
    });
  }

  getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

  frequencyBandlist = (search, callbackFunc) => {
    var num2 =0;
    InternalStore.getBandNameList(resp => {
      for (var i=0;i<resp.result.length;i++){
        var M2 ={}
          
        M2["label"]=resp.result[i].bandName
        M2["value"]=resp.result[i].bandName
        this.state.bands[num2]=M2
        num2++;
      } 
      callbackFunc(this.state.bands)
    });
    
  }

  render() {
    if (this.state.object === undefined) {
      return null;
    }

    let factoryPresetFreqsStr = "";
    if (this.state.object.factoryPresetFreqsStr !== undefined) {
      factoryPresetFreqsStr = this.state.object.factoryPresetFreqsStr;
    } else if (this.state.object.factoryPresetFreqs !== undefined) {
      factoryPresetFreqsStr = this.state.object.factoryPresetFreqs.join(", ");
    }

    const codeMirrorOptions = {
      lineNumbers: true,
      mode: "javascript",
      theme: "default",
    };
    
    let payloadEncoderScript = this.state.object.payloadEncoderScript;
    let payloadDecoderScript = this.state.object.payloadDecoderScript;

    if (payloadEncoderScript === "" || payloadEncoderScript === undefined) {
      payloadEncoderScript = `// Encode encodes the given object into an array of bytes.
//  - fPort contains the LoRaWAN fPort number
//  - obj is an object, e.g. {"temperature": 22.5}
//  - variables contains the device variables e.g. {"calibration": "3.5"} (both the key / value are of type string)
// The function must return an array of bytes, e.g. [225, 230, 255, 0]
function Encode(fPort, obj, variables) {
  return [];
}`;
    }

    if (payloadDecoderScript === "" || payloadDecoderScript === undefined) {
      payloadDecoderScript = `// Decode decodes an array of bytes into an object.
//  - fPort contains the LoRaWAN fPort number
//  - bytes is an array of bytes, e.g. [225, 230, 255, 0]
//  - variables contains the device variables e.g. {"calibration": "3.5"} (both the key / value are of type string)
// The function must return an object, e.g. {"temperature": 22.5}
function Decode(fPort, bytes, variables) {
  return {};
}`;
    }

    const tags = this.state.tags.map((obj, i) => <KVForm key={i} index={i} object={obj} onChange={this.onChangeKV("tags")} onDelete={this.onDeleteKV("tags")} />);
    const { t } = this.props

    return(
      <Form
        submitLabel={this.props.submitLabel}
        onSubmit={this.onSubmit}
        disabled={this.props.disabled}
      >
        <Tabs value={this.state.tab} onChange={this.onTabChange} indicatorColor="primary">
          <Tab label={t('general')} />
          <Tab label="Join (OTAA / ABP)" />
          <Tab label="Class-B" />
          <Tab label="Class-C" />
          <Tab label={t('codec')} />
          <Tab label={t('tags')} />
        </Tabs>

        {this.state.tab === 0 && <div>
          <TextField
            id="name"
            label={t('deviceProfileName')}
            margin="normal"
            value={this.state.object.name || ""}
            onChange={this.onChange}
            helperText={t('deviceProfileNameHelpText')}
            required
            fullWidth
          />
          {!this.props.update && <FormControl fullWidth margin="normal">
            <FormLabel className={this.props.classes.formLabel} required>{t('networkServer')}</FormLabel>
            <AutocompleteSelect
              id="networkServerID"
              label={t('selectNetworkServer')}
              value={this.state.object.networkServerID || ""}
              onChange={this.onChange}
              getOptions={this.getNetworkServerOptions}
            />
            <FormHelperText>
              {t('deviceSelectNetworkServerHelpText')}
            </FormHelperText>
          </FormControl>}
          <FormControl fullWidth margin="normal">
            <FormLabel className={this.props.classes.formLabel} required>{t('LoRaWANMACVersion')}</FormLabel>
            <AutocompleteSelect
              id="macVersion"
              label={t('selectLoRaWANMACVersion')}
              value={this.state.object.macVersion || ""}
              onChange={this.onChange}
              getOptions={this.getMACVersionOptions}
            />
            <FormHelperText>
              {t('selectLoRaWANMACVersionHelpText')}
            </FormHelperText>
          </FormControl>

          <FormControl fullWidth margin="normal" >
            <FormLabel className={this.props.classes.formLabel} required>{t('FrequencyBands')}</FormLabel>
            <AutocompleteSelect
              id="rfRegion"
              label={t('selectFrequencyBand')}
              value={this.state.object.rfRegion || ""}
              onChange={this.onChange}
              getOptions={this.frequencyBandlist}
            />

            <FormHelperText>
              {t('selectFrequencyBandHelpText')}
            </FormHelperText>
          </FormControl>

   
           <TextField
            id="channelsStr"
            label={t('enabledChannels')}
            margin="normal"
            value={this.state.object.channelsStr || ""}
            onChange={this.onChange}
            helperText={t('enabledChannelsHelpText')}
            placeholder="0, 1, 2"
            inputProps={{
              pattern: "[0-9]+(,[\\s]*[0-9]+)*",
            }}
            required
            fullWidth
          />

          {/* <TextField
            id="extra_channelsStr"
            label={t('ExtraChannels')}
            margin="normal"
            value={this.state.object.extra_channelsStr || ""}
            onChange={this.onChange}
            helperText={t('extraChannelsHelpText')}
            placeholder="867100000,867300000,867500000"
            // inputProps={{
            //   pattern: "[0-9]+(,[\\s]*[0-9]+)*",
            // }}
            
            fullWidth
          /> */}


            {!this.state.channelisnull && <FormControl fullWidth margin="normal" >
            <FormLabel className={this.props.classes.formLabel} required>{t('ExtraChannels')}</FormLabel>
              <Select
                  multiple
                  value={this.state.channel_list}
                  onChange={this.handleChange}
                  input={<Input id="select-multiple-chip" />}
                  renderValue={selected => (
                    <div className={this.props.classes.chips}>
                      {selected.map(value => (
                        <Chip key={value} label={value} className={this.props.classes.chip} />
                      ))}
                    </div>
                  )}
                  MenuProps={MenuProps}
                >
              {this.state.frequencys.map((name) => (
                  <MenuItem key={name} value={name} style={this.getStyles(name, this.state.channel_list, theme)}>
                    {name}
                  </MenuItem>
              ))}
              </Select>
            </FormControl>}


        


          <FormControl fullWidth margin="normal">
            <FormLabel className={this.props.classes.formLabel} required>{t('LoRaWANRegionalParametersRevision')}</FormLabel>
            <AutocompleteSelect
              id="regParamsRevision"
              label={t('selectLoRaWANRegionalParametersRevision')}
              value={this.state.object.regParamsRevision || ""}
              onChange={this.onChange}
              getOptions={this.getRegParamsOptions}
            />
            <FormHelperText>
                {t('selectLoRaWANRegionalParametersRevisionHelpText')}
            </FormHelperText>
          </FormControl>
          <FormControl fullWidth margin="normal">
            <FormLabel className={this.props.classes.formLabel} required>{t('ADRAlgorithm')}</FormLabel>
            <AutocompleteSelect
              id="adrAlgorithmID"
              label={t('selectADRAlgorithm')}
              value={this.state.object.adrAlgorithmID || ""}
              onChange={this.onChange}
              getOptions={this.getADRAlgorithmsOptions}
              triggerReload={this.state.object.networkServerID || ""}
            />
            <FormHelperText>
              {t('selectADRAlgorithmHelpText')}
            </FormHelperText>
          </FormControl>
          <TextField
            id="maxEIRP"
            label={t('maxEIRP')}
            type="number"
            margin="normal"
            value={this.state.object.maxEIRP || 0}
            onChange={this.onChange}
            helperText={t('maxEIRPHelpText')}
            required
            fullWidth
          />
          <DurationField
            id="uplinkInterval"
            label={t('uplinkInterval')}
            helperText={t('uplinkIntervalHelpText')}
            value={this.state.object.uplinkInterval}
            onChange={this.onChange}
          />
           <TextField
            id="rx1Delay"
            label={"rx1Delay"}
            type="number"
            margin="normal"
            value={this.state.object.rx1Delay || 0}
            onChange={this.onChange}
            helperText={t('rx1DelayHelpText')}
            required
            fullWidth
          />
          


   

          <FormControl label={"DisableAdr"}>
          <FormGroup>
            <FormControlLabel
              label={t("DisableAdr")}
              control={
                <Checkbox
                  id="disableAdr"
                  checked={!!this.state.object.disableAdr}
                  onChange={this.onChange}
                  color="primary"
                />
              }
            />
          </FormGroup>
        </FormControl>
        </div>}

        {this.state.tab === 1 && <div>
          <FormControl fullWidth margin="normal">
            <FormControlLabel
              label={t('deviceSupportsOTAA')}
              control={
                <Checkbox
                  id="supportsJoin"
                  checked={!!this.state.object.supportsJoin}
                  onChange={this.onChange}
                  color="primary"
                />
              }
            />
          </FormControl>
          {!this.state.object.supportsJoin && <TextField
            id="rxDelay1"
            label={t('RX1Delay')}
            type="number"
            margin="normal"
            value={this.state.object.rxDelay1 || 0}
            onChange={this.onChange}
            helperText={t('RX1DelayHelpText')}
            required
            fullWidth
          />}
          {!this.state.object.supportsJoin && <TextField
            id="rxDROffset1"
            label={t('RX1DataRateOffset')}
            type="number"
            margin="normal"
            value={this.state.object.rxDROffset1 || 0}
            onChange={this.onChange}
            helperText={t('RX2DataRateHelpText')}
            required
            fullWidth
          />}
          {!this.state.object.supportsJoin && <TextField
            id="rxDataRate2"
            label={t('RX2DataRate')}
            type="number"
            margin="normal"
            value={this.state.object.rxDataRate2 || 0}
            onChange={this.onChange}
            helperText={t('RX2DataRateHelpText')}
            required
            fullWidth
          />}
          {!this.state.object.supportsJoin && <TextField
            id="rxFreq2"
            label={t('RX2ChannelFrequency')}
            type="number"
            margin="normal"
            value={this.state.object.rxFreq2 || 0}
            onChange={this.onChange}
            required
            fullWidth
          />}
          {!this.state.object.supportsJoin && <TextField
            id="factoryPresetFreqsStr"
            label={t('factoryPresetFrequencies')}
            margin="normal"
            value={factoryPresetFreqsStr}
            onChange={this.onChange}
            helperText={t('factoryPresetFrequenciesHelpText')}
            required
            fullWidth
          />}
        </div>}

        {this.state.tab === 2 && <div>
          <FormControl fullWidth margin="normal">
            <FormControlLabel
              label={t('deviceSupportsClassB')}
              control={
                <Checkbox
                  id="supportsClassB"
                  checked={!!this.state.object.supportsClassB}
                  onChange={this.onChange}
                  color="primary"
                />
              }
            />
          </FormControl>

          {this.state.object.supportsClassB && <TextField
            id="classBTimeout"
            label={t('ClassBConfirmedDownlinkTimeout')}
            type="number"
            margin="normal"
            value={this.state.object.classBTimeout || 0}
            onChange={this.onChange}
            helperText={t('ClassBConfirmedDownlinkTimeoutHelpText')}
            required
            fullWidth
          />}
          {this.state.object.supportsClassB && <FormControl
              fullWidth
              margin="normal"
            >
              <FormLabel className={this.props.classes.formLabel} required>{t('ClassBPingSlotPeriodicity')}</FormLabel>
              <AutocompleteSelect
                id="pingSlotPeriod"
                label={t('selectClassBPingSlotPeriodicity')}
                value={this.state.object.pingSlotPeriod || ""}
                onChange={this.onChange}
                getOptions={this.getPingSlotPeriodOptions}
              />
              <FormHelperText>{t('ClassBPingSlotPeriodicityHelpText')}</FormHelperText>
          </FormControl>}
          {this.state.object.supportsClassB && <TextField
            id="pingSlotDR"
            label={t('ClassBPingSlotDataRate')}
            type="number"
            margin="normal"
            value={this.state.object.pingSlotDR || 0}
            onChange={this.onChange}
            required
            fullWidth
          />}
          {this.state.object.supportsClassB && <TextField
            id="pingSlotFreq"
            label={t('ClassBPingSlotFrequency')}
            type="number"
            margin="normal"
            value={this.state.object.pingSlotFreq || 0}
            onChange={this.onChange}
            required
            fullWidth
          />}
        </div>}

        {this.state.tab === 3 && <div>
          <FormControl fullWidth margin="normal">
            <FormControlLabel
              label={t('deviceSupportsClassC')}
              control={
                <Checkbox
                  id="supportsClassC"
                  checked={!!this.state.object.supportsClassC}
                  onChange={this.onChange}
                  color="primary"
                />
              }
            />
            <FormHelperText>{t('deviceSupportsClassCHelpText')}</FormHelperText>
          </FormControl>

          <TextField
            id="classCTimeout"
            label={t('ClassCConfirmedDownlinkTimeout')}
            type="number"
            margin="normal"
            value={this.state.object.classCTimeout || 0}
            onChange={this.onChange}
            helperText={t('ClassCConfirmedDownlinkTimeoutHelpText')}
            required
            fullWidth
          />
        </div>}

        {this.state.tab === 4 && <div>
          <FormControl fullWidth margin="normal">
            <FormLabel className={this.props.classes.formLabel}>{t('payloadCodec')}</FormLabel>
            <AutocompleteSelect
              id="payloadCodec"
              label={t('selectPayloadCodec')}
              value={this.state.object.payloadCodec || ""}
              onChange={this.onChange}
              getOptions={this.getPayloadCodecOptions}
            />
            <FormHelperText>
              {t('selectPayloadCodecHelpText')}
            </FormHelperText>
          </FormControl>

          {this.state.object.payloadCodec === "CUSTOM_JS" && <FormControl fullWidth margin="normal">
            <CodeMirror
              value={payloadDecoderScript}
              options={codeMirrorOptions}
              onBeforeChange={this.onCodeChange.bind(this, 'payloadDecoderScript')}
              className={this.props.classes.codeMirror}
            />
            <FormHelperText>
              The function must have the signature <strong>function Decode(fPort, bytes)</strong> and must return an object.
              Zysmart Application Server will convert this object to JSON.
            </FormHelperText>
          </FormControl>}
          {this.state.object.payloadCodec === "CUSTOM_JS" && <FormControl fullWidth margin="normal">
            <CodeMirror
              value={payloadEncoderScript}
              options={codeMirrorOptions}
              onBeforeChange={this.onCodeChange.bind(this, 'payloadEncoderScript')}
              className={this.props.classes.codeMirror}
            />
            <FormHelperText>
              The function must have the signature <strong>function Encode(fPort, obj)</strong> and must return an array
              of bytes.
            </FormHelperText>
          </FormControl>}
        </div>}

        {this.state.tab === 5 && <div>
          <FormControl fullWidth margin="normal">
            <Typography variant="body1">
              {t('tagsTips')}
            </Typography>
            {tags}
          </FormControl>
          <Button variant="outlined" onClick={this.addKV("tags")}>{t('addTag')}</Button>
        </div>}
      </Form>
    );
  }
}

export default withStyles(styles)(withTranslation()(DeviceProfileForm));
