import { EventEmitter } from "events";

import Swagger from "swagger-client";

import sessionStore from "./SessionStore";
import { checkStatus, errorHandler } from "./helpers";
import dispatcher from "../dispatcher";


class InternalStore extends EventEmitter {
  constructor() {
    super();
    this.client = null;
    this.swagger = Swagger("/swagger/internal.swagger.json", sessionStore.getClientOpts());
  }

  listAPIKeys(filters, callbackFunc) {
    this.swagger.then(client => {
      client.apis.InternalService.ListAPIKeys(filters)
        .then(checkStatus)
        .then(resp => {
          callbackFunc(resp.obj);
        })
        .catch(errorHandler);
    });
  }

  deleteAPIKey(id, callbackFunc) {
    this.swagger.then(client => {
      client.apis.InternalService.DeleteAPIKey({
        id: id,
      })
      .then(checkStatus)
      .then(resp => {
        this.notify("api key has been deleted");
        callbackFunc(resp.obj);
      })
      .catch(errorHandler);
    });
  }

  getAPIKey(id, callbackFunc) {
    this.swagger.then(client => {
      client.apis.InternalService.GetAPIKeys({
        id: id,
      })
      .then(checkStatus)
      .then(resp => {
        callbackFunc(resp.obj);
      })
      .catch(errorHandler);
    });
  }

  createAPIKey(obj, callbackFunc) {
    this.swagger.then(client => {
      client.apis.InternalService.CreateAPIKey({
        body: {
          apiKey: obj,
        },
      })
      .then(checkStatus)
      .then(resp => {
        this.notify("api key has been created");
        callbackFunc(resp.obj);
      })
      .catch(errorHandler);
    });
  }

  createAuthorization(obj, callbackFunc) {
    this.swagger.then(client => {
      client.apis.InternalService.CreateAuthorization({
        body: {
          authorization: obj,
        },
      })
      .then(checkStatus)
      .then(resp => {
        this.notify("api authorization has been created");
        callbackFunc(resp.obj);
      })
      .catch(errorHandler);
    });
  }
  listAuthorization(filters, callbackFunc) {
    this.swagger.then(client => {
      client.apis.InternalService.ListAuthorization(filters)
        .then(checkStatus)
        .then(resp => {
          console.log("======",resp)
          callbackFunc(resp.obj);
        })
        .catch(errorHandler);
    });
  }
  deleteAuthorization(id, callbackFunc) {
    this.swagger.then(client => {
      client.apis.InternalService.DeleteAuthorization({
        id: id,
      })
      .then(checkStatus)
      .then(resp => {
        this.notify("authorization has been deleted");
        callbackFunc(resp.obj);
      })
      .catch(errorHandler);
    });
  }
  GetAuthorizationState(id, callbackFunc) {
    this.swagger.then(client => {
      client.apis.InternalService.GetAuthorizationState({
        id: id,
      })
      .then(checkStatus)
      .then(resp => {
        callbackFunc(resp.obj);
      })
      .catch(errorHandler);
    });
  }

  GetSystemSettings(id, callbackFunc) {
    this.swagger.then(client => {
      client.apis.InternalService.GetSystemSettings({
        id: id,
      })
      .then(checkStatus)
      .then(resp => {
        callbackFunc(resp.obj);
      })
      .catch(errorHandler);
    });
  }
  updateSystemSettings(sysset, callbackFunc) {
    this.swagger.then(client => {
      client.apis.InternalService.UpdateSystemSettings({
        "SystemSettings.id": sysset.id,
        body: {
          SystemSettings: sysset,
        },
      })
      .then(checkStatus)
      .then(resp => {
        this.notify("updated");
        callbackFunc(resp.obj);
      })
      .catch(errorHandler);
    });
  }

  settings(callbackFunc) {
    this.swagger.then(client => {
      client.apis.InternalService.Settings({})
        .then(checkStatus)
        .then(resp => {
          callbackFunc(resp.obj);
        })
        .catch(errorHandler);
    });
  }
  getBandNameList(callbackFunc) {
    this.swagger.then(client => {
      client.apis.InternalService.GetBandNameList({})
        .then(checkStatus)
        .then(resp => {
          callbackFunc(resp.obj);
        })
        .catch(errorHandler);
    });
  }

  getDevicesSummary(organizationID, callbackFunc) {
    this.swagger.then(client => {
      client.apis.InternalService.GetDevicesSummary({
        organizationID: organizationID,
      })
        .then(checkStatus)
        .then(resp => {
          callbackFunc(resp.obj);
        })
        .catch(errorHandler);
    });
  }

  getGatewaysSummary(organizationID, callbackFunc) {
    this.swagger.then(client => {
      client.apis.InternalService.GetGatewaysSummary({
        organizationID: organizationID,
      })
        .then(checkStatus)
        .then(resp => {
          callbackFunc(resp.obj);
        })
        .catch(errorHandler);
    });
  }
  ListListHistoryUpQueue(filters, callbackFunc) {
    this.swagger.then(client => {
      client.apis.InternalService.ListListHistoryUpQueue(filters)
        .then(checkStatus)
        .then(resp => {
          callbackFunc(resp.obj);
        })
        .catch(errorHandler);
    });
  }

  GetExtraChannelslist(filters, callbackFunc) {
    this.swagger.then(client => {
      client.apis.InternalService.GetExtraChannelslist(filters)
        .then(checkStatus)
        .then(resp => {
          callbackFunc(resp.obj);
        })
        .catch(errorHandler);
    });
  }
  GetExtraChannels(id, callbackFunc) {
    console.log("---id===",id)
    this.swagger.then(client => {
      client.apis.InternalService.GetExtraChannels({
        id: id,
      })
      .then(checkStatus)
      .then(resp => {
        callbackFunc(resp.obj);
      })
      .catch(errorHandler);
    });
  }

  UpdateExtraChannels(extraChannels, callbackFunc) {
    this.swagger.then(client => {
      client.apis.InternalService.UpdateExtraChannels({
        "extra_channels.id": extraChannels.id,
        body: {
          extra_channels: extraChannels,
        },
      })
      .then(checkStatus)
      .then(resp => {
        this.notify("updated");
        callbackFunc(resp.obj);
      })
      .catch(errorHandler);
    });
  }

  CreateExtraChannels(extraChannels, callbackFunc) {
    this.swagger.then(client => {
      client.apis.InternalService.CreateExtraChannels({
        body: {
          extra_channels: extraChannels,
        },
      })
      .then(checkStatus)
      .then(resp => {
        this.notify("created");
        callbackFunc(resp.obj);
      })
      .catch(errorHandler);
    });
  }
  DeleteExtraChannels(id, callbackFunc) {
    this.swagger.then(client => {
      client.apis.InternalService.DeleteExtraChannels({
        id: id,
      })
      .then(checkStatus)
      .then(resp => {
        this.notify("deleted");
        callbackFunc(resp.obj);
      })
      .catch(errorHandler);
    });
  }

  GetExtraChannelItemlist(filters, callbackFunc) {
    this.swagger.then(client => {
      client.apis.InternalService.GetExtraChannelItemlist(filters)
        .then(checkStatus)
        .then(resp => {
          callbackFunc(resp.obj);
        })
        .catch(errorHandler);
    });
  }

  GetExtraChannelItem(id,extraChannelsId, callbackFunc) {
    this.swagger.then(client => {
      client.apis.InternalService.GetExtraChannelItem({
        id: id,
        extraChannelsId:extraChannelsId
      })
      .then(checkStatus)
      .then(resp => {
        callbackFunc(resp.obj);
      })
      .catch(errorHandler);
    });
  }


  CreateExtraChannelItem(extraChannelItem, callbackFunc) {
    this.swagger.then(client => {
      client.apis.InternalService.CreateExtraChannelItem({
        body: {
          extra_channel_item: extraChannelItem,
        },
      })
      .then(checkStatus)
      .then(resp => {
        this.notify("created");
        callbackFunc(resp.obj);
      })
      .catch(errorHandler);
    });
  }
  
  UpdateExtraChannelItem(extraChannelItem, callbackFunc) {
    this.swagger.then(client => {
      client.apis.InternalService.UpdateExtraChannelItem({
        "extra_channel_item.id": extraChannelItem.id,
        body: {
          extra_channel_item: extraChannelItem,
        },
      })
      .then(checkStatus)
      .then(resp => {
        this.notify("updated");
        callbackFunc(resp.obj);
      })
      .catch(errorHandler);
    });
  }

  DeleteExtraChannelItem(id, extraChannelsId,callbackFunc) {
    this.swagger.then(client => {
      client.apis.InternalService.DeleteExtraChannelItem({
        id: id,
        extraChannelsId:extraChannelsId
      })
      .then(checkStatus)
      .then(resp => {
        this.notify("deleted");
        callbackFunc(resp.obj);
      })
      .catch(errorHandler);
    });
  }
  
  
  RenovateExtraChannel( callbackFunc) {
    this.swagger.then(client => {
      client.apis.InternalService.RenovateExtraChannel({
    
      })
      .then(checkStatus)
      .then(resp => {
        callbackFunc(resp.obj);
      })
      .catch(errorHandler);
    });
  }

  GetExtraChannelItemlistByBandName( bandName ,callbackFunc) {
    this.swagger.then(client => {
      client.apis.InternalService.GetExtraChannelItemlistByBandName({
        bandName:bandName
      })
      .then(checkStatus)
      .then(resp => {
        callbackFunc(resp.obj);
      })
    });
  }

  notify(msg) {
    dispatcher.dispatch({
      type: "CREATE_NOTIFICATION",
      notification: {
        type: "success",
        message: msg,
      },
    });
  }


  CreateAsyncTask(relevanceId,params,type, name,callbackFunc) {
    this.swagger.then(client => {
      client.apis.InternalService.CreateAsyncTask({
        body: {
          relevanceId: relevanceId,
          params: params,
          type: type,
          name: name,
        },
      })
      .then(checkStatus)
      .then(resp => {
        this.notify("created");
        callbackFunc(resp.obj);
      })
      .catch(errorHandler);
    });
  }

  AsyncTaskList(relevanceId,type,limit, offset,callbackFunc) {
    this.swagger.then(client => {
      client.apis.InternalService.AsyncTaskList({
        body: {
          relevanceId: relevanceId,
          type: type,
          limit:limit, 
          offset:offset,
        },
      })
      .then(checkStatus)
      .then(resp => {
        callbackFunc(resp.obj);
      })
      .catch(errorHandler);
    });
  }
}

const internalStore = new InternalStore();
export default internalStore;
