import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import Grid from "@material-ui/core/Grid";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import IconButton from '@material-ui/core/IconButton';

import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import TitleBar from "../../components/TitleBar";
import TitleBarTitle from "../../components/TitleBarTitle";
import TitleBarButton from "../../components/TitleBarButton";
import DataTable from "../../components/DataTable";
import InternalStore from "../../stores/InternalStore";

import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Button from "@material-ui/core/Button";

import DialogContentText from '@material-ui/core/DialogContentText';
import CodeGreaterThan  from "mdi-material-ui/CodeGreaterThan";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';

import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import JSONTree from "../../components/JSONTree";
import Form from "../../components/Form";


const useStyles = withStyles((theme) => ({
}));
const styles = {
  valueTableCell: {
    textAlign: "left",
    color: "black",
  },
  HeadTableCell: {
    textAlign: "left",
  },
  treeStyle: {
    paddingTop: 0,
    paddingBottom: 0,
    fontSize: 12,
    lineHeight: 1.5,
  },
};


class ListAdminUplinkQueue extends Component {

  constructor() {
    super();
    this.state = {
      id: "",
      spDialog: false,
      fullWidth: true,
      maxWidth: "sm",
      json:"",

    };
    this.Detail = this.Detail.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
    



  }





Detail(date) {
 
  // InternalStore.getAPIKey(date, resp => {
  //   if ((resp.jwtToken).length >50){
  //     resp.exhibitToken=(resp.jwtToken).slice(0,50)+"..."

  //     this.setState({
  //       key: resp,

  //     })
  //   }else{
  //     resp.exhibitToken="----"
  //     this.setState({
  //       key: resp,

  //     })
  //   }

  // });
  var jdata = JSON.parse(date)
    this.setState({
      spDialog: true,
      json: jdata,
    })
}
closeDialog() {
  this.setState({
    spDialog: false,
  });
}
  getPage = (limit, offset, callbackFunc) => {
    InternalStore.ListListHistoryUpQueue({
      //isAdmin: true,
      limit: limit,
      offset: offset,
    }, callbackFunc);
  }

  getRow = (obj) => {
    return (
      <TableRow
        key={obj.id}
        hover
      >
        <TableCell className={this.props.classes.valueTableCell} >{obj.id}</TableCell>
        <TableCell className={this.props.classes.valueTableCell} >{obj.applicationId}</TableCell>
        <TableCell className={this.props.classes.valueTableCell} >{obj.devEui}</TableCell>
        <TableCell className={this.props.classes.valueTableCell} >{obj.url}</TableCell>
        <TableCell className={this.props.classes.valueTableCell} >{obj.createdAt}</TableCell>
        <TableCell className={this.props.classes.valueTableCell}>
          <IconButton onClick={this.Detail.bind(this,obj.date)}><CodeGreaterThan /></IconButton>
        </TableCell>
      </TableRow>
    );
  }

  render() {
    const { t } = this.props
    return (
      <Grid container >
        <Grid container spacing={4}>
          <TitleBar>
            <TitleBarTitle title={t('UplinkQueue')} />
          </TitleBar>
          <Grid item xs={12}>
            <DataTable
              header={
                <TableRow>
                  <TableCell className={this.props.classes.HeadTableCell} >ID</TableCell>
                  <TableCell className={this.props.classes.HeadTableCell} >ApplicationId</TableCell>
                  <TableCell className={this.props.classes.HeadTableCell} >DevEui</TableCell>
                  <TableCell className={this.props.classes.HeadTableCell} >Url</TableCell>
                  <TableCell className={this.props.classes.HeadTableCell} >{t('createdTime')}</TableCell>
                  <TableCell className={this.props.classes.HeadTableCell} >{t('details')}</TableCell>
                </TableRow>
              }
              getPage={this.getPage}
              getRow={this.getRow}
            />
          </Grid>
        </Grid>
       
        
      
            <div>
              <Dialog
              fullWidth={this.state.fullWidth}
              maxWidth={this.state.maxWidth}
              open={this.state.spDialog}
              onClose={this.closeDialog}
              aria-labelledby="max-width-dialog-title"

            >
              <DialogContent  >
                <DialogContentText paragraph>
                  <ExpansionPanel>
                    <ExpansionPanelDetails>
                      <Grid item xs className={this.props.classes.treeStyle}>
                        <JSONTree data={this.state.json} />
                      </Grid>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button color="primary" onClick={this.closeDialog}>{t('close')}</Button>
              </DialogActions>
            </Dialog>
    
          </div>
         </Grid>


    );
  }
}


export default withStyles(styles)(withTranslation()(ListAdminUplinkQueue));
