import React from "react";

import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import { withTranslation } from "react-i18next";

import FormComponent from "../../classes/FormComponent";
import Form from "../../components/Form";


class OrganizationUserForm extends FormComponent {
  render() {
    if (this.state.object === undefined) {
      return(<div></div>);
    }

    const { t } = this.props

    return(
      <Form
        submitLabel={this.props.submitLabel}
        onSubmit={this.onSubmit}
      >
          <TextField
            label={t('email')}
            id="email"
            margin="normal"
            value={this.state.object.email || ""}
            onChange={this.onChange}
            required
            fullWidth
            disabled={this.props.update}
          />
          <Typography variant="body1">
            {t('addUserTips')}
          </Typography>
          <FormControl fullWidth margin="normal">
            <FormControlLabel
              label={t('userIsOrganizationAdmin')}
              control={
                <Checkbox
                  id="isAdmin"
                  checked={!!this.state.object.isAdmin}
                  onChange={this.onChange}
                  color="primary"
                />
              }
            />
            <FormHelperText>{t('userIsOrganizationAdminHelpText')}</FormHelperText>
          </FormControl>
          {!!!this.state.object.isAdmin && <FormControl fullWidth margin="normal">
            <FormControlLabel
              label={t('userIsDeviceAdmin')}
              control={
                <Checkbox
                  id="isDeviceAdmin"
                  checked={!!this.state.object.isDeviceAdmin}
                  onChange={this.onChange}
                  color="primary"
                />
              }
            />
            <FormHelperText>{t('userIsDeviceAdminHelpText')}</FormHelperText>
          </FormControl>}
          {!!!this.state.object.isAdmin && <FormControl fullWidth margin="normal">
            <FormControlLabel
              label={t('userIsGatewayAdmin')}
              control={
                <Checkbox
                  id="isGatewayAdmin"
                  checked={!!this.state.object.isGatewayAdmin}
                  onChange={this.onChange}
                  color="primary"
                />
              }
            />
            <FormHelperText>{t('userIsGatewayAdminHelpText')}</FormHelperText>
          </FormControl>}
      </Form>
    );
  }
}

export default withTranslation()(OrganizationUserForm);
