import { createMuiTheme } from "@material-ui/core/styles";
import blue from "@material-ui/core/colors/blue";
import * as locales from '@material-ui/core/locale';
import i18n from "./i18n/i18n";

const currTheme = ()=>createMuiTheme({
  palette: {
    primary: blue,
  },
}, locales[i18n.language === 'zh' ? 'zhCN' : 'enUS']);

const theme = currTheme()
theme.currTheme = currTheme

export default theme;
