import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { IconButton } from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import Fade from '@material-ui/core/Fade';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withTranslation } from 'react-i18next';
import Refresh from "mdi-material-ui/Refresh";
const styles = {
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      },
    placeholder: {
        height: 40,
      },
    textcss:{
        paddingTop: '25px',
        textAlign: 'center',
    },
    butts:{
      height: '25px',
      width: '25px',
      marginRight: '8px'
      
  },
  HideBlank:{
    height: '20px',
}

    
}

class LoadingDialog extends Component{

    constructor(){
        super()
        this.state = {
            search: '',
            fullWidth: true,
            maxWidth: "sm",
            spDialog:false,
            loading:false,
            FadeDialog:false,
        }
    }

   handleClickOpen = () => {
  
    // var jdata = JSON.parse(this.props.objectJson)

    this.setState({
        FadeDialog: true,
        loading:true,
        spDialog: true,
    })
    var timer = setTimeout(function(){
      this.handleClose()
    }.bind(this),4000);
  }
   handleClose = () => {
    this.setState({
      FadeDialog: false,
      loading:false,
      spDialog: false,
    })
  }
  render (){
     const { t } = this.props;
      return(
        <div>
        <IconButton
              color="inherit"
              onClick={this.handleClickOpen}
              className={this.props.classes.butts}
            >
        <Refresh /> 
      </IconButton>

      <Dialog
            fullWidth={this.state.fullWidth}
            maxWidth={this.state.maxWidth}
            open={this.state.FadeDialog}
            aria-labelledby="max-width-dialog-title"
          >
            < DialogContent>
              <DialogContentText paragraph>
                <div className={this.props.classes.root}>
                  <div className={this.props.classes.placeholder}>
                    <div className={this.props.classes.HideBlank}> </div>
                    <Fade in={this.state.loading} unmountOnExit>
                      <CircularProgress />
                    </Fade>
                  </div>
                </div> 
                <div  className={this.props.classes.textcss} >{t('LoadingDialogHelpText')}</div>
              </DialogContentText>
            </DialogContent>
          </Dialog>
      </div>
      );
    }
}
export default withStyles(styles)(withRouter(withTranslation()(LoadingDialog)));