import React from "react";

import TextField from '@material-ui/core/TextField';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from "@material-ui/core/FormGroup";
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import { withTranslation } from "react-i18next";

import FormComponent from "../../classes/FormComponent";
import Form from "../../components/Form";
import FormControl from "../../components/FormControl";


class NetworkServerForm extends FormComponent {
  constructor() {
    super();
    this.state = {
      tab: 0,
    };

    this.onChangeTab = this.onChangeTab.bind(this);
  }

  onChangeTab(event, value) {
    this.setState({
      tab: value,
    });
  }

  render() {
    if (this.state.object === undefined) {
      return(null);
    }

    const { t } = this.props

    return(
      <Form
        submitLabel={this.props.submitLabel}
        onSubmit={this.onSubmit}
      >
            <Tabs
              value={this.state.tab}
              indicatorColor="primary"
              textColor="primary"
              onChange={this.onChangeTab}
            >
              <Tab label={t('general')} />
              <Tab label={t('gatewayDiscovery')} />
              <Tab label={t('TlsCertificates')} />
            </Tabs>
          {this.state.tab === 0 && <div>
            <TextField
              id="name"
              label={t('networkServerName')}
              fullWidth={true}
              margin="normal"
              value={this.state.object.name || ""}
              onChange={this.onChange}
              helperText={t('networkServerNameHelpText')}
              required={true}
            />
            <TextField
              id="server"
              label={t('networkServerServer')}
              fullWidth={true}
              margin="normal"
              value={this.state.object.server || ""}
              onChange={this.onChange}
              helperText={t('networkServerServerHelpText')}
              required={true}
            />
          </div>}
          {this.state.tab === 1 && <div>
            <FormControl
              label={t('gatewayDiscovery')}
            >
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      id="gatewayDiscoveryEnabled"
                      checked={!!this.state.object.gatewayDiscoveryEnabled}
                      onChange={this.onChange}
                      value="true"
                      color="primary"
                    />
                  }
                  label={t('enableGatewayDiscovery')}
                />
              </FormGroup>
              <FormHelperText>{t('enableGatewayDiscoveryHelpText')}</FormHelperText>
            </FormControl>
            {this.state.object.gatewayDiscoveryEnabled && <TextField
              id="gatewayDiscoveryInterval"
              label={t('intervalPerDay')}
              type="number"
              fullWidth={true}
              margin="normal"
              value={this.state.object.gatewayDiscoveryInterval}
              onChange={this.onChange}
              helperText={t('intervalPerDayHelpText')}
              required={true}
            />}
            {this.state.object.gatewayDiscoveryEnabled && <TextField
              id="gatewayDiscoveryTXFrequency"
              label={t('TXFrequency')}
              type="number"
              fullWidth={true}
              margin="normal"
              value={this.state.object.gatewayDiscoveryTXFrequency}
              onChange={this.onChange}
              helperText={t('TXFrequencyHelpText')}
              required={true}
            />}
            {this.state.object.gatewayDiscoveryEnabled && <TextField
              id="gatewayDiscoveryDR"
              label={t('TXDataRate')}
              type="number"
              fullWidth={true}
              margin="normal"
              value={this.state.object.gatewayDiscoveryDR}
              onChange={this.onChange}
              helperText={t('TXDataRateHelpText')}
              required={true}
            />}
          </div>}
          {this.state.tab === 2 && <div>
            <FormControl
              label={t('gatewayDiscoveryTips')}
            >
              <FormGroup>
                <TextField
                  id="caCert"
                  label={t('CACertificate')}
                  fullWidth={true}
                  margin="normal"
                  value={this.state.object.caCert || ""}
                  onChange={this.onChange}
                  helperText={t('CACertificateHelpText')}
                  multiline
                  rows="4"
                />
                <TextField
                  id="tlsCert"
                  label={t('TLSCertificate')}
                  fullWidth={true}
                  margin="normal"
                  value={this.state.object.tlsCert || ""}
                  onChange={this.onChange}
                  helperText={t('TLSCertificateHelpText')}
                  multiline
                  rows="4"
                />
                <TextField
                  id="tlsKey"
                  label={t('TLSKey')}
                  fullWidth={true}
                  margin="normal"
                  value={this.state.object.tlsKey || ""}
                  onChange={this.onChange}
                  helperText={t('TLSKeyHelpText')}
                  multiline
                  rows="4"
                />
              </FormGroup>
            </FormControl>

            <FormControl
              label={t('gatewayDiscoveryTips2')}
            >
              <FormGroup>
                <TextField
                  id="routingProfileCACert"
                  label={t('CACertificate')}
                  fullWidth={true}
                  margin="normal"
                  value={this.state.object.routingProfileCACert || ""}
                  onChange={this.onChange}
                  helperText={t('CACertificateHelpText')}
                  multiline
                  rows="4"
                />
                <TextField
                  id="routingProfileTLSCert"
                  label={t('TLSCertificate')}
                  fullWidth={true}
                  margin="normal"
                  value={this.state.object.routingProfileTLSCert || ""}
                  onChange={this.onChange}
                  helperText={t('TLSCertificateHelpText')}
                  multiline
                  rows="4"
                />
                <TextField
                  id="routingProfileTLSKey"
                  label={t('TLSKey')}
                  fullWidth={true}
                  margin="normal"
                  value={this.state.object.routingProfileTLSKey || ""}
                  onChange={this.onChange}
                  helperText={t('TLSKeyHelpText')}
                  multiline
                  rows="4"
                />
              </FormGroup>
            </FormControl>
          </div>}
      </Form>
    );
  }
}

export default withTranslation()(NetworkServerForm);
