import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';

import moment from "moment";
import { Map, Marker, Popup } from 'react-leaflet';
import MarkerClusterGroup from "react-leaflet-markercluster";
import L from "leaflet";
import { Doughnut } from 'react-chartjs-2';
import "leaflet.awesome-markers";
import { withTranslation } from "react-i18next";

import MapTileLayer from "../../components/MapTileLayer";
import GatewayStore from "../../stores/GatewayStore";
import InternalStore from "../../stores/InternalStore";
import theme from "../../theme";
import Form from "../../components/Form";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import TextField from '@material-ui/core/TextField';
import SessionStore from "../../stores/SessionStore";


import Fade from '@material-ui/core/Fade';
import CircularProgress from '@material-ui/core/CircularProgress';



const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  placeholder: {
    height: 40,
  },
  textcss:{
    paddingTop: '25px',
    textAlign: 'center',

  },
  cardheader:{
    display: 'inline-block'
  },
  doughtnutChart: {
    maxWidth: "350px",
    padding: 0,
    margin: "auto",
    display: "block",
  },
 
}

class ListGatewaysMap extends Component {
  constructor() {
    super();

    this.state = {
      items: null,
    };
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps === this.props) {
      return;
    }

    this.loadData();
  }

  loadData = () => {
    GatewayStore.list("", this.props.organizationID, 9999, 0, resp => {
      this.setState({
        items: resp.result,
      });
    });
  }

  render() {
    const { t } = this.props
    if (this.state.items === null || this.state.items.length === 0) {
      return(
        <Card>
          <CardHeader title={t('gateways2')} />
            <CardContent>
            {t('noDataAvailable')}
            </CardContent>
        </Card>
      );
    }

    const style = {
      height: 300,
    };


    let bounds = [];
    let markers = [];

    const greenMarker = L.AwesomeMarkers.icon({
      icon: "wifi",
      prefix: "fa",
      markerColor: "green",
    });

    const grayMarker = L.AwesomeMarkers.icon({
      icon: "wifi",
      prefix: "fa",
      markerColor: "gray",
    });

    const redMarker = L.AwesomeMarkers.icon({
      icon: "wifi",
      prefix: "fa",
      markerColor: "red",
    });

    for (const item of this.state.items) {
      const position = [item.location.latitude, item.location.longitude];

      bounds.push(position);

      let marker = greenMarker;
      let lastSeen = "";

      if (item.lastSeenAt === undefined || item.lastSeenAt === null) {
        marker = grayMarker;
        lastSeen = "Never seen online";
      } else {
        const ts = moment(item.lastSeenAt);
        if (ts.isBefore(moment().subtract(5, 'minutes'))) {
          marker = redMarker;
        }

        lastSeen = ts.fromNow();
      }

      markers.push(
        <Marker position={position} key={`gw-${item.id}`} icon={marker}>
          <Popup>
            <Link to={`/organizations/${this.props.organizationID}/gateways/${item.id}`}>{item.name}</Link><br />
            {item.id}<br /><br />
            {lastSeen}
          </Popup>
        </Marker>
      );
    }

    return(
      <Card>
        <CardHeader title={t('gateways2')} />
          <CardContent>
            <Map bounds={bounds} maxZoom={19} style={style} animate={true} scrollWheelZoom={false}>
              <MapTileLayer />
              <MarkerClusterGroup>
                {markers}
              </MarkerClusterGroup>
            </Map>
          </CardContent>
      </Card>
    );
  }
}


class DevicesActiveInactive extends Component {
  constructor() {
    super();

    this.state = {
      labels: [],
      devicesSummary:{}
    };
  }

  componentDidMount() {
      InternalStore.getDevicesSummary(this.props.organizationID, resp => {
        let  labels=[]
        labels[0]="Never seen("+resp.neverSeenCount+")"
        labels[1]="Inactive("+resp.inactiveCount+")"
        labels[2]="Active("+resp.activeCount+")"
        this.setState({
          devicesSummary: resp,
          labels:labels,
        });
      });
  }

  render() {
    let data = null;

    if (this.props.summary !== null && (this.props.summary.activeCount !== 0 || this.props.summary.inactiveCount !== 0)) {
      data = {
        labels: this.state.labels,
        datasets: [
          {
            data: [this.props.summary.neverSeenCount, this.props.summary.inactiveCount, this.props.summary.activeCount],
            backgroundColor: [
              theme.palette.warning.main,
              theme.palette.error.main,
              theme.palette.success.main,
            ],
          },
        ],
      };
    }

    const options = {
      animation: false,
    };
    const { t } = this.props
    return(
      <Card>
        <CardHeader title={t('activeDevices') }/>
        <CardContent>
          {data && <Doughnut data={data} options={options} className={this.props.classes.doughtnutChart} />}
          {!data && <div>{t('noDataAvailable')}</div>}
        </CardContent>
      </Card>
    );
  }
}
DevicesActiveInactive = withStyles(styles)(withTranslation()(DevicesActiveInactive));

class GatewaysActiveInactive extends Component {
  constructor() {
    super();

    this.state = {
      labels: [],
      gatewaysSummary:{}
    };
  }
  componentDidMount() {

    InternalStore.getGatewaysSummary(this.props.organizationID, resp => {
      console.log("===resp====",resp)
      let  labels=[]
      labels[0]="Never seen("+resp.neverSeenCount+")"
      labels[1]="Inactive("+resp.inactiveCount+")"
      labels[2]="Active("+resp.activeCount+")"
      this.setState({
        gatewaysSummary: resp,
        labels:labels,
      });
    });
}
  render() {
    let data = null;

    if (this.props.summary !== null && (this.props.summary.activeCount !== 0 || this.props.summary.inactiveCount !== 0)) {
      data = {
        labels: this.state.labels,
        datasets: [
          {
            data: [this.props.summary.neverSeenCount, this.props.summary.inactiveCount, this.props.summary.activeCount],
            backgroundColor: [
              theme.palette.warning.main,
              theme.palette.error.main,
              theme.palette.success.main,
            ],
          },
        ],
      };
    }


    const options = {
      animation: false,
    };
    const { t } = this.props
    return(
      <Card>
        <CardHeader title={t('activeGateways')} />
        <CardContent>
          {data && <Doughnut data={data} options={options} className={this.props.classes.doughtnutChart} />}
          {!data && <div>{t('noDataAvailable')}</div>}
        </CardContent>
      </Card>
    );
  }
}
GatewaysActiveInactive = withStyles(styles)(withTranslation()(GatewaysActiveInactive));



class DevicesDataRates extends Component {
  getColor = (dr) => {
    return ['#ff5722', '#ff9800', '#ffc107', '#ffeb3b', '#cddc39', '#8bc34a', '#4caf50', '#009688', '#00bcd4', '#03a9f4', '#2196f3', '#3f51b5', '#673ab7', '#9c27b0', '#e91e63'][dr];
  }

  render() {
    let data = null;

    if (this.props.summary !== null && Object.keys(this.props.summary.drCount).length !== 0) {
      data = {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: [],
          },
        ],
      };

      for (let dr in this.props.summary.drCount) {
        data.labels.push(`DR${dr}`);
        data.datasets[0].data.push(this.props.summary.drCount[dr]);
        data.datasets[0].backgroundColor.push(this.getColor(dr));
      }
    }
    
    // const { t } = this.props

    // return(
    //   <Card>
    //     <CardHeader title={t('deviceDataRateUsage')} />
    //     <CardContent>
    //       {data && <Doughnut data={data} />}
    //       {!data && <div>{t('noDataAvailable')}</div>}
    //     </CardContent>
    //   </Card>
    // );
    const options = {
      animation: false,
    };
    const { t } = this.props
    return(
      <Card>
        <CardHeader title={t('deviceDataRateUsage')} />
        <CardContent>
          {data && <Doughnut data={data} options={options} className={this.props.classes.doughtnutChart} />}
          {!data && <div>{t('noDataAvailable')}</div>}
        </CardContent>
      </Card>
    );
  }
}
DevicesDataRates = withStyles(styles)(withTranslation()(DevicesDataRates));



class OrganizationDashboard extends Component {
  constructor() {
    super();

    this.state = {
      devicesSummary: null,
      gatewaysSummary: null,
      fullWidth: true,
      maxWidth: "sm",
      spDialog:false,
      license:"",
      object:{},
      loading:false,
      FadeDialog:false,
    };
    this.closeDialog = this.closeDialog.bind(this);
    this.AddLicense = this.AddLicense.bind(this);
    this.JionLicense = this.JionLicense.bind(this);
    
    
  }

  componentDidMount() {
    InternalStore.GetAuthorizationState(1, resp => {
        if (!resp.state){
          this.setState({
            spDialog: true,
          });
        }
       
     }
    )
    


    this.loadData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps === this.props) {
      return;
    }

    this.loadData();
  }

  loadData = () => {
    InternalStore.getDevicesSummary(this.props.organization.id, resp => {
      this.setState({
        devicesSummary: resp,
      });
    });

    InternalStore.getGatewaysSummary(this.props.organization.id, resp => {
      this.setState({
        gatewaysSummary: resp,
      });
    });
  }
  AddLicense(){
    var license =this.state.object.license
    if (license.length>1500 ){
      this.setState({
        FadeDialog: true,
        loading:true,
        spDialog: false,
      });
    }
    
    
    InternalStore.createAuthorization(this.state.object, resp => {
      this.setState({
        FadeDialog: false,
        loading:false,
      });
    });
   
   
  }
  JionLicense(e){
    
    let object = this.state.object;
    let obj = object;
    obj.license=e.target.value
    this.setState({
      object: obj,
    });

  }
  closeDialog() {
    this.setState({
      spDialog: false,
    });

    
    if (this.state.oidcEnabled === true) {
      if (this.state.logoutURL !== "") {
          SessionStore.logout(false, () => {
          window.location.assign(this.state.logoutURL);
        });
      } else {
        SessionStore.logout(true, () => {
            this.props.history.push("/login");
        });
      }
    } else {
      SessionStore.logout(true, () => {
        this.props.history.push("/login");
      });
    }
    

  }

  render() {
    const { t } = this.props
    return(
      <Form>
      <Grid container spacing={4}>
        {/* <Grid item xs={12} sm={6} md={4}> */}
        <Grid item xs={4}>
          <DevicesActiveInactive t={t} summary={this.state.devicesSummary} organizationID={this.props.organization.id} />
        </Grid>

        <Grid item xs={4}>
          <GatewaysActiveInactive t={t} summary={this.state.gatewaysSummary} organizationID={this.props.organization.id} />
        </Grid>

        <Grid item xs={4}>
          <DevicesDataRates t={t} summary={this.state.devicesSummary} organizationID={this.props.organization.id} />
        </Grid>

        <Grid item xs={12}>
          <ListGatewaysMap t={t} organizationID={this.props.organization.id} />
        </Grid>
      </Grid>
      <div >
          <Dialog
            fullWidth={this.state.fullWidth}
            maxWidth={this.state.maxWidth}
            open={this.state.spDialog}
         
            aria-labelledby="max-width-dialog-title"
          >
   
            <DialogTitle>{t('AuthorizationManagement')}</DialogTitle>
            < DialogContent>
            <DialogContentText paragraph>
              <TextField
              id="note"
              label={t('AuthorizationLicenseString')}
              margin="normal"
              onChange={this.JionLicense}
              rows={4}
              fullWidth
              multiline
            />
             


            </DialogContentText>
            </DialogContent>
        
            <DialogActions>
              <Button color="primary" onClick={this.AddLicense}>{t('add')}</Button>
              <Button color="primary" onClick={this.closeDialog}>{t('logout')}</Button>
            </DialogActions>
          </Dialog>




        </div>
        <div >
          <Dialog
            fullWidth={this.state.fullWidth}
            maxWidth={this.state.maxWidth}
            open={this.state.FadeDialog}
            aria-labelledby="max-width-dialog-title"
          >
            <DialogTitle>{t('AuthorizationManagement')}</DialogTitle>
            < DialogContent>
              <DialogContentText paragraph>
                <div className={this.props.classes.root}>
                  <div className={this.props.classes.placeholder}>
                    <Fade in={this.state.loading} unmountOnExit>
                      <CircularProgress />
                    </Fade>
                  </div>
                </div> 

                <div  className={this.props.classes.textcss} >{t('AuthorizationHelpText')}</div>
              </DialogContentText>
            </DialogContent>

          </Dialog>
        </div>
       
        
         

      </Form>
    );
  }
}


export default withStyles(styles)(withTranslation()(OrganizationDashboard));