export default{
    dashboard: "Dashboard",
    applications: "Applications",
    multicastGroups: "Multicast-groups",
    gateways: "Gateways",
    organizationsManage: "Organizations",
    setting: "Setting",
    changeLang: "Change Language",
    search: "Search",
    searchTips: "Search organization, application, gateway or device",
    changePassword: "Change Password",
    logout: "Logout",
    login: "Login",
    usernameOrEmail: "Username/email",
    password: 'Password',
    orgDashboard: 'Org. dashboard',
    orgUsers: 'Org. users',
    orgApiKey: 'Org. API keys',
    serviceProfiles: 'Service-profiles',
    deviceProfiles: 'Device-profiles',
    sysDashboard: 'Dashboard',
    networkServers: 'Network-servers',
    gatewayProfiles: 'Gateway-profiles',
    allUsers: 'All users',
    apiKeys: 'API keys',
    organizations: "Organizations",
    dashboard2: 'Dashboard',
    activeDevices: 'Active devices',
    neverSeen: 'Never Seen',
    inactive: 'Inactive',
    active: 'Active',
    activeGateways: 'Active gateways',
    deviceDataRateUsage: 'Device data-rate usage',
    noDataAvailable: 'No data available.',
    gateways2: 'Gateways',
    add: 'Add',
    name: 'Name',
    server: 'Server',
    networkServer: 'Network-server',
    create: 'Create',
    help: 'Help',
    close: 'Close',
    gatewayProfileHelp: 'Gateway Profile help',
    gatewayProfileHelpContent1: 'The only purpose of a Gateway Profile is to (re)configure one or multiple gateways with the configuration properties that are set by the Gateway Profile.',
    gatewayProfileHelpContent2: 'When the Zysmart Network Server detects that the configuration of a gateway is out-of-sync with its Gateway Profile, it will push a configuration command to the gateway in order to update its configuration.',
    gatewayProfileHelpContent3: 'Please note that this feature is optional and only works in combination with the Zysmart Concentratord component.',
    gatewayProfileHelpContent4: 'Also note that the Gateway Profile does not change the way how devices are behaving. To configure the channel-plan that must be used by devices, update the Zysmart Network Server configuration.',
    displayName: 'Display name',
    canHaveGateways: 'Can have gateways',
    users: 'Users',
    email: 'Email',
    admin: 'Admin',
    globalApikeys: 'Global API keys',
    users2: 'Users',
    changePassword2: "Change password",
    updatePassword: 'Update password',
    kind: 'Kind',
    delete: 'Delete',
    organizations2: 'Organizations',
    configuration: 'Configuration',
    organizationUsers: 'Organization users',
    organizationAdmin: 'Organization admin',
    gatewayAdmin: 'Gateway admin',
    deviceAdmin: 'Device admin',
    organizationApikeys: 'Organization API keys',
    deleteApiKeyTips: "Are you sure you want to delete this api key?",
    createApiKey: 'Create API key',
    ApiKeyId: 'API key ID',
    ApiKeyName: 'API key name',
    token: 'Token',
    tokenHelpText: 'Use this token when making API request with this API key. This token is provided once.',
    apiKayNameHelpText: 'A descriptive name for the API key',
    networkServer2: 'Network Server',
    gateways3: 'Gateways',
    lastSeen: 'Last seen',
    gatewayId: 'Gateway ID',
    gatewayActivity30d: 'Gateway activity (30d)',
    serviceProfiles2: 'Service-profile',
    description: 'Description',
    applications2: 'Applications',
    multicastGroups2: 'Multicast-groups',
    addNetworkServer: 'Add network-server',
    general: 'General',
    gatewayDiscovery: 'Gateway discovery',
    TlsCertificates: 'TLS certificates',
    networkServerName: 'Network-server name',
    networkServerNameHelpText: 'A name to identify the network-server.',
    networkServerServer: 'Network-server server',
    networkServerServerHelpText: "The 'hostname:port' of the network-server, e.g. 'localhost:8000'.",
    enableGatewayDiscovery: 'Enable gateway discovery',
    enableGatewayDiscoveryHelpText: 'Enable the gateway discovery feature for this network-server.',
    intervalPerDay: 'Interval (per day)',
    intervalPerDayHelpText: "The number of gateway discovery 'pings' per day that Zysmart Application Server will broadcast through each gateway.",
    TXFrequency: 'TX frequency (Hz)',
    TXFrequencyHelpText: "The frequency (Hz) used for transmitting the gateway discovery 'pings'. Please consult the LoRaWAN Regional Parameters specification for the channels valid for each region.",
    TXDataRate: 'TX data-rate',
    TXDataRateHelpText: "The data-rate used for transmitting the gateway discovery 'pings'. Please consult the LoRaWAN Regional Parameters specification for the data-rates valid for each region.",
    gatewayDiscoveryTips: 'Certificates for Zysmart Application Server to Zysmart Network Server connection',
    CACertificate: 'CA certificate',
    CACertificateHelpText: 'Paste the content of the CA certificate (PEM) file in the above textbox. Leave blank to disable TLS.',
    TLSCertificate: 'TLS certificate',
    TLSCertificateHelpText: 'Paste the content of the TLS certificate (PEM) file in the above textbox. Leave blank to disable TLS.',
    TLSKey: 'TLS key',
    TLSKeyHelpText: "Paste the content of the TLS key (PEM) file in the above textbox. Leave blank to disable TLS. Note: for security reasons, the TLS key can't be retrieved after being submitted (the field is left blank). When re-submitting the form with an empty TLS key field (but populated TLS certificate field), the key won't be overwritten.",
    gatewayDiscoveryTips2: 'Certificates for Zysmart Network Server to Zysmart Application Server connection',
    deleteDeviceProfileTips: 'Are you sure you want to delete this device-profile?',
    deleteNetworkServerTips: 'Are you sure you want to delete this network-server?',
    updateNetworkServer: 'Update network-server',
    createGatewayProfile: 'Create gateway-profile',
    addANetworkServer: 'Add a network-server?',
    addANetworkServerTips1: "Zysmart Application Server isn't connected to a Zysmart Network Server network-server. Did you know that Zysmart Application Server can connect to multiple Zysmart Network Server instances, e.g. to support multiple regions?",
    addANetworkServerTips2: "Would you like to connect to a network-server now?",
    dismiss: 'Dismiss',
    addExtraChannel: 'Add extra channel',
    gatewayNameHelpText: 'A short name identifying the gateway-profile.',
    statsInterval: 'Stats interval (seconds)',
    statsIntervalHelpText: 'The stats interval in which the gateway reports its statistics. The recommended value is 30 seconds.',
    enabledChannels: 'Enabled channels',
    enabledChannelsHelpText: 'The channels active in this gateway-profile as specified in the LoRaWAN Regional Parameters specification. Separate channels by comma, e.g. 0, 1, 2. Extra channels must not be included in this list.',
    selectNetworkServer: 'Select network-server',
    extraChannel: 'Extra channel',
    modulation: 'Modulation',
    bandwidth: 'bandwidth (kHz)',
    frequency: 'Frequency (Hz)',
    spreadingFactors: 'Spreading-factors',
    spreadingFactorsHelpText: 'When defining multiple spreading-factors, the channel will be configured as a multi-SF channel on the gateway.',
    bitrate: 'Bitrate',
    deleteGatewayProfileTips: 'Are you sure you want to delete this gateway-profile?',
    updateGatewayProfile: 'Update gateway-profile',
    createOrganization: 'Create organization',
    organizationName: 'Organization name',
    organizationNameHelpText: 'The name may only contain words, numbers and dashes.',
    maxNumberOfGateways: 'Max. number of gateways',
    maxNumberOfGatewaysHelpText: 'The maximum number of gateways that can be added to this organization (0 = unlimited).',
    gateways4: 'Gateways',
    organizationCanHaveGateways: 'Organization can have gateways',
    organizationCanHaveGatewaysHelpText: 'When checked, it means that organization administrators are able to add their own gateways to the network. Note that the usage of the gateways is not limited to this organization.',
    devices: 'Devices',
    maxNumberOfDevices: 'Max. number of devices',
    maxNumberOfDevicesHelpText: 'The maximum number of devices that can be added to this organization (0 = unlimited).',
    updateOrganization: 'Update organization',
    createUser: 'Create user',
    emailAddress: 'E-mail address',
    optionalNote: 'Optional note',
    optionalNoteHelpText: 'Optional note, e.g. a phone number, address, comment...',
    permissions: 'Permissions',
    isActive: 'Is active',
    isGlobalAdmin: 'Is global admin',
    deleteUserTips: 'Are you sure you want to delete this user?',
    updateUser: 'Update user',
    addUser: 'Add user',
    addUserTips: 'An user without additional permissions will be able to see all resources under this organization and will be able to send and receive device payloads.',
    userIsOrganizationAdmin: 'User is organization admin',
    userIsOrganizationAdminHelpText: 'An organization admin user is able to add and modify resources part of the organization.',
    userIsDeviceAdmin: 'User is device admin',
    userIsDeviceAdminHelpText: 'A device admin user is able to add and modify resources part of the organization that are related to devices.',
    userIsGatewayAdmin: 'User is gateway admin',
    userIsGatewayAdminHelpText: 'A gateway admin user is able to add and modify gateways part of the organization.',
    organizationUsers2: 'Organization users',
    gotoUser: 'Goto user',
    deleteOrgUserTips: 'Are you sure you want to remove this organization user (this does not remove the user itself)?',
    createServiceProfile: 'Create service-profile',
    serviceProfileName: 'Service-profile name',
    serviceProfileNameHelpText: 'A name to identify the service-profile.',
    networkServerSelectHelpText: "The network-server on which this service-profile will be provisioned. After creating the service-profile, this value can't be changed.",
    addGatewayMetaData: 'Add gateway meta-data',
    addGatewayMetaDataHelpText: 'GW metadata (RSSI, SNR, GW geoloc., etc.) are added to the packet sent to the application-server.',
    enableNetworkGeolocation: 'Enable network geolocation',
    enableNetworkGeolocationHelpText: 'When enabled, the network-server will try to resolve the location of the devices under this service-profile. Please note that you need to have gateways supporting the fine-timestamp feature and that the network-server needs to be configured in order to provide geolocation support.',
    deviceStatusRequestFrequency: 'Device-status request frequency',
    deviceStatusRequestFrequencyHelpText: 'Frequency to initiate an End-Device status request (request/day). Set to 0 to disable.',
    reportDeviceBatteryLevelToAppServer: 'Report device battery level to application-server',
    reportDeviceLinkMarginToAppServer: 'Report device link margin to application-server',
    minimumAllowedDataRate: 'Minimum allowed data-rate',
    minimumAllowedDataRateHelpText: 'Minimum allowed data rate. Used for ADR.',
    maximumAllowedDataRate: 'Maximum allowed data-rate',
    maximumAllowedDataRateHelpText: 'Maximum allowed data rate. Used for ADR.',
    privateGateways: 'Private gateways',
    privateGatewaysHelpText: 'Gateways under this service-profile are private. This means that these gateways can only be used by devices under the same service-profile.',
    deleteServiceProfileTips: 'Are you sure you want to delete this service-profile?',
    updateServiceProfile: 'Update service-profile',
    addAServiceProfile: 'Add a service-profile?',
    addAServiceProfileTips1: 'The selected organization does not have a service-profile yet. A service-profile connects an organization to a network-server and defines the features that an organization can use on this network-server.',
    addAServiceProfileTips2: 'Would you like to create a service-profile?',
    createDeviceProfile: 'Create device-profile',
    codec: 'Codec',
    tags: 'Tags',
    deviceProfileName: 'Device-profile name',
    deviceProfileNameHelpText: 'A name to identify the device-profile.',
    deviceSelectNetworkServerHelpText: "The network-server on which this device-profile will be provisioned. After creating the device-profile, this value can't be changed.",
    LoRaWANMACVersion: 'LoRaWAN MAC version',
    selectLoRaWANMACVersion: 'Select LoRaWAN MAC version',
    selectLoRaWANMACVersionHelpText: 'The LoRaWAN MAC version supported by the device.',
    LoRaWANRegionalParametersRevision: 'LoRaWAN Regional Parameters revision',
    selectLoRaWANRegionalParametersRevision: 'Select LoRaWAN Regional Parameters revision',
    selectLoRaWANRegionalParametersRevisionHelpText: 'Revision of the Regional Parameters specification supported by the device.',
    ADRAlgorithm: 'ADR algorithm',
    selectADRAlgorithm: 'Select ADR algorithm',
    selectADRAlgorithmHelpText: 'The ADR algorithm that will be used for controlling the device data-rate.',
    maxEIRP: 'Max EIRP',
    maxEIRPHelpText: 'Maximum EIRP supported by the device.',
    uplinkInterval: 'Uplink interval (seconds)',
    uplinkIntervalHelpText: 'The expected interval in seconds in which the device sends uplink messages. This is used to determine if a device is active or inactive.',
    deviceSupportsOTAA: 'Device supports OTAA',
    RX1Delay: 'RX1 delay',
    RX1DelayHelpText: 'RX1 delay (valid values are 0 - 15).',
    RX1DataRateOffset: 'RX1 data-rate offset',
    RX2DataRate: 'RX2 data-rate',
    RX2DataRateHelpText: 'Please refer the LoRaWAN Regional Parameters specification for valid values.',
    RX2ChannelFrequency: 'RX2 channel frequency (Hz)',
    factoryPresetFrequencies: 'Factory-preset frequencies (Hz)',
    factoryPresetFrequenciesHelpText: 'List of factory-preset frequencies (Hz), comma separated.',
    deviceSupportsClassB: 'Device supports Class-B',
    ClassBConfirmedDownlinkTimeout: 'Class-B confirmed downlink timeout',
    ClassBConfirmedDownlinkTimeoutHelpText: 'Class-B timeout (in seconds) for confirmed downlink transmissions.',
    ClassBPingSlotPeriodicity: 'Class-B ping-slot periodicity',
    ClassBPingSlotPeriodicityHelpText: 'Class-B ping-slot periodicity.',
    selectClassBPingSlotPeriodicity: 'Select Class-B ping-slot periodicity',
    ClassBPingSlotDataRate: 'Class-B ping-slot data-rate',
    ClassBPingSlotFrequency: 'Class-B ping-slot frequency (Hz)',
    payloadCodec: 'Payload codec',
    selectPayloadCodec: 'Select payload codec',
    selectPayloadCodecHelpText: 'By defining a payload codec, Zysmart Application Server can encode and decode the binary device payload for you.',
    deviceSupportsClassC: 'Device supports Class-C',
    deviceSupportsClassCHelpText: 'Select this option when the device will operate as Class-C device immediately after activation. In case it sends a DeviceModeInd mac-command when it changes to Class-C, do not select this option.',
    ClassCConfirmedDownlinkTimeout: 'Class-C confirmed downlink timeout',
    ClassCConfirmedDownlinkTimeoutHelpText: 'Class-C timeout (in seconds) for confirmed downlink transmissions.',
    tagsTips: 'Tags can be used to store additional key/value data.',
    addTag: 'Add tag',
    everySecond: 'every second',
    everyNSecond: 'every {{s}} seconds',
    updateDeviceProfile: 'Update device-profile',
    deleteOrganizationTips: 'Are you sure you want to delete this organization?',
    organization: 'organization',
    application: 'application',
    device: 'device',
    gateway: 'gateway',
    multicastGroups3: 'Multicast-groups',
    createMulticastGroup: 'Create multicast-group',
    deleteMulticastGroupTips: 'Are you sure you want to delete this multicast-group?',
    updateMulticastGroup: 'Update multicast-group',
    deviceName: 'Device name',
    deviceEUI: 'Device EUI',
    addDevice: 'Add device',
    device2: 'Device',
    selectDevice: 'Select device',
    selectDeviceHelpText: "Search on device by it's name or device EUI. Only devices part of the same service-profile as the multicast-group can be added (and are visible).",
    deleteMultGroupDeviceTips: 'Are you sure you want to remove this device from the multicast-group? This does not remove the device itself.',
    multicastGroupName: 'Multicast-group name',
    multicastGroupNameHelpText: 'The name of the multicast-group.',
    selectServiceProfile: 'Select service-profile',
    selectServiceProfileHelpText: "The service-profile to which this multicast-group will be attached. Note that you can't change this value after the multicast-group has been created.",
    multicastAddress: 'Multicast address',
    multicastNetworkSessionKey: 'Multicast network session key',
    multicastApplicationSessionKey: 'Multicast application session key',
    frameCounter: 'Frame-counter',
    dataRate: 'Data-rate',
    dataRateHelpText: 'The data-rate to use when transmitting the multicast frames. Please refer to the LoRaWAN Regional Parameters specification for valid values.',
    frequencyHelpText: 'The frequency to use when transmitting the multicast frames. Please refer to the LoRaWAN Regional Parameters specification for valid values.',
    multicastGroupType: 'Multicast-group type',
    selectMulticastGroupType: 'Select multicast-group type',
    selectMulticastGroupTypeHelpText: 'The multicast-group type defines the way how multicast frames are scheduled by the network-server.',
    createGateway: 'Create gateway',
    gatewayDetails: 'Gateway details',
    gatewayConfiguration: 'Gateway configuration',
    certificate: 'Certificate',
    liveLoRaWANFrames: 'Live LoRaWAN frames',
    deleteGatewayTips: 'Are you sure you want to delete this gateway?',
    updateGateway: 'Update gateway',
    gatewayCertTips: 'If required by the network, the gateway needs a client certificate in order to connect to the network. This certificate must be configured on the gateway. After generating the certificate, the certificate can only be retrieved once.',
    generateCertificate: 'Generate certificate',
    certificateExpiresAt: 'Certificate expires at',
    certificateExpiresAtHelpText: 'The certificate expires at this date. Make sure to generate and configure a new certificate for your gateway before this expiration date.',
    gatewayCACertHelpText: "The CA certificate is to authenticate the certificate of the server. Store this as a text-file on your gateway, e.g. named 'ca.pem'.",
    gatewayTLSCertHelpText: "Store this as a text-file on your gateway, e.g. named 'cert.pem'",
    gatewayTLSKeyHelpText: "Store this as a text-file on your gateway, e.g. named 'key.pem'",
    framesReceived: 'Frames received',
    framesTransmitted: 'Frames transmitted',
    altitude: 'Altitude',
    GPSCoordinates: 'GPS coordinates',
    lastSeenAt: 'Last seen at',
    noGatewayDiscoveryTips1: 'No gateway discovery data is available (yet). This could mean:',
    noGatewayDiscoveryTips2: "no discovery 'ping' was emitted yet",
    noGatewayDiscoveryTips3: 'the gateway ping feature has been disabled in Zysmart Application Server',
    noGatewayDiscoveryTips4: "the discovery 'ping' was not received by any other gateways",
    freq: 'Freq',
    meter: 'meter(s)',
    RSSI: 'RSSI',
    SNR: 'SNR',
    gatewayFrameTips: 'The frames below are the raw (and encrypted) LoRaWAN PHYPayload frames as seen by the gateway(s). This data is intended for debugging only.',
    pause: 'Pause',
    resume: 'Resume',
    download: 'Download',
    clear: 'Clear',
    notConnectedToWebsocketAPI: 'Not connected to Websocket API',
    FPGAIDHelpText: 'The FPGA ID of the geoloc concentrator board. This is only available for v2 gateways with geolocation capabilities. (optional)',
    fineTimestampDecryptionKey: 'Fine-timestamp decryption key',
    fineTimestampDecryptionKeyHelpText: 'The fine-timestamp AES decryption key. When set, Zysmart Network Server will decrypt the fine-timestamp. This is only available for v2 gateways with geolocation capabilities. (optional)',
    configuration2: 'configuration',
    metadata: 'Metadata',
    addBoardConfiguration: 'Add board configuration',
    gatewayName: 'Gateway name',
    gatewayNameHelpText2: 'The name may only contain words, numbers and dashes.',
    gatewayDescription: 'Gateway description',
    selectNetworkServerHelpText: 'Select the network-server to which the gateway will connect. When no network-servers are available in the dropdown, make sure a service-profile exists for this organization. ',
    selectServiceProfileHelpText2: 'Select the service-profile under which the gateway must be added. The available service-profiles depend on the selected network-server, which must be selected first.',
    GatewayProfile: 'Gateway-profile',
    selectGatewayProfile: 'Select gateway-profile',
    selectGatewayProfileHelpText: 'Optional. When assigning a gateway-profile to the gateway, Zysmart Network Server will attempt to update the gateway according to the gateway-profile. Note that this does require a gateway with Zysmart Concentratord.',
    gatewayDiscoveryEnabled: 'Gateway discovery enabled',
    gatewayDiscoveryEnabledHelpText: 'When enabled (and Zysmart Network Server is configured with the gateway discover feature enabled), the gateway will send out periodical pings to test its coverage by other gateways in the same network.',
    gatewayAltitude: 'Gateway altitude (meters)',
    gatewayAltitudeHelpText: 'When the gateway has an on-board GPS, this value will be set automatically when the network has received statistics from the gateway.',
    gatewayLocation: 'Gateway location',
    setToCurrentLocation: 'set to current location',
    gatewayLocationTips: 'Drag the marker to the location of the gateway. When the gateway has an on-board GPS, this value will be set automatically when the network receives statistics from the gateway.',
    gatewayMateDataTips: 'Metadata can be used by the Zysmart Gateway Bridge to push information about the gateway (e.g. ip / hostname, serial number, temperatures, ...). This information is automatically updated when gateway statistics are received.',
    deviceProfile: 'Device profile',
    linkMargin: 'Link margin',
    battery: 'Battery',
    updateDevice: 'Update device',
    port: 'Port',
    portHelpText: 'Note that the port value must be 1-223',
    confirmedDownlink: 'Confirmed downlink',
    base64EncodedString: 'Base64 encoded string',
    sexadecimalEncodedString: 'hexadecimal encoded string',
    
    deviceEncodeTips: 'The device must be configured with a Device Profile supporting a Codec which is able to encode the given (JSON) payload.',
    applications3: 'Applications',
    details: 'Details',
    activation: 'Activation',
    deviceData: 'Device data',
    LoRaWANFrames: 'LoRaWAN Frames',
    keysOTAA: 'Keys (OTAA)',
    
    HistoryMessages: 'History Messages',
    deleteDeviceTips: 'Are you sure you want to delete this device?',
    setDeviceKeys: 'Set device-keys',
    networkKey: 'Network key (LoRaWAN 1.1)',
    deviceKeyHelpText: 'For LoRaWAN 1.1 devices. In case your device does not support LoRaWAN 1.1, update the device-profile first.',
    applicationKey: 'Application key',
    applicationKey2: 'Application key (LoRaWAN 1.1)',
    deviceKeyHelpText2: 'For LoRaWAN 1.0 devices. In case your device supports LoRaWAN 1.1, update the device-profile first.',
    addADeviceProfile: 'Add a device-profile?',
    addADeviceProfileTips1: 'The selected application does not have access to any device-profiles. A device-profile defines the capabilities and boot parameters of a device. You can create multiple device-profiles for different kind of devices.',
    addADeviceProfileTips2: 'Would you like to create a device-profile?',
    createDevice: 'Create device',
    deviceAddress: 'Device address',
    deviceAddressHelpText: 'While any device address can be entered, please note that a LoRaWAN compliant device address consists of an AddrPrefix (derived from the NetID) + NwkAddr.',
    meters: 'meters',
    networkSessionKey: 'Network session key (LoRaWAN 1.0)',
    applicationSessionKey: 'Application session key (LoRaWAN 1.0)',
    uplinkFrameCounter: 'Uplink frame-counter',
    downlinkFrameCounter: 'Downlink frame-counter (network)',
    networkSessionEncryptionKey: 'Network session encryption key',
    servingNetworkSessionIntegrityKey: 'Serving network session integrity key',
    forwardingNetworkSessionIntegrityKey: 'Forwarding network session integrity key',
    applicationSessionKey2: 'Application session key',
    downlinkFrameCounter2: 'Downlink frame-counter (application)',
    ReActivateDevice: '(Re)activate device',
    deviceNotActivatedTips: 'This device has not (yet) been activated.',
    deviceDataHelpTips1: 'These are the events as published to the application (using the JSON marshaler). Please refer to data integrations (documentation) for more information on integrating this with your application.',
    deviceDataHelpTips2: 'ID values are converted from base64 to HEX for your convenience.',
    deviceProfile2: 'Device-profile',
    enabled: 'enabled',
    disabled: 'disabled',
    status: 'Status',
    state: 'State',
    enqueueDownlinkPayload: 'Enqueue downlink payload',
    enqueuePayload: 'Enqueue payload',
    base64Encoded: 'Base64 encoded',

    sexadecimalEncoded: 'hexadecimal encoding',

    downlinkQueue: 'Downlink queue',
    confirmed: 'Confirmed',
    base64EncodedPayload: 'Base64 encoded payload',

    hexEncodedPayload:'Hexadecimal encoded data',
    pending:"Pending",
    createdTime:"createdTime",
    updatedTime:"updatedTime",
    

    flushDeviceQueueTips: 'Are you sure you want to flush the device queue?',
    variables: 'Variables',
    deviceNameHelpText: 'The name may only contain words, numbers and dashes.',
    deviceDescription: 'Device description',
    disableFrameCounterValidation: 'Disable frame-counter validation',
    disableFrameCounterValidationHelpText: 'Note that disabling the frame-counter validation will compromise security as it enables people to perform replay-attacks.',
    deviceIsDisabled: 'Device is disabled',
    deviceIsDisabledHelpText: 'Zysmart Network Server will ignore received uplink frames and join-requests from disabled devices.',
    deviceVariablesTips: 'Variables can be used to substitute placeholders in for example integrations, e.g. in case an integration requires the configuration of a device specific token.',
    addVariable: 'Add variable',
    deviceTagsTips: 'Tags can be used as device filters and are exposed on events as additional meta-data for aggregation.',
    updateApplication: 'Update application',
    createApplication: 'Create application',
    applicationConfiguration: 'Application configuration',
    integrations: 'Integrations',
    deleteAppTips: 'Are you sure you want to delete this application? This will also delete all devices part of this application.',
    applicationName: 'Application name',
    applicationNameHelpText: 'The name may only contain words, numbers and dashes.',
    applicationDescription: 'Application description',
    selectServiceProfileHelpText3: "The service-profile to which this application will be attached. Note that you can't change this value after the application has been created.",
    importantNote: 'Important note',
    selectPayloadCodecHelpText2: 'the payload fields have moved to the device-profile. For backward-compatibility and migration, existing codec settings are still visible. Codec settings on the device-profile have priority over the application codec settings.',
    appPayloadCodecTips: 'Note: The payload codec fields have moved to the device-profile.',
    toggleMSBOrLSBTips: 'Toggle the byte order of the input. Some devices use LSB.',
    generateRandomKey: 'Generate random key.',
    generateRandomDeviceAddress: 'Generate random device address.',
    generateRandomID: 'Generate random ID.',
    value: 'Value',
    addADeviceProfileTips3: 'The selected organization does not have a device-profile yet. A device-profile defines the capabilities and boot parameters of a device. You can create multiple device-profiles for different kind of devices.',
    gatewayInfo: 'Gateway Information',
    gatewayName2: 'Gateway Name',
    gatewayType: 'Gateway Type',
    frequencyRegion: 'Region',
    subnetType: 'Subnet Type',
    pktfwd: 'PKTFWD',
    onlineStatus: 'Online Status',
    gatewayDownlinkTXPower: 'Gateway Down Transmission Power',
    TXPower: 'Transmitting Power',
    HTTPPushInterface: 'HTTP Push Interface',
    GPSTimeSyncStatus: 'GPS Time Synchronization State',
    createTime: 'Create Time',
    lastFrameData: 'Last Frame Data',
    msgType: 'Type',
    freq2: 'Freq (MHz)',
    RSSI2: 'RSSI (dBm)',
    SNR2: 'SNR (dB)',
    SF: 'SF',
    BW: 'BW (KHz)',
    LoRaModulate: 'LoRa Modulate',
    GPSTime: 'GPS Time',
    lastSeen2: 'Last Seen',
    gatewayNetworkStatus: 'Gateway Network Status',
    networkType: 'Network Type',
    WANIP: 'WAN IP',
    LANIP: 'LAN IP',
    LTESignalStrength: 'LTE Signal Strength',
    latency: 'Latency',
    lastUpdated: 'Last Updated',
    frequencyScheduleSwitchingState: 'Frequency Schedule Switching State',
    currentFrequencyPlan: 'Current Frequency Plan',
    targetFrequencyPlan: 'Target Frequency Plan',
    dataDeliveryInterval: 'Data Delivery Interval',
    switchTime: 'Switch Time',
    responseState: 'Response State',
    dataIssuedCount: 'Data Issued Count',
    nextDataReleaseTime: 'Next Data Release Time',
    gatewaySystemStatus: 'Gateway System Status',
    subModel: 'Model',
    firmwareDate: 'Firmware Date',
    firmwareVersion: 'Firmware Version',
    hardwareVersion: 'Hardware Version',
    kernelVersion: 'Kernel Version',
    SDKVersion: 'SDK Version',
    PKTFWDVersion: 'PKTFWD Version',
    PKTFWDStatus: 'PKTFWD Status',
    gatewayRunningTime: 'Gateway Running Time',
    SDKRunningTime: 'SDK Running Time',
    CPUUsageRate: 'CPU Usage Rate',
    memoryUsageRate: 'Memory Usage Rate',
    rootUsageRate: 'Root Usage Rate',
    logUsageRate: 'Log Usage Rate',
    temperature: 'Temperature',
    latitude: 'Latitude',
    longitude: 'Longitude',
    fCntUp: 'fCntUp',
    fCntDown: 'fCntDown',
    fPort: 'fPort',
    copy: 'Copy',
    deviceName2: 'Device Name',
    activationType: 'Activation Type',
    supportClassB: 'Support Class B',
    supportClassC: 'Support Class C',
    deviceCreateTime: 'Device Create Time',
    macVersion: 'Mac Version',
    DR: 'DR',
    ADR: 'ADR',
    latestFrameData: 'Latest Frame Data',
    communicationGatewayList: 'Communication Gateway List',
    enabled2: 'Enabled',
    disabled2: 'Disabled',
    online: 'Online',
    offline: 'Offline',
    default: 'Default',
    custom: 'Custom',
    synced: 'Synced',
    unsynced: 'Unsynced',
    hasResponse: 'Has Response',
    notResponding: 'Not Responding',
    StartingTime:'Starting Time',
    EndTime:'End Time',
    Check:'Check',
    AppHistory:'App History',
    MacHistory:'Mac History',
    DownlinkMessageQueue:'Downlink Message Queue',
    TransmissionDirection:'Transmission Direction',
    GatewayId:'Gateway ID',
    gatewayDownlinkTXPowerHelpText: 'The transmit power can only allow 0-27 Setting 0 the system will get the default setting.',
    GatewayHistoricalMessage:'Gateway Historical Message',
    GatewayStatusHistory:'Gateway Status History',
    ConnectionStatus:'Connection Status',
    ConnectionType:'Connection Type',
    All:'All',
    DisplayUpLineStatus:'Display Up Line Status',
    DisplayDownLineStatus:'Display Down Line Status',
    AuthorizationManagement:'Authorization management',
    deleteAuthorizationTips: "Are you sure you want to delete this authorization?",
    createAuthorization: 'Create or update LIcense',
    AuthorizationLicenseString:'Authorization License String',
    Expiredate:'Expire date',
    Expired:'Expired',
    Normal:'Normal',
    selectFrequencyBand: 'Select Frequency Band',
    selectFrequencyBandHelpText: 'Please select a frequency band',
    FrequencyBands:'Band settings',
    Online1:'Online',
    FrequencyBand:'FrequencyBand',
    CreateOrUpdate:'Create or update',
    selectBandChHelpText: 'Please select the frequency band first',
    selectChannel: 'select Channel (Please select the frequency band first)',
    ChannelSettings:'Channel Settings',
    PendingCommand:'Pending Command',
    Channel:'Channel',
    DeviceQuantity:'Device Quantity',
    Integrated:'Integrated',
    OnlineDocumentation:'Document',
    APIKeyDetails:'API Key Details',
    SystemSettings:'System Settings',
    UpdateSystemSettings:'Update System Settings',
    SiteLanguage:'Site Language',
    SiteLanguageHelpTex:'A language must be selected',
    Display:'Display',
    SiteName:'Site Name',
    SiteDocumentation:'Site Documentation',
    SiteDocumentationHelpTex:'The top menu displays the document',
    LogoHelpTex: 'The logo format requires PNG 485*96',
    FaviconHelpTex:'The favicon format requires PNG 32*32',
    AuthorizationHelpText:'Permission configuration loading......',
    SendTime:'Send Time',
    BatteryStatus:'Battery Status',
    PowerType:'Power Type',
    BatteryPowered:'Battery Powered',
    ExternalPowerSupply:'External Power Supply',
    BatteryPoweredAndExternalPowerSupply:'BatteryPowered And ExternalPowerSupply',
    Unknown:'Unknown',
    DisableAdr:'Disable Adr',
    rx1DelayHelpText: 'Note that the numeric value must be 1-15.',
    PleaseSelectCmmand :'Please select the command type first.',
    Command:'Command',
    ExecuteCommand:'Execute Command',
    CommandDetails:'Command details',
    PleaseSelectCommandDetails :'Please select command details',
    WaitForExecuteCommand:'Wait for execute command',
    UplinkQueue:'Uplink Queue',
    ExtraChannels:'Extra Channels',
    CreateExtraChannels:'Create Extra Channels',
    ExtraChannelsBandNameHelpText: 'The frequency band is unique, and duplicate frequency bands cannot be created.',
    selectServiceProfileHelpText4: "This application will be attached to the configuration file of the extra frequency band. Please note that this value cannot be changed after creating additional frequency bands. This value cannot be empty.",
    Subchannel:'Sub channel',
    deleteExtraChannelsItemTips: 'Are you sure you want to delete this subchannel?',
    renovate:'renovate',
    open:'open',
    CreateSubchannel:'Create Sub channel',
    update:'update',
    extraChannelsHelpText: 'The input channel must be initialized globally and separated by commas. For example, 867100000867300000867500000 default channels must not be included in this list.',
    LoadingDialogHelpText:'Loading......',
    createExtraChannelItemTips: 'ExtraChannel can only add up to 5',
    deleteExtraChannelItTips:'Are you sure you want to delete this ExtraChannel?',
    errType:'Err Type',
    DownlinkTXAck:'Downlink TX Ack',
    UpDecodeData:'Up Decode Data',
    TaskSeconds:'Task Seconds',
    RelevanceId:'Relevance ID',
    TaskType:'Task Type',
    TaskResult:'Task Result',
    TaskWait:'Wait',
    TaskInProgress:'Task In Progress',
    TaskComplete:'Task Complete',
    TaskFail:'Task Faile',
    UpDecodeData:"UP_Decode_Data",
    CreateTask:'Create Task',
    CheckTask:'Check Task',
    DeviceEui:'Device EUI',
    GatewayEui:'Gateway Eui',
    

}