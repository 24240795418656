import React, { Component } from "react";
import { Route, Switch, Link, withRouter } from "react-router-dom";

import Grid from '@material-ui/core/Grid';
import Delete from "mdi-material-ui/Delete";
import { withTranslation } from "react-i18next";
import TitleBar from "../../components/TitleBar";
import TitleBarTitle from "../../components/TitleBarTitle";
import InternalStore from "../../stores/InternalStore";
import UpdateExtraChannels from "./UpdateExtraChannels";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ListExtraChannelItem from "../extra-channels-item/ListExtraChannelItem";
import TitleBarButton from "../../components/TitleBarButton";
import theme from "../../theme";
import { withStyles } from "@material-ui/core/styles";
const styles = {
  tabs: {
    borderBottom: "1px solid " + theme.palette.divider,
    height: "48px",
    overflow: "visible",
  },
};

class ExtraChannelsLayout extends Component {
  constructor() {
    super();
    this.state = {
      tab: 0,
      id:0,
    };
    this.deleteExtraChannels = this.deleteExtraChannels.bind(this);
    this.onChangeTab = this.onChangeTab.bind(this);
    this.locationToTab = this.locationToTab.bind(this);
  }

  componentDidMount() {
    console.log("---ExtraChannelsLayout===",this.props.match.params)
   // this.props.match.params.id =this.props.match.params.extraChannelsID
    this.setState({
      id: this.props.match.params.extraChannelsID,
      extraChannelsID: this.props.match.params.extraChannelsID,
    });

    InternalStore.GetExtraChannels(this.props.match.params.extraChannelsID, resp => {
      console.log("---",resp)
      this.setState({
        extraChannels: resp,
      });

    });
    this.locationToTab();
  }
  componentDidUpdate(oldProps) {
    if (this.props === oldProps) {
      return;
    }
    this.locationToTab();
  }

  deleteExtraChannels() {
    if (window.confirm(this.props.t('deleteAppTips'))) {
      InternalStore.DeleteExtraChannels(this.props.match.params.id, resp => {
        this.props.history.push(`/extra-channels`);
      });
    }
  }
  locationToTab() {
    let tab = 0;
    if (window.location.href.endsWith("/edit")) {
      tab = 1;
    } 

    this.setState({
      tab: tab,
    });
  }



  onChangeTab(e, v) { 
    console.log("====this.props.match.params====",this.props.match.params)
    this.setState({
      tab: v,
    });
  }

  render() {
    if (this.state.extraChannels === undefined) {
      return(<div></div>);
    }

    const { t } = this.props

    return(
      <Grid container spacing={4}>
        <TitleBar
          buttons={
  
              <TitleBarButton
                label={t('delete')}
                icon={<Delete />}
                color="secondary"
                onClick={this.deleteExtraChannels}
              />

          }
        >
          <TitleBarTitle to={`/extra-channels`} title={t('ExtraChannels')} />
          <TitleBarTitle title="/" />
          <TitleBarTitle title={this.state.extraChannels.extraChannels.bandName} />
        </TitleBar>

        <Grid item xs={12}>
          <Tabs
            value={this.state.tab}
            onChange={this.onChangeTab}
            indicatorColor="primary"
            className={this.props.classes.tabs}
          >
            <Tab label={t('Subchannel')} component={Link} to={`/extra-channels/${this.props.match.params.extraChannelsID}`} />
            {/* <Tab label={t('configuration2')} component={Link} to={`/extra-channels/${this.props.match.params.extraChannelsID}/edit`} /> */}
          
          </Tabs>
        </Grid>
     
      <Grid item xs={12}>
      <Switch>
        {/* <Route exact path={`${this.props.match.path}/edit`} render={props => <UpdateExtraChannels  extraChannels={this.state.extraChannels.extraChannels} {...props} />} /> */}

        <Route exact path={`${this.props.match.path}`}      render={props => <ListExtraChannelItem extraChannels={this.state.extraChannels.extraChannels} {...props} />} />
        </Switch>
        </Grid>
        </Grid>
    );
  }
}
export default withStyles(styles)(withRouter(withTranslation()(ExtraChannelsLayout)));
