import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Plus from "mdi-material-ui/Plus";
import { withTranslation } from "react-i18next";
import TitleBar from "../../components/TitleBar";
import TableCellLink from "../../components/TableCellLink";
import TitleBarButton from "../../components/TitleBarButton";
import DataTable from "../../components/DataTable";
import theme from "../../theme";

import InternalStore from "../../stores/InternalStore";

const styles = {

  online:{
    color:"green"
  },
  offline:{
 
    color:"red",
  }
};
class ListExtraChannelItem extends Component {
  
  constructor() {
    super();
    this.state = {
      totalCount:0
     
    };
    this.getPage = this.getPage.bind(this);
    this.getRow = this.getRow.bind(this);
    this.createExtraChannelItem = this.createExtraChannelItem.bind(this);
  }
  
  componentDidMount() {
    InternalStore.GetExtraChannels(this.props.match.params.extraChannelsID, resp => {
      console.log("---",resp)
      this.setState({
        extraChannels: resp,
      });

    });
    InternalStore.GetExtraChannelItemlist({
      extraChannelsID:this.props.match.params.extraChannelsID
    }, resp => {
      this.setState({
        totalCount: resp.totalCount,
      });
    });

  }

  createExtraChannelItem() {
    if (this.state.totalCount>=5){
      if (window.confirm(this.props.t('createExtraChannelItemTips'))) {
      }
    }else{
      this.props.history.push(`/extra-channels/${this.props.match.params.extraChannelsID}/extra-channels-item/create`);
    }
   
  }

  getPage(limit, offset, callbackFunc) {
    InternalStore.GetExtraChannelItemlist({
      extraChannelsID:this.props.match.params.extraChannelsID,
      limit: limit,
      offset: offset,
    },callbackFunc);
  }

  getRow(obj) {
    console.log("=params===",this.state.Object)
    return(
      <TableRow
        key={obj.frequency}
        hover
      >
        <TableCellLink to={`/extra-channels/${this.props.match.params.extraChannelsID}/extra-channels-item/${obj.id}`}>{obj.frequency}</TableCellLink>
        <TableCell  >{obj.minDr}</TableCell>
        <TableCell  >{obj.maxDr}</TableCell>
        <TableCell >{ obj.isDisabled ? 'true' :  'false'} </TableCell>
      </TableRow>
    );
  }
  render() {
    const { t } = this.props
    return(
      <Grid container spacing={4}>
        <TitleBar
          buttons={[
            <TitleBarButton
              key={1}
              label={t('create')}
              icon={<Plus />}
              // to={`/extra-channels/${this.props.match.params.extraChannelsID}/extra-channels-item/create`}
              onClick={this.createExtraChannelItem}
            />
          ]}
        >
        </TitleBar>
        <Grid item xs={12}>
          <DataTable
            header={
              <TableRow>
                <TableCell>{t('frequency')}</TableCell>
                <TableCell>{'MinDr'}</TableCell>
                <TableCell>{'MaxDr'}</TableCell>
                <TableCell>{t('disabled')}</TableCell>
              </TableRow>
            }
            getPage={this.getPage}
            getRow={this.getRow}
          />
        </Grid>
      </Grid>
    );
  }
}
export default withStyles(styles)(withTranslation()(ListExtraChannelItem));
