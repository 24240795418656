import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import Grid from '@material-ui/core/Grid';

import Delete from "mdi-material-ui/Delete";
import { withTranslation } from "react-i18next";
import TitleBar from "../../components/TitleBar";
import TitleBarTitle from "../../components/TitleBarTitle";
import TitleBarButton from "../../components/TitleBarButton";
import InternalStore from "../../stores/InternalStore";
import UpdateExtraChannelItem from "./UpdateExtraChannelItem";
import { withStyles } from "@material-ui/core/styles";

import theme from "../../theme";

const styles = {
  tabs: {
    borderBottom: "1px solid " + theme.palette.divider,
    height: "49px",
  },
};


class ExtraChannelItemLayout extends Component {
  constructor() {
    super();
    this.state = {
      frequency:0,
     
    };
    this.deleteExtraChannelItem = this.deleteExtraChannelItem.bind(this);
  }

  componentDidMount() {
    InternalStore.GetExtraChannelItem(this.props.match.params.extraChannelsItemID, this.props.match.params.extraChannelsID ,resp => {
      this.setState({
        Object: resp.extraChannelItem,
        frequency: resp.extraChannelItem.frequency,
      });
    });
  }




  deleteExtraChannelItem() {
    if (window.confirm(this.props.t('deleteExtraChannelItTips'))) {
      InternalStore.DeleteExtraChannelItem(this.props.match.params.extraChannelsItemID,this.props.match.params.extraChannelsID, resp => {
        this.props.history.push(`/extra-channels/${this.props.match.params.extraChannelsID}`);
      });
    }
   
  }

  render() {
    if (this.state.Object === undefined) {
      return(<div></div>);
    }
    const { t } = this.props

    return(
      <Grid container spacing={4}>
        <TitleBar
          buttons={
              <TitleBarButton
                key={1}
                label={t('delete')}
                icon={<Delete />}
                color="secondary"
                onClick={this.deleteExtraChannelItem}
              />
          }
        >
          <TitleBarTitle to={`/extra-channels/${this.props.match.params.extraChannelsID}`} title={t('Subchannel')} />
          <TitleBarTitle title="/" />
          <TitleBarTitle title={this.state.frequency} />
          
        </TitleBar>

        <Grid item xs={12}>
          <UpdateExtraChannelItem extraChannelItem={this.state.Object}  />
        </Grid>
      </Grid>
    );

    
    
  }
}
export default withStyles(styles)(withRouter(withTranslation()(ExtraChannelItemLayout)));