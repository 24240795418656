import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from "@material-ui/core/CardContent";
import { withTranslation } from "react-i18next";

import TitleBar from "../../components/TitleBar";
import TitleBarTitle from "../../components/TitleBarTitle";
import AuthorizationForm from "./AuthorizationForm";
class CreateAdminAuthorization extends Component {

  
  render() {
    const { t } =this.props
    
    return(
      <Grid container spacing={4}>
        <TitleBar>
          <TitleBarTitle title={t('AuthorizationManagement')} to="/authorization" />
          <TitleBarTitle title="/" />
          <TitleBarTitle title={t('CreateOrUpdate')} />
        </TitleBar>

        <Grid item xs={12}>
          <Card>
            <CardContent>
              <AuthorizationForm
                submitLabel={t('createAuthorization')}
                onSubmit={this.onSubmit}
                isAdmin={true}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  }
}

export default withRouter(withTranslation()(CreateAdminAuthorization));
