import React, { Component } from "react";
import { withRouter, Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import { CardContent } from "@material-ui/core";
import { withTranslation } from "react-i18next";
import TitleBar from "../../components/TitleBar";
import TitleBarTitle from "../../components/TitleBarTitle";
import ExtraChannelsForm from "./ExtraChannelsForm";
import InternalStore from "../../stores/InternalStore";




const styles = {
  card: {
    overflow: "visible",
  },
};


class CreateExtraChannels extends Component {
  constructor() {
    super();
    this.state = {
    };
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
   
  }

  onSubmit(extraChannels) {
    InternalStore.CreateExtraChannels(extraChannels, resp => {
      this.props.history.push("/extra-channels");
    });
  }

  render() {
    const { t } = this.props
    return(
      <Grid container spacing={4}>
        <TitleBar>
          <TitleBarTitle title={t('ExtraChannels')} to="/extra-channels" />
          <TitleBarTitle title="/" />
          <TitleBarTitle title={t('create')} />
        </TitleBar>

        <Grid item xs={12}>
          <Card className={this.props.classes.card}>
            <CardContent>
              <ExtraChannelsForm
                submitLabel={t('CreateExtraChannels')}
                onSubmit={this.onSubmit}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(withRouter(withTranslation()(CreateExtraChannels)));
