import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Button from '@material-ui/core/Button';
import moment from "moment";
import Plus from "mdi-material-ui/Plus";
import PowerPlug from "mdi-material-ui/PowerPlug";
import { withTranslation } from "react-i18next";
import TableCellLink from "../../components/TableCellLink";
import DataTable from "../../components/DataTable";
import DeviceAdmin from "../../components/DeviceAdmin";
import DeviceStore from "../../stores/DeviceStore";
import theme from "../../theme";
import InternalStore from "../../stores/InternalStore";
import Form from "../../components/Form";
import IconButton from "@material-ui/core/IconButton";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloudUpload from "mdi-material-ui/CloudUpload";
// import CloudArrowDown from "mdi-material-ui/CloudArrowDown";
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import * as XLSX from 'xlsx';
import LinearProgress from '@material-ui/core/LinearProgress';
import Box from '@material-ui/core/Box';








const styles = {
  buttons: {
    textAlign: "right",
  },
  button: {
    marginLeft: 2 * theme.spacing(1),
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  RadioGroupCss: {
    display: 'block',
  },
  GroupCss: {
    textAlign: 'right',
    border: 'dotted'
  },

  DialogContentTextCss
    : {
    textAlign: 'center',
  },
  input: {
    display: 'none',
    textAlign: 'center',
  },
  CloudUploadCss: {
    textAlign: 'center',
    fontSize: '-webkit-xxx-large'
  },

  margin: {
    height: theme.spacing(3),
  },

  prett: {
    width: "75%",
    textAlign: "center",
    position: "absolute",
    left: "50%",
    top: "53%",
    transform: "translate(-50%, -50%)",
  },
  buttonCss: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "flexStart"

   
  },
  prettDisplay: {
    display: "none"
  },





};


function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};


class ListDevices extends Component {
  constructor() {
    super();
    this.state = {
      spDialog: false,
      fullWidth: true,
      maxWidth: "sm",
      activationType: "OTAA",
      schedule: 0,
      startImport: false
    };
    this.getPage = this.getPage.bind(this);
    this.getRow = this.getRow.bind(this);

    this.activationTypeChange = this.activationTypeChange.bind(this);

    this.ImportDevices = this.ImportDevices.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.Uploadiles = this.Uploadiles.bind(this);
    this.Template = this.Template.bind(this);
  }
  Template = () => {
    // 使用 history.push 跳转到 "/target-route"
    this.props.history.push('./device-import-template.xlsx');
  };
  Uploadiles = (e) => {
    this.setState({
      startImport: true

    })

    console.log("====file===", file)
    console.log("====applicationID===", this.props.match.params.applicationID)
    console.log("====this.state.file===", e.target.files[0])
    const applicationID = this.props.match.params.applicationID;
    const files = e.target.files;
    const reader = new FileReader();
    const file = files[0];
    reader.onload = function (e) {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

      // console.log(jsonData);
      const head = jsonData[0]
      const mapData = new Map();
      const num = jsonData.length - 1
      for (let i = 1; i < jsonData.length; i++) {
        let row = jsonData[i]
        let dev = {
          applicationID: applicationID.toString(),
          name: row[0].toString(),
          devEUI: row[1].toString(),
          description: row[2].toString(),
          frequencyBand: row[3].toString(),
          deviceProfileID: row[4].toString()
        }
        let devEUI = row[1].toString()
        let keys = {
          nwkKey: row[7].toString(),
          devEUI: devEUI

        }

        DeviceStore.create1(dev, resp => {

          console.log("create",resp);

            DeviceStore.createKeys1(keys, resp1 => {
              this.setState({
                schedule: (i / num) * 100

              })
              if ( i==num){
                this.setState({
                  startImport: false
        
                })
              }
          });

        });


      }


    }.bind(this);

    reader.readAsArrayBuffer(file);


  }

  closeDialog() {
    this.setState({
      spDialog: false,
      startImport: false,
      schedule: 0
    });
  }
  ImportDevices() {
    this.setState({
      spDialog: true,
    });
  }
  activationTypeChange(e) {
    this.setState({
      activationType: e.target.value,

    })
  }
  handleChange = (event) => {

    this.setState({
      activationType: event.target.value,

    })
  };


  getPage(limit, offset, callbackFunc) {
    InternalStore.GetAuthorizationState(1, resp => {
      if (!resp.state) {
        this.props.history.push(`/organizations/${this.props.match.params.organizationID}`);
      }

    }
    )



    DeviceStore.list({
      applicationID: this.props.match.params.applicationID,
      limit: limit,
      offset: offset,
    }, callbackFunc);
  }

  getRow(obj) {
    let lastseen = "n/a";
    let margin = "n/a";
    let battery = "n/a";

    if (obj.lastSeenAt !== undefined && obj.lastSeenAt !== null) {
      lastseen = moment(obj.lastSeenAt).fromNow();
    }

    if (!obj.deviceStatusExternalPowerSource && !obj.deviceStatusBatteryLevelUnavailable) {
      battery = `${obj.deviceStatusBatteryLevel}%`
    }

    if (obj.deviceStatusExternalPowerSource) {
      battery = <PowerPlug />;
    }

    if (obj.deviceStatusMargin !== undefined && obj.deviceStatusMargin !== 256) {
      margin = `${obj.deviceStatusMargin} dB`;
    }

    return (
      <TableRow
        key={obj.devEUI}
        hover
      >
        <TableCell>{lastseen}</TableCell>
        <TableCellLink to={`/organizations/${this.props.match.params.organizationID}/applications/${this.props.match.params.applicationID}/devices/${obj.devEUI}`}>{obj.name}</TableCellLink>
        <TableCell>{obj.devEUI}</TableCell>
        <TableCellLink to={`/organizations/${this.props.match.params.organizationID}/device-profiles/${obj.deviceProfileID}`}>{obj.deviceProfileName}</TableCellLink>
        <TableCell>{margin}</TableCell>
        <TableCell>{battery}</TableCell>
      </TableRow>
    );
  }

  render() {
    const { t } = this.props


    return (
      <Form>
        <Grid container spacing={4}>
          <DeviceAdmin organizationID={this.props.match.params.organizationID}>
            <Grid item xs={12} className={this.props.classes.buttons}>

              <Button variant="outlined" onClick={() => { this.ImportDevices() }} className={this.props.classes.button}><Plus /> {t('Import')}</Button>
              <Button variant="outlined" className={this.props.classes.button} component={Link} to={`/organizations/${this.props.match.params.organizationID}/applications/${this.props.match.params.applicationID}/devices/create`}>
                <Plus className={this.props.classes.icon} />
                {t('create')}
              </Button>
            </Grid>
          </DeviceAdmin>
          <Grid item xs={12}>
            <DataTable
              header={
                <TableRow>
                  <TableCell>{t('lastSeen')}</TableCell>
                  <TableCell>{t('deviceName')}</TableCell>
                  <TableCell>{t('deviceEUI')}</TableCell>
                  <TableCell>{t('deviceProfile')}</TableCell>
                  <TableCell>{t('linkMargin')}</TableCell>
                  <TableCell>{t('battery')}</TableCell>
                </TableRow>
              }
              getPage={this.getPage}
              getRow={this.getRow}
            />
          </Grid>
        </Grid>
        <div >
          <Dialog
            fullWidth={this.state.fullWidth}
            maxWidth={this.state.maxWidth}
            open={this.state.spDialog}
            onClose={this.closeDialog}
            aria-labelledby="max-width-dialog-title"

          >

            <DialogTitle>{t('DeviceBatchImport')}


            </DialogTitle>

            <DialogContent  >

              <div className={this.props.classes.GroupCss}>
                {/* <FormControl component="fieldset">
                          <RadioGroup aria-label="gender" name="activationType" value={this.state.activationType} onChange={this.handleChange} className={this.props.classes.RadioGroupCss}>
                            <FormControlLabel value="OTAA" control={<Radio />} label="OTAA" />
                            <FormControlLabel value="ABP" disabled control={<Radio />} label="ABP" />
                          </RadioGroup>
                  </FormControl> */}

{/* 
                <Button  onClick={this.Template()}  className={this.props.classes.button}> {t('DownloadTemplate')}</Button> */}

                {<a  style={{color: 'blank',marginLeft:'10px',textDecoration:'none'}}  target="_blank" href='http://lora.zhongyismart.com/Device-Import-Template.xlsx' >{t('DownloadTemplate')}</a>}
                
                <DialogContentText paragraph className={this.state.startImport||this.state.schedule==100 ? this.props.classes.prettDisplay : this.props.classes.DialogContentTextCss}>




                  <input
                    accept="xlsx/*"
                    className={this.props.classes.input}
                    id="icon-button-file"
                    type="file"
                    multiple

                    onChange={this.Uploadiles}
                  />
                  <label htmlFor="icon-button-file"   >
                    <IconButton color="primary" aria-label="upload picture" component="span" >
                      <CloudUpload className={this.props.classes.CloudUploadCss} />
                    </IconButton>

                  </label>



                </DialogContentText>
                <Button variant="contained" color="primary" disableElevation className={this.state.schedule==100 ? this.props.classes.buttonCss : this.props.classes.prettDisplay}>
                Upload succes
                </Button>
                <LinearProgressWithLabel variant="buffer"  valueBuffer={10} value={this.state.schedule} className={this.state.startImport ? this.props.classes.prett : this.props.classes.prettDisplay} />
              </div>
            </DialogContent>







            <DialogActions>
              {/* <Button color="primary" onClick={this.Uploadiles}>{'上传'}</Button> */}
              <Button color="primary" onClick={this.closeDialog}>{t('close')}</Button>
            </DialogActions>
          </Dialog>

        </div>



      </Form>



    );
  }
}

export default withStyles(styles)(withTranslation()(ListDevices));
